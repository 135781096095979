import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Heading from '../Heading';

import defaultPicture from './success.png';
import styles from './SuccessMessage.styl';

const cx = classNames.bind(styles);

export default function SuccessMessage(props) {
  const { className, image, title, text, children } = props;

  return (
    <div className={cx('SuccessMessage', className)}>
      <div className={cx('SuccessMessage__picture')}>
        <img src={image || defaultPicture} alt="success" />
      </div>
      {title && (
        <Heading level={3} tagName="div" className={cx('SuccessMessage__title')}>
          {title}
        </Heading>
      )}
      {text && (
        <div className={cx('SuccessMessage__text')} dangerouslySetInnerHTML={{ __html: text }} />
      )}
      {children}
    </div>
  );
}

SuccessMessage.defaultProps = {
  className: '',
  children: '',
  image: '',
  title: null,
  text: '',
};

SuccessMessage.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  text: PropTypes.string,
};
