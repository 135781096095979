import React from 'react';
import { useIntl } from 'react-intl';
import loadable from '@loadable/component';

const CommonCookie = loadable(() => import('./CommonCookie'));
const PolandCookie = loadable(() => import('./PolandVersion'));

export default function CookiePolicyPopup() {
  const intl = useIntl();
  const lang = intl.locale;
  const View = lang === 'pl' ? PolandCookie : CommonCookie;

  return <View />;
}
