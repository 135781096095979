import bannerApi from './api';
import * as types from './actionTypes';

export const fetchBanners = () => {
  return dispatch => {
    dispatch({ type: types.FETCH_BANNERS_REQUEST });

    return bannerApi
      .getSliderList()
      .then(data => {
        return dispatch({ type: types.FETCH_BANNERS_SUCCESS, payload: data });
      })
      .catch(({ message }) => {
        return dispatch({ type: types.FETCH_BANNERS_FAILURE, payload: message });
      });
  };
};

export const fetchBannersIfNeeded = () => {
  return (dispatch, getState) => {
    const {
      banner: { isLoading, isLoaded },
    } = getState();

    if (!isLoading && !isLoaded) {
      return dispatch(fetchBanners());
    }

    return false;
  };
};
