import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './Form.styl';

const cx = classNames.bind(styles);

const Field = ({ className, children, width }) => (
  <div
    className={cx('Form__field', className, {
      [`Form__field_${width}`]: width,
    })}>
    {children}
  </div>
);

Field.defaultProps = {
  className: '',
  width: '',
};

Field.propTypes = {
  className: PropTypes.string,
  width: PropTypes.oneOf(['', 'one-half', 'one-third', 'one-quarter']),
  children: PropTypes.any.isRequired,
};

export default Field;
