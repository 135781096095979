import { createSelector } from 'reselect';

// общая стоимоть товаров за бонусы, добавленных в корзину
export const giftsForBonusesSumSelector = createSelector([state => state.cart.composition], items =>
  items.reduce((summ, item) => (item.type === 'bonus' ? summ + item.price * item.count : summ), 0),
);

// текущий уровень лояльности
export const currentLoyaltySelector = createSelector(
  [state => state.user.profile.loyalty?.statuses, state => state.user.profile.loyalty?.status],
  (statuses, currentStatus) => {
    if (!statuses || !currentStatus) {
      return null;
    }
    return statuses.find(status => status.code === currentStatus) || null;
  },
);

// соответствие уровеня лояльности критерию новой ПЛ
export const currentLoyaltySelectorForNewPL = createSelector(
  [state => state.user.profile.loyalty?.statuses, state => state.user.profile.loyalty?.status],
  (statuses, currentStatus) => {
    if (!statuses || !currentStatus) {
      return null;
    }
    const neededOldStatuses = ['platinum', 'gold']
    const currentLoyalty = statuses.find(status => status.code === currentStatus)?.code || ''
    return neededOldStatuses.includes(currentLoyalty)
  },
);
// остаток бонусов
export const unusedBonusesSum = createSelector(
  [
    state => state.user.profile.loyalty?.points?.confirmed || 0,
    state => state.cart.sailplay_discount,
    giftsForBonusesSumSelector,
  ],
  (bonuses, sailplay_discount, giftsForBonusesSum) => {
    return bonuses - sailplay_discount - giftsForBonusesSum;
  },
);

export const daysResetStatus = createSelector(
  [state => state.user.profile.loyalty?.days_to_reset_status],
  (days) => {
    if (!days) {
      return null;
    }

    return days
  },
);

// остаток бонусов после того как положили подарки в корзину
export const maxBonusesMinusGiftsSelector = createSelector(
  [state => state.user.profile.loyalty?.points?.confirmed || 0, giftsForBonusesSumSelector],
  (bonuses, giftsForBonusesSum) => {
    return bonuses - giftsForBonusesSum;
  },
);
