import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import InputField from '../../InputField';
import PasswordField from '../../PasswordField';
import PhoneField from '../../PhoneField';
import Button from '../../Button';
import CheckboxField from '../../CheckboxField';
import AppLink from '../../AppLink';

// Router
import { getRoute } from '../../../entry/routes';

// State
import { signUp } from '../../../state/modules/user/actions';
import userApi from '../../../state/modules/user/api';

// Utils
import {
  required,
  isEmail,
  strongPassword,
  numberByCode,
  requiredNumber,
} from '../../../utils/validators';
import { setGlobalMessage } from '../../../utils/setGlobalMessage';

// Config
import config from '../../../config';

// Styles
import styles from './SignUpForm.styl';

const cx = classNames.bind(styles);

export default function SignUpForm(props) {
  const { className, successCallback } = props;

  const intl = useIntl();
  const dispatch = useDispatch();

  const [isSigningUp, setIsSigninUp] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(true);
  const [isCheckbox1Enabled, setIsCheckbox1Enabled] = useState(false);
  const [isCheckbox2Enabled, setIsCheckbox2Enabled] = useState(false);
  const [isCheckbox3Enabled, setIsCheckbox3Enabled] = useState(false);
  const [isCheckbox4Enabled, setIsCheckbox4Enabled] = useState(false);
  const [isCheckbox5Enabled, setIsCheckbox5Enabled] = useState(false);

  // в Польше кнопка недоступна, пока не прожаты первый и последний чекбоксы
  const isButtonDisabled =
    config.legal === 'pl' ? isFirstStep || !isCheckbox1Enabled || !isCheckbox5Enabled : isFirstStep;

  const { register, handleSubmit, errors, control, setError, trigger, getValues } = useForm({
    mode: 'onBlur',
    defaultValues: { username: '', phone: '', email: '', password: '', password_repeat: '' },
  });

  // подтверждаем первый шаг
  const onFirstStepSubmit = async () => {
    setIsSigninUp(true);
    const result = await trigger(['username', 'phone', 'email']);

    if (!result) {
      return setIsSigninUp(false);
    }

    let hasError = false;
    const { phone, email } = getValues(['phone', 'email']);

    await userApi
      .hasPhone(phone)
      .then(({ status }) => {
        if (status) {
          hasError = true;
          setError('phone', {
            type: 'manual',
            message: intl.formatMessage({ id: 'signUp.existPhone' }),
          });
        }
      })
      .catch(({ message }) => {
        hasError = true;
        setError('phone', {
          type: 'manual',
          message,
        });
      });

    await userApi
      .hasMail(email)
      .then(({ status }) => {
        if (status) {
          hasError = true;
          setError('email', {
            type: 'manual',
            message: intl.formatMessage({ id: 'signUp.existEmail' }),
          });
        }
      })
      .catch(({ message }) => {
        hasError = true;
        setError('email', {
          type: 'manual',
          message,
        });
      });

    if (!hasError) {
      setIsFirstStep(false);
    }

    setIsSigninUp(false);
  };

  // подтверждаем второй шаг
  const onSubmit = handleSubmit(async formValues => {
    const onSuccessCallback = () => {
      successCallback?.();
      setGlobalMessage(intl.formatMessage({ id: 'signUp.successMessage.text' }), {
        type: 'success',
      });
    };

    setIsSigninUp(true);
    await dispatch(
      signUp(
        {
          ...formValues,
          sms_state: config.legal === 'pl' ? isCheckbox3Enabled : undefined,
          subscription_state: config.legal === 'pl' ? isCheckbox4Enabled : undefined,
          accepted_loyalty_program: config.legal === 'pl' ? Number(isCheckbox2Enabled) : undefined,
        },
        onSuccessCallback,
      ),
    );
    setIsSigninUp(false);
  });

  return (
    <form className={cx('SignUpForm', className)} onSubmit={onSubmit}>
      <div className={cx('SignUpForm__step', { SignUpForm__step_visible: isFirstStep })}>
        <div className={cx('SignUpForm__field')}>
          <InputField
            name="username"
            label={intl.formatMessage({ id: 'field.username.label' })}
            register={register({ validate: { required } })}
          />
          {errors.username && (
            <div className={cx('SignUpForm__error')}>{errors.username.message}</div>
          )}
        </div>
        <div className={cx('SignUpForm__field')}>
          <Controller
            name="phone"
            control={control}
            rules={{ validate: { required, requiredNumber, numberByCode } }}
            render={({ onChange, value, name, onBlur }) => (
              <PhoneField
                name={name}
                value={value}
                label={intl.formatMessage({ id: 'field.phone.label' })}
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
          {errors.phone && <div className={cx('SignUpForm__error')}>{errors.phone.message}</div>}
        </div>
        <div className={cx('SignUpForm__field')}>
          <InputField
            name="email"
            label="Email"
            register={register({ validate: { required, isEmail } })}
            onKeyDown={e => e.key === 'Enter' && onFirstStepSubmit()}
          />
          {errors.email && <div className={cx('SignUpForm__error')}>{errors.email.message}</div>}
        </div>
      </div>
      <div className={cx('SignUpForm__step', { SignUpForm__step_visible: !isFirstStep })}>
        <div className={cx('SignUpForm__field')}>
          <PasswordField
            name="password"
            label={intl.formatMessage({ id: 'field.password.label' })}
            register={register({ validate: { required, strongPassword } })}
          />
          {errors.password && (
            <div className={cx('SignUpForm__error')}>{errors.password.message}</div>
          )}
        </div>
        <div className={cx('SignUpForm__field')}>
          <PasswordField
            name="password_repeat"
            label={intl.formatMessage({ id: 'field.repeatPassword.label' })}
            register={register({
              validate: {
                required,
                passwordIsEqual: value =>
                  getValues('password') === value
                    ? undefined
                    : intl.formatMessage({ id: 'validators.passwordIsEqual' }),
              },
            })}
          />
          {errors.password_repeat && (
            <div className={cx('SignUpForm__error')}>{errors.password_repeat.message}</div>
          )}
        </div>
      </div>
      {config.legal === 'pl' && !isFirstStep && (
        <>
          <CheckboxField
            className={cx('SignUpForm__privacy-title')}
            value={isCheckbox1Enabled}
            label={intl.formatMessage({ id: 'privacy.checkbox1.title' })}
            onChange={() => setIsCheckbox1Enabled(prev => !prev)}
          />
          <div className={cx('SignUpForm__privacy-description')}>
            {intl.formatMessage({ id: 'privacy.checkbox1.description' })}
          </div>
          <CheckboxField
            className={cx('SignUpForm__privacy-title')}
            value={isCheckbox2Enabled}
            label={intl.formatMessage({ id: 'privacy.checkbox2.title' })}
            onChange={() => setIsCheckbox2Enabled(prev => !prev)}
          />
          <div className={cx('SignUpForm__privacy-description')}>
            {intl.formatMessage({ id: 'privacy.checkbox2.description' })}
          </div>
          <div className={cx('SignUpForm__privacy-subtitle')}>
            {intl.formatMessage({ id: 'privacy.title' })}
          </div>
          <div className={cx('SignUpForm__privacy-description')}>
            {intl.formatMessage({ id: 'privacy.description' })}
          </div>
          <CheckboxField
            className={cx('SignUpForm__privacy-title')}
            value={isCheckbox3Enabled}
            label={intl.formatMessage({ id: 'privacy.checkbox3.title' })}
            onChange={() => setIsCheckbox3Enabled(prev => !prev)}
            disabled={!isCheckbox2Enabled}
          />
          <div className={cx('SignUpForm__privacy-description')}>
            {intl.formatMessage({ id: 'privacy.checkbox3.description' })}
          </div>
          <CheckboxField
            className={cx('SignUpForm__privacy-title')}
            value={isCheckbox4Enabled}
            label={intl.formatMessage({ id: 'privacy.checkbox4.title' })}
            onChange={() => setIsCheckbox4Enabled(prev => !prev)}
            disabled={!isCheckbox2Enabled}
          />
          <div className={cx('SignUpForm__privacy-description')}>
            {intl.formatMessage({ id: 'privacy.checkbox4.description' })}
          </div>
          <CheckboxField
            className={cx('SignUpForm__privacy-title')}
            value={isCheckbox5Enabled}
            label={intl.formatMessage(
              { id: 'privacy.checkbox5.title' },
              {
                link1: (
                  <AppLink to={getRoute('user-agreement')}>
                    {intl.formatMessage({ id: 'checkout.agreePrivacyPolicyLink' })}
                  </AppLink>
                ),
                link2: (
                  <AppLink to={getRoute('politics-of-privacy')}>
                    {intl.formatMessage({ id: 'checkout.agreePrivacyPolicyLink1' })}
                  </AppLink>
                ),
              },
            )}
            onChange={() => setIsCheckbox5Enabled(prev => !prev)}
          />
          <div className={cx('SignUpForm__privacy-description')}>
            {intl.formatMessage(
              { id: 'privacy.checkbox5.description' },
              {
                link1: (
                  <AppLink to={getRoute('user-agreement')}>
                    {intl.formatMessage({ id: 'checkout.agreePrivacyPolicyLink' })}
                  </AppLink>
                ),
                link2: (
                  <AppLink to={getRoute('politics-of-privacy')}>
                    {intl.formatMessage({ id: 'checkout.agreePrivacyPolicyLink1' })}
                  </AppLink>
                ),
              },
            )}
          </div>
        </>
      )}
      <div className={cx('SignUpForm__actions')}>
        {isFirstStep ? (
          <Button
            className={cx('SignUpForm__button')}
            onClick={onFirstStepSubmit}
            type="button"
            isLoading={isSigningUp}
          >
            {intl.formatMessage({ id: 'button.continue' })}
          </Button>
        ) : (
          <>
            <Button
              className={cx('SignUpForm__button', 'SignUpForm__button_left')}
              onClick={() => setIsFirstStep(true)}
              link
            >
              {intl.formatMessage({ id: 'button.back' })}
            </Button>
            <Button
              className={cx('SignUpForm__button')}
              type="submit"
              isLoading={isSigningUp}
              disabled={isButtonDisabled}
            >
              {intl.formatMessage({ id: 'button.continue' })}
            </Button>
          </>
        )}
      </div>
    </form>
  );
}

SignUpForm.defaultProps = {
  className: '',
  successCallback: undefined,
};

SignUpForm.propTypes = {
  className: PropTypes.string,
  successCallback: PropTypes.func,
};
