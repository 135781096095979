import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Field } from 'react-final-form';

import styles from './FieldError.styl';

const cx = classNames.bind(styles);

const FieldError = ({ name, className, message, validateOnFlight }) => {
  return (
    <Field
      name={name}
      subscription={{ touched: true, error: true, dirty: true, submitFailed: true, value: true }}
      render={({ meta: { touched, error, dirty, submitFailed, value } }) => {
        const showError = validateOnFlight
          ? error && touched
          : error && ((dirty && value) || submitFailed) && touched;

        return message ? (
          <div
            className={cx('FieldError', className)}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        ) : showError ? (
          <div className={cx('FieldError', className)}>{error}</div>
        ) : null;
      }}
    />
  );
};

FieldError.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  validateOnFlight: PropTypes.bool,
};

export default FieldError;
