import { productClickHandler, addToCartHandler, removeFromCartHandler } from './handlers';
import { convertCartProductToAnalytic } from '../../helpers';

// State
import * as cartTypes from '../../../modules/cart/actionTypes';

// Utils
import { difference } from '../../../../utils/helpers';

/**
 * Ивент для нажатия на товар
 * (используется для добавления в корзину)
 *
 * @param {object} action
 * @param {object} prevState
 * @param {object} nextState
 *
 * @returns {object} outItem Ивент
 */
export function productClickSuccess(action, prevState, nextState) {
  // check if UPDATE_CART_ITEMS call from constructor

  if (
    action.type === cartTypes.UPDATE_CART_ITEMS &&
    !prevState.location.current.pathname.includes('/constructor')
  ) {
    return null;
  }

  // Continue after check
  const { composition } = difference(nextState.cart, prevState.cart);
  const diffKey = Object.keys(composition)[0];

  // Get clicked product
  const clickedProductInCart = nextState.cart.composition[diffKey] || null;
  if (!clickedProductInCart) {
    console.error(
      `[analytic event] Clicked product not found in CART with this diffKey -> ${diffKey}`,
    );
    return null;
  }

  // Get previous state of clicked product && check if count is decreased
  const previousProductState = prevState.cart.composition[diffKey] || null;
  if (previousProductState && previousProductState.count > clickedProductInCart.count) {
    return null;
  }

  const processedProduct = convertCartProductToAnalytic(clickedProductInCart);

  const outItem = productClickHandler(processedProduct);

  return outItem;
}

// отправляем эвент, когда положили товар в корзину из апсейла
export function addCartItemFromUpsale(action) {
  const { variation, index } = action.payload;

  const product = convertCartProductToAnalytic({ item: variation });

  if (product) {
    delete product.count;

    const products = [{ ...product }]; // Продукт, на который кликнули

    const outItem = {
      event: 'upsale-checkout-web',
      eventCategory: 'Ecommerce',
      eventAction: 'upsale-checkout-web',
      eventLabel: `${index + 1}`,
      ecommerce: {
        click: {
          actionField: { list: product.category },
          products: [...products],
        },
      },
    };

    return outItem;
  }

  return null;
}

/**
 * 1.2 | 2.1.1
 * Ивент для добавления товара в корзину.
 * Также для удаления товара по значку "-" в корзине
 *
 * @param {object} action
 * @param {object} prevState
 * @param {object} nextState
 *
 * @returns {object} outItem Ивент
 */
export function updateCartItemCountSuccess(action, prevState, nextState) {
  // check if UPDATE_CART_ITEMS call from constructor
  // if not - returns null

  if (
    action.type === cartTypes.UPDATE_CART_ITEMS &&
    !prevState.location.current.pathname.includes('/constructor')
  ) {
    return null;
  }

  // Continue after check
  const { composition } = difference(nextState.cart, prevState.cart);
  const diffKey = Object.keys(composition)[0];

  // Get clicked product
  const clickedProductInCart = nextState.cart.composition[diffKey] || null;
  if (!clickedProductInCart) {
    console.error(
      `[analytic event] Clicked product not found in CART with this diffKey -> ${diffKey}`,
    );
    return null;
  }

  const processedProduct = convertCartProductToAnalytic(clickedProductInCart);

  // Get previous state of clicked product && check if count is decreased
  const previousProductState = prevState.cart.composition[diffKey] || null;
  if (previousProductState && previousProductState.count > clickedProductInCart.count) {
    return removeFromCartHandler(processedProduct);
  }

  const currency = nextState.city.userCity.currency.unit || '';

  const outItem = addToCartHandler(processedProduct, currency);

  return outItem;
}

/**
 * 2.1.2
 * Ивент для удаления товара из корзины
 *
 * @param {object} action
 * @param {object} prevState
 * @param {object} nextState
 *
 * @returns {object} outItem Ивент для удаления товара из корзины
 */
export function removeCartItemSuccess(action, prevState, nextState) {
  const { composition } = difference(prevState.cart, nextState.cart);
  const diffKey = Object.keys(composition)[0];

  // Get clicked product
  const removedProductFromCart = prevState.cart.composition[diffKey] || null;
  if (!removedProductFromCart) {
    console.error(
      `[analytic event] Removed product not found in CART with this diffKey -> ${diffKey}`,
    );
    return null;
  }

  const processedProduct = convertCartProductToAnalytic(removedProductFromCart);

  const outItem = removeFromCartHandler(processedProduct);

  return outItem;
}

/**
 * 2.1.3
 * Ивент для очистки корзины
 *
 * @param {object} action
 * @param {object} prevState
 * @param {object} nextState
 *
 * @returns {object} outItem Ивент очистки корзины
 */
export function clearCheckoutSuccess(action, prevState, nextState) {
  const { composition } = difference(prevState.cart, nextState.cart);

  if (!composition || !Array.isArray(composition) || composition.length === 0) {
    console.error(`[analytic event] Composition error: `, composition);
    return null;
  }

  const processedProducts = [];
  composition.forEach(item => {
    const processedItem = convertCartProductToAnalytic(item);
    processedProducts.push(processedItem);
  });

  const outItem = removeFromCartHandler(processedProducts);

  return outItem;
}
