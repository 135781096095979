import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Utils
import { maskedPhoneNumber, plainPhoneNumber } from '../../utils/telephone';

// Config
import config from '../../config';

// Icons
import by from './assets/by.svg';
import kz from './assets/kz.svg';
import ru from './assets/ru.svg';
import kg from './assets/kg.svg';
import pl from './assets/pl.svg';

// Styles
import styles from './PhoneField.styl';

const cx = classNames.bind(styles);

const countryIcons = {
  by,
  kz,
  ru,
  kg,
  pl,
};

export default function PhoneField(props) {
  const {
    className,
    inputClassname,
    name,
    value,
    disabled,
    required,
    autoFocus,
    label,
    description,
    children,
    _ref,
    //
    onChange,
    onFocus,
    onBlur,
  } = props;

  const [isFormatted, setIsFormatted] = useState(true);

  const { firstNumbers } = config.phone;

  const handleOnChange = e => {
    // вдруг мы вставили телефон формата +7 (943) 233-33-33, тогда убираем все лишние символы
    const inputValue = plainPhoneNumber(e.target.value);

    // если мы пытаемся стереть всю строку или до плюса (через backspace + alt или backspace + ctrl)
    if (inputValue.length <= firstNumbers.length) {
      e.target.value = firstNumbers;
      onChange(e);
      return;
    }

    // если вводим не цифру или если мы превысили макс. кол-во символов
    if (!/^[+][\d]+$/.test(inputValue) || inputValue.length > config.phone.digitsCount) {
      return;
    }

    // если мы пытается стереть плюс или 7 или написать вместо них/между ними цифры
    if (inputValue.indexOf(firstNumbers) !== 0) {
      return;
    }

    e.target.value = inputValue;
    onChange(e);
  };

  const handleOnFocus = e => {
    // ставим курсор на последний символ
    e.target.selectionStart = e.target.value.length;
    e.target.selectionEnd = e.target.value.length;
    setIsFormatted(false);
    onFocus(e);
  };

  const handleOnBlur = e => {
    setIsFormatted(true);
    onBlur(e);
  };

  const formattedValue = isFormatted ? maskedPhoneNumber(value) : plainPhoneNumber(value);
  // сейчас children и _ref приходит только из одного места - сайдабара в профиле десктопной версии.
  // Там вообще мрак и его долго переписывать
  // поэтому пусть пока будет костыль

  return (
    <div
      className={cx('PhoneField', className, {
        PhoneField_disabled: disabled,
      })}
    >
      {Boolean(label) && (
        <label className={cx('PhoneField__label')} htmlFor={name}>
          {label} {required && '*'}
        </label>
      )}
      <div className={cx('PhoneField__wrapper')}>
        {!children && (
          <img className={cx('PhoneField__flag')} src={countryIcons[config.legal]} alt="flag" />
        )}
        <input
          className={cx('PhoneField__input', inputClassname)}
          ref={_ref}
          type="tel"
          name={name}
          value={formattedValue || firstNumbers}
          disabled={disabled}
          required={required}
          onChange={handleOnChange}
          onFocus={handleOnFocus}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
          onBlur={handleOnBlur}
          style={children ? { paddingLeft: '16px' } : {}}
        />
        {children}
      </div>
      {description && <div className={cx('PhoneField__description')}>{description}</div>}
    </div>
  );
}

PhoneField.defaultProps = {
  className: '',
  name: '',
  value: '',
  disabled: false,
  required: false,
  autoFocus: false,
  description: '',
  children: null,
  _ref: null,
  //
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
};

PhoneField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.any,
  _ref: PropTypes.object,
  //
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};
