import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

// Config
import { useLocation } from 'react-router-dom';
import config from '../config';

export default function useHelmet() {
  const intl = useIntl();
  const { name = '', declension = '', _supertag } = useSelector(state => state.city.userCity);
  const vars = { declension, name };

  const location = useLocation();

  const homeMeta = {
    title: intl.formatMessage({ id: 'page.menu.helmet' }, vars),
    description: intl.formatMessage({ id: 'page.menu.helmet.description' }, vars),
    other:
      config.legal === 'ru' ? (
        <link rel="canonical" href={`https://papajohns.ru/${_supertag}`} />
      ) : null,
  };
  const canonical = {
    other:
      config.legal === 'ru' ? (
        <link rel="canonical" href={`https://papajohns.ru${location.pathname}`} />
      ) : null,
  };

  return {
    home: homeMeta,
    stockGifts: homeMeta,
    stockConditions: homeMeta,
    activateCode: homeMeta,
    contacts: {
      ...canonical,
      title: intl.formatMessage({ id: 'page.contacts.helmet' }, vars),
      description: intl.formatMessage({ id: 'page.contacts.helmet.description' }, vars),
    },
    order: {
      ...canonical,
      title: intl.formatMessage({ id: 'page.checkout.helmet' }, vars),
    },
    profile: {
      ...canonical,
      title: intl.formatMessage({ id: 'page.profile.helmet' }, vars),
    },
    constructorAlias: {
      ...canonical,
      title: intl.formatMessage({ id: 'page.constructor.helmet' }, vars),
      description: intl.formatMessage({ id: 'page.constructor.helmet.description' }, vars),
    },
    constructorAliasNotId: {
      title: intl.formatMessage({ id: 'page.constructor.helmet' }, vars),
      description: intl.formatMessage({ id: 'page.constructor.helmet.description' }, vars),
    },
    halves: {
      ...canonical,
      title: intl.formatMessage({ id: 'page.halves.helmet' }, vars),
      description: intl.formatMessage({ id: 'page.halves.helmet.description' }, vars),
    },
    signin: {
      ...canonical,
      title: intl.formatMessage({ id: 'page.signIn.helmet' }, vars),
    },
    signup: {
      ...canonical,
      title: intl.formatMessage({ id: 'page.signUp.helmet' }, vars),
    },
    registerForm: {
      ...canonical,
      title: intl.formatMessage({ id: 'page.signUp.helmet' }, vars),
    },
    address: {
      ...canonical,
      title: intl.formatMessage({ id: 'page.address.helmet' }, vars),
    },
    recoveryPassword: {
      ...canonical,
      title: intl.formatMessage({ id: 'page.recoveryPassword.helmet' }, vars),
    },
    stock: {
      ...canonical,
      title: intl.formatMessage({ id: 'page.stocklist.helmet' }, vars),
      description: intl.formatMessage({ id: 'page.stocklist.helmet.description' }, vars),
    },
    stockAlias: null,
    orderTrackingAlias: {
      ...canonical,
      title: intl.formatMessage({ id: 'page.orderStatus.helmet' }, vars),
    },
    comboboxAlias: {
      ...canonical,
      title: intl.formatMessage({ id: 'page.combobox.helmet' }, vars),
    },
    papabonus: {
      ...canonical,
      title: intl.formatMessage({ id: 'page.papabonus.helmet' }, vars),
      description: intl.formatMessage({ id: 'page.papabonus.helmet.description' }, vars),
    },
    nutrition: {
      ...canonical,
      title: intl.formatMessage({ id: 'page.nutrition.helmet' }, vars),
      description: intl.formatMessage({ id: 'page.nutrition.helmet.description' }, vars),
    },
    nutritionAlias: {
      ...canonical,
      title: intl.formatMessage({ id: 'page.recoveryPassword.helmet' }, vars),
      description: intl.formatMessage({ id: 'page.nutrition.helmet.description' }, vars),
    },
    restaurantsList: {
      ...canonical,
      title: intl.formatMessage({ id: 'page.restaurants.helmet' }, vars),
      description: intl.formatMessage({ id: 'page.restaurants.helmet.description' }, vars),
    },
    restaurant: {
      ...canonical,
      title: intl.formatMessage({ id: 'page.restaurant.helmet' }, { ...vars, address: '' }),
      description: intl.formatMessage(
        { id: 'page.restaurant.helmet.description' },
        { ...vars, address: '', city: vars.name },
      ),
    },
    page: null,
  };
}
