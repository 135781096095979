import React from 'react';
import PropTypes from 'prop-types';

import Marker from 'react-google-maps/lib/components/Marker';

// Config
import config from '../../config';

import pinIcon from './assets/pin-green.svg';
import pinIconDisabled from './assets/pin-green_dis.svg';

export default function RestaurantGoogle(props) {

  const handleClick = () => {
    props.onClick();
  };

  const { coordinates, redirected } = props;

  const markerCoords =
    config.legal === 'pl'
      ? new google.maps.LatLng(parseFloat(coordinates[1]), parseFloat(coordinates[0]))
      : new google.maps.LatLng(parseFloat(coordinates[0]), parseFloat(coordinates[1]));

  return (
    <Marker
      position={markerCoords}
      icon={redirected ? pinIconDisabled : pinIcon}
      draggable={false}
      onClick={handleClick}
    />
  );
}

RestaurantGoogle.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  metro: PropTypes.string.isRequired,
  pay_params: PropTypes.object,
  areas_details: PropTypes.array,
  coordinates: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  redirected: PropTypes.bool
};
