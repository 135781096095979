import * as types from './actionTypes';

const initialState = {
  list: [],
  isLoading: false,
  isLoaded: false,
  error: '',
  stopList: [],
};

export default function restaurantReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_LIST_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        error: '',
      };
    }

    case types.FETCH_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload,
        isLoading: false,
        isLoaded: true,
        error: '',
      };

    case types.FETCH_LIST_FAILURE:
      return {
        ...state,
        list: [],
        isLoading: false,
        isLoaded: false,
        error: action.payload,
      };

    case types.FETCH_RESTAURANT_STOPLIST_SUCCESS:
      return {
        ...state,
        stopList: action.payload,
      };

    case types.CLEAR_RESTAURANT_STOPLIST:
      return {
        ...state,
        stopList: [],
      };

    default:
      return state;
  }
}
