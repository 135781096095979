import isomorphicCookies from './cookie/isomorphicCookies';

// userId
export const setUserId = id => isomorphicCookies().set('USR_ID', id);
export const removeUserId = () => isomorphicCookies().remove('USR_ID');
export const getUserId = req => (req ? req.cookies.USR_ID : isomorphicCookies().get('USR_ID'));

// userStatus
export const setUserStatus = status => isomorphicCookies().set('USR_ST', status);
export const removeUserStatus = () => isomorphicCookies().remove('USR_ST');
export const getUserStatus = req => (req ? req.cookies.USR_ST : isomorphicCookies().get('USR_ST'));

// accessToken
export const setAccessToken = token => {
  return isomorphicCookies().set('access_token', token, { expires: 1 });
};

export const removeAccessToken = () => {
  isomorphicCookies().remove('access_token');
  isomorphicCookies().remove('access_token_promo', { domain: '.papajohns.ru' }); // см коммент в getAccessToken
};

export const getAccessToken = req => {
  // access_token_promo актуален только до 01.02.21, после можно удалить
  // он используется в розыгрыше пицц и сработает только если клиент не зарегестрирован и перешел со страници розыгрыша
  if (req) {
    return req.cookies.access_token || req.cookies.access_token_promo;
  }

  return isomorphicCookies().get('access_token') || isomorphicCookies().get('access_token_promo');
};

// UnauthorizedToken
export const getUnauthorizedToken = () => {
  return isomorphicCookies().get('unauthorized_token');
};

export const removeUnauthorizedToken = () => {
  isomorphicCookies().remove('unauthorized_token');
  // last_order_id нужен для отслеживания заказа у неавторизованного пользователя. У авторизованного есть история заказов
  isomorphicCookies().remove('last_order_id');
};

export const setUnauthorizedToken = token => {
  if (!getUnauthorizedToken()) {
    isomorphicCookies().set('unauthorized_token', token, { expires: 1 });
  }
};

export const withUnauthorizedToken = (data = {}) => {
  const token = getUnauthorizedToken();

  return token ? { unauthorized_token: token, ...data } : data;
};
