const prefix = '@combobox';

export const FETCH_COMBOBOXES_REQUEST = `${prefix}/FETCH_COMBOBOXES_REQUEST`;
export const FETCH_COMBOBOXES_SUCCESS = `${prefix}/FETCH_COMBOBOXES_SUCCESS`;
export const FETCH_COMBOBOXES_FAILURE = `${prefix}/FETCH_COMBOBOXES_FAILURE`;

export const SET_IS_ADDED = `${prefix}/SET_IS_ADDED`;
export const ADD_TO_SELECTED_SUCCESS = `${prefix}/ADD_TO_SELECTED_SUCCESS`;
export const REMOVE_FROM_SELECTED_SUCCESS = `${prefix}/REMOVE_FROM_SELECTED_SUCCESS`;
export const REMOVE_INGREDIENT = `${prefix}/REMOVE_INGREDIENT`;
export const CANCEL_REMOVED_INGREDIENTS = `${prefix}/CANCEL_REMOVED_INGREDIENTS`;
export const RESET_COMBOBOX = `${prefix}/RESET_COMBOBOX`;
