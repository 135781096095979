import React from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';

export default function Collapse(props) {
  const {
    children,
    isOpen,
    className,
    contentClassName,
    onAnimationStart,
    onAnimationEnd,
    height,
  } = props;

  return (
    <AnimateHeight
      className={className}
      height={isOpen ? 'auto' : height}
      animateOpacity
      contentClassName={contentClassName}
      onAnimationStart={onAnimationStart}
      onAnimationEnd={onAnimationEnd}
    >
      {children}
    </AnimateHeight>
  );
}

Collapse.defaultProps = {
  className: '',
  contentClassName: '',
  isOpen: false,
  height: 0,
  onAnimationStart: () => {},
  onAnimationEnd: () => {},
};

Collapse.propTypes = {
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  height: PropTypes.number,
  onAnimationStart: PropTypes.func,
  onAnimationEnd: PropTypes.func,
};
