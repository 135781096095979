const prefix = '@stock';

export const FETCH_LIST_REQUEST = `${prefix}/FETCH_LIST_REQUEST`;
export const FETCH_LIST_SUCCESS = `${prefix}/FETCH_LIST_SUCCESS`;
export const FETCH_LIST_FAILURE = `${prefix}/FETCH_LIST_FAILURE`;

export const APPLY_STOCK_REQUEST = `${prefix}/APPLY_STOCK_REQUEST`;
export const APPLY_STOCK_SUCCESS = `${prefix}/APPLY_STOCK_SUCCESS`;
export const APPLY_STOCK_FAILURE = `${prefix}/APPLY_STOCK_FAILURE`;

export const SEND_STOCK_APPLY_EXTRA_REQUEST = `${prefix}/SEND_STOCK_APPLY_EXTRA_REQUEST`;
export const SEND_STOCK_APPLY_EXTRA_SUCCESS = `${prefix}/SEND_STOCK_APPLY_EXTRA_SUCCESS`;
export const SEND_STOCK_APPLY_EXTRA_FAILURE = `${prefix}/SEND_STOCK_APPLY_EXTRA_FAILURE`;

export const CLEAR_STOCK_REQUEST = `${prefix}/CLEAR_STOCK_REQUEST`;
export const CLEAR_STOCK_SUCCESS = `${prefix}/CLEAR_STOCK_SUCCESS`;
export const CLEAR_STOCK_FAILURE = `${prefix}/CLEAR_STOCK_FAILURE`;

export const REMOVE_INGREDIENT = `${prefix}/REMOVE_INGREDIENT`;
export const CANCEL_REMOVED_INGREDIENTS = `${prefix}/CANCEL_REMOVED_INGREDIENTS`;

export const GET_UNCONDITIONAL_LIST_REQUEST = `${prefix}/GET_UNCONDITIONAL_LIST_REQUEST`;
export const GET_UNCONDITIONAL_LIST_SUCCESS = `${prefix}/GET_UNCONDITIONAL_LIST_SUCCESS`;
export const GET_UNCONDITIONAL_LIST_FAILURE = `${prefix}/GET_UNCONDITIONAL_LIST_FAILURE`;

export const ADD_REWARD_REQUEST = `${prefix}/ADD_REWARD_REQUEST`;
export const ADD_REWARD_SUCCESS = `${prefix}/ADD_REWARD_SUCCESS`;
export const ADD_REWARD_FAILURE = `${prefix}/ADD_REWARD_FAILURE`;

export const CLEAR_STOCK_ERRORS_SUCCESS = `${prefix}/CLEAR_STOCK_ERRORS_SUCCESS`;
export const APPLY_STOCK_GIFTS = `${prefix}/APPLY_STOCK_GIFTS`;

export const IS_LOADING_STOCK_APPLY = `${prefix}/IS_LOADING_STOCK_APPLY`;
