const analyticalCookies = [
  'pj_clientId',
  'pj_sessionId',
  'utm_medium',
  'utm_campaign',
  'utm_source',
  'last_paid_medium',
  'last_paid_campaign',
  'click_id',
  'admitad_uid',
  '_ga',
  '_gid',
  'tmr_reqNum',
  '_dc_gtm'
];

export default analyticalCookies;
