import * as types from './actionTypes';
import sailplayApi from './api';

export const fetchSailplayBonuses = () => {
  return dispatch => {
    dispatch({ type: types.FETCH_SAILPLAY_BONUSES_REQUEST });

    return sailplayApi
      .getSaiplayRead()
      .then(data => {
        return dispatch({ type: types.FETCH_SAILPLAY_BONUSES_SUCCESS, payload: data });
      })
      .catch(({ message }) => {
        return dispatch({ type: types.FETCH_SAILPLAY_BONUSES_FAILURE, payload: message });
      });
  };
};

export const fetchSailplayBonusesLazy = () => {
  return (dispatch, getState) => {
    const {
      sailplay: { isLoading, isLoaded },
    } = getState();

    if (!isLoading && !isLoaded) {
      return dispatch(fetchSailplayBonuses());
    }

    return false;
  };
};

export const resetSailplayState = () => {
  return { type: types.RESET_SAILPLAY_STATE };
};
