import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import Amount from '../../../Amount';
import Avatar from '../../Avatar';

// Router
import { paths, getRoute } from '../../../../entry/routes';

// Icons
import ExitIcon from '../../../../icons/exit.svg';

// Styles
import styles from './MobileView.styl';

const cx = classNames.bind(styles);

export default function MobileView(props) {
  const { loyalty, avatar, bonuses, onSignOut, onProfileClick } = props;

  const handleProfileClick = () => {
    onProfileClick?.();
  };

  return (
    <div className={cx('Profile')}>
      <Link
        to={getRoute(paths.profile)}
        className={cx('Profile__body')}
        onClick={handleProfileClick}
      >
        <Avatar className={cx('Profile__avatar')} status={loyalty?.status} avatar={avatar} />
        <div className={cx('Profile__bonuses')}>
          <Amount className={cx('Profile__bonuses-count')} value={bonuses} showCurrency={false} />
          <div className={cx('Profile__bonuses-label')}>
            <FormattedMessage id="profile.popup.bonuses" />
          </div>
        </div>
      </Link>
      <button
        className={cx('Profile__logout')}
        onClick={onSignOut}
        onKeyDown={e => e.key === 'Enter' && onSignOut()}
        type="button"
      >
        <ExitIcon width={24} height={24} />
      </button>
    </div>
  );
}

MobileView.defaultProps = {
  bonuses: 0,
  avatar: '',
  loyalty: {},
  onProfileClick: undefined,
};

MobileView.propTypes = {
  onSignOut: PropTypes.func.isRequired,
  onProfileClick: PropTypes.func,
  bonuses: PropTypes.number,
  avatar: PropTypes.string,
  loyalty: PropTypes.object,
};
