import React, { useMemo } from 'react';
import { Link, useLocation, matchPath } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';

// Assets
import LinkImage from '../../assets/common/button_link.png';

// Styles
import styles from './AdventPageLink.styl';
import { getRoute, paths } from '../../../../entry/routes';

const cx = classNames.bind(styles);
export const AdventPageLink = () => {
  const { pathname } = useLocation();
  const _supertag = useSelector(state => state.city.userCity._supertag);

  const shouldBeHidden = useMemo(() => {
    return !!matchPath(pathname, {
      path: `/${_supertag}${paths.advent}`,
      exact: true,
      strict: false,
    });
  }, [pathname, _supertag, paths]);

  return (
    <Link to={getRoute(paths.advent)}>
      <div
        className={cx('AdventPageLink', { AdventPageLink_hidden: shouldBeHidden })}
        style={{ backgroundImage: `url(${LinkImage})` }}
      />
    </Link>
  );
};
