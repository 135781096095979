const strictlyMass = [
  'thx_link_closed',
  'last_order_id',
  'cancel_timer',
  'city_confirm',
  'city_confirm',
  'addIngredientsHintHasShown',
  'delay_alert_is_shown',
  'city_id',
  'delivery_alert_is_shown',
  'USR_ID',
  'USR_ST',
  'access_token',
  'access_token_promo',
  'unauthorized_token',
  'actionpay',
  'adv_uid',
  'last_order_id',
  'cancel_timer',
  'delivery_alert_is_shown',
];

export default strictlyMass;
