import * as types from './actionTypes';
import footerApi from './api';
import { selectUserCity } from '../city/actions';

export const toggleHomeCategories = payload => ({
  type: types.TOGGLE_HOME_CATEGORIES,
  payload,
});

export const toggleHomeCatalog = payload => ({
  type: types.TOGGLE_HOME_CATALOG,
  payload,
});

export const toggleAlcoholWarning = () => ({
  type: types.TOOGLE_ALCOHOL_WARNING,
});

export const toggleCityPopup = payload => ({
  type: types.TOGGLE_CITY_POPUP,
  payload,
});

export const toggleAuthPopup = payload => ({
  type: types.TOGGLE_AUTH_POPUP,
  payload,
});

export const fetchFooterLinks = () => {
  return (dispatch, getState) => {
    dispatch({ type: types.FETCH_FOOTER_LINKS_REQUEST });
    const isWaitOpenings = getState().city.waitOpenings;
    return footerApi
      .getFooterLinks()
      .then(payload => {
        if (isWaitOpenings) {
          const linksToRemove = ['constructor', 'dostavka', 'nutrition'];
          payload = payload.filter(l => !linksToRemove.includes(l.link));
        }
        return dispatch({ type: types.FETCH_FOOTER_LINKS_SUCCESS, payload });
      })
      .catch(({ message }) => {
        return dispatch({ type: types.FETCH_FOOTER_LINKS_FAILURE, payload: message });
      });
  };
};

export const setCityToRedirect = payload => ({
  type: types.SET_CITY_REDIRECT,
  payload,
});

export const clearCityRedirect = () => ({
  type: types.CLEAR_CITY_REDIRECT,
});

export const acceptCityRedirect = cityToRedirect => dispatch => {
  dispatch(selectUserCity(cityToRedirect));
  dispatch(clearCityRedirect());
};

export const setOpenedProductCard = (id) => ({
  type: types.OPEN_PRODUCT_CARD,
  payload: {id}
})
