import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Styles
import styles from './Paper.styl';

const cx = classNames.bind(styles);

export default function Paper(props) {
  const { className, children, ...otherProps } = props;

  return (
    <div className={cx('Paper', className)} {...otherProps}>
      {children}
    </div>
  );
}

Paper.defaultProps = {
  className: '',
};

Paper.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};
