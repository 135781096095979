import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { SwiperSlide } from 'swiper/react';
import useGiftsSlider from '../useGiftsSlider';

// Components
import Slider from '../../Slider';

// Styles
import styles from './GiftsSliderFourZeroFour.styl';
import GiftSlide from '../../GiftSlide';

const cx = classNames.bind(styles);

export default function GiftsSliderFourZeroFour(props) {
  const { className, withoutButton, screenWidth, catalogSliderNotFound, onAddToCart } = props;
  const { isLoading, isDisabled } = useGiftsSlider();

  const swiperParams = {
    slidesPerView: 3,
    spaceBetween: 16,
    direction: 'horizontal',
  };

  const swiperParamsMobile = {
    slidesPerView: 1,
    spaceBetween: 16,
    direction: 'vertical',
  };

  return (
    <Slider
      className={cx(
        'GiftsSliderFourZeroFour',
        // { GiftsSliderFourZeroFour_disabled: isDisabled },
        className,
      )}
      isOutsideNavigation={screenWidth > 1024}
      swiperParams={screenWidth > 1024 ? swiperParams : swiperParamsMobile}
    >
      {catalogSliderNotFound?.map(gift => (
        <SwiperSlide className={cx('GiftsSliderFourZeroFour__slide')} key={gift.good_id}>
          <GiftSlide gift={gift} onAddToCart={onAddToCart} withoutButton={withoutButton} />
        </SwiperSlide>
      ))}
    </Slider>
  );
}

GiftsSliderFourZeroFour.defaultProps = {
  className: '',
  withoutButton: false,
  screenWidth: 0,
  catalogSliderNotFound: [],
};

GiftsSliderFourZeroFour.propTypes = {
  className: PropTypes.string,
  withoutButton: PropTypes.bool,
  screenWidth: PropTypes.number,
  catalogSliderNotFound: PropTypes.array,
  onAddToCart: PropTypes.func,
};

