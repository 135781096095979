import { repeatOrder } from './events/order';
import {
  addCartItemFromUpsale,
  productClickSuccess,
  updateCartItemCountSuccess,
  removeCartItemSuccess,
  clearCheckoutSuccess
} from './events/cart';
import { visitCheckout, addressCheck, sendCheckoutCancelOrderAnalytic } from './events/checkout';
import { getCheckoutEvents } from './events/checkout/getCheckoutEvents';
import {
  loginSuccess,
  signUpSuccess,
  updateProfileSuccess,
  recoveryPasswordSendConfirmCodeSuccess,
  recoveryPasswordSuccess,
  recoveryPhoneSendConfirmSmsSuccess,
  saveAddressSuccess,
  sendFormForSale
} from './events/user';
import { changeIngredients } from './events/ingredients';
import { localeChange, cityChange, applyStock } from './events/other';

// State
import * as cartTypes from '../modules/cart/actionTypes';
import * as orderTypes from '../modules/order/actionTypes';
import * as locationTypes from '../modules/location';
import * as intlTypes from '../modules/intl/actionTypes';
import * as cityTypes from '../modules/city/actionTypes';
import * as userTypes from '../modules/user/actionTypes';
import * as addressTypes from '../modules/address/actionTypes';
import * as stockTypes from '../modules/stock/actionTypes';
import * as constructorTypes from '../modules/constructor/actionTypes';
import * as catalogTypes from '../modules/catalog/actionTypes';
import * as types from '../modules/user/actionTypes';

const tryFunc = func => (...args) => {
  try {
    return func(...args);
  } catch ({ message }) {
    if (func) {
      console.warn(`[analytic] Error in ${func.name || '[funcName undefined]'}: ${message}`);
    } else {
      console.warn(`[analytic] Analytic function is not defined => message: ${message}`);
    }

    return null;
  }
};

export default function eventsMapper(action) {
  switch (action.type) {
    // Посещение корзины
    case locationTypes.LOCATION_CHANGE:
    case cartTypes.FETCH_ITEMS_SUCCESS:
      return tryFunc(visitCheckout);

    // Обработка ошибок адреса
    case orderTypes.FETCH_DELIVERY_RESTAURANT_DETAILS_SUCCESS:
    case orderTypes.FETCH_DELIVERY_RESTAURANT_DETAILS_FAILURE:
      return tryFunc(addressCheck);

    // Смена типа оплаты/доставки
    case orderTypes.SET_CHECKOUT_PAY_TYPE:
    case orderTypes.SEND_CHECKOUT_DELIVERY_TYPE_ANALYTIC:
      return getCheckoutEvents(action.type, tryFunc);

    // Успешная оплата
    case orderTypes.SEND_ANALYTIC_ORDER_DATA:
      return getCheckoutEvents(action.type, tryFunc);

    // Смена языка
    case intlTypes.LOCALE_CHANGE:
      return tryFunc(localeChange);

    // Смена города
    case cityTypes.SET_USER_CITY:
      return tryFunc(cityChange);

    case orderTypes.SEND_CHECKOUT_CANCEL_ORDER_ANALYTIC:
      return tryFunc(sendCheckoutCancelOrderAnalytic);

    // Повтор заказа
    case cartTypes.ADD_CART_ITEM_FROM_HISTORY:
      return tryFunc(repeatOrder);

    // Добавление в корзины из апсейла
    case cartTypes.ADD_CART_ITEM_FROM_UPSALE: {
      return tryFunc(addCartItemFromUpsale);
    }

    // Добавление продукта в корзину
    case cartTypes.UPDATE_CART_ITEMS:
    case cartTypes.UPDATE_CART_ITEM_SUCCESS:
    case cartTypes.ADD_CART_ITEM_SUCCESS:
      return action.payload.withoutAnalytic // Если стоит флаг "Без аналитики" в payload, то возвращать null
        ? null
        : [tryFunc(productClickSuccess), tryFunc(updateCartItemCountSuccess)];

    // Удаление товара из корзины
    case cartTypes.REMOVE_CART_ITEM_SUCCESS:
      return tryFunc(removeCartItemSuccess);

    // Очистка корзины
    case cartTypes.CLEAR_SUCCESS:
      return tryFunc(clearCheckoutSuccess);

    // Авторизация
    case userTypes.SIGNIN_SUCCESS:
      return tryFunc(loginSuccess);

    // Регистрация
    case userTypes.SIGNUP_SUCCESS:
      return tryFunc(signUpSuccess);

    // Смена данных в профиле
    case userTypes.UPDATE_PROFILE_SUCCESS:
      return tryFunc(updateProfileSuccess);

    // Отправка кода подтверждения смены пароля
    case userTypes.RECOVERY_PASSWORD_SEND_CONFIRM_CODE_SUCCESS:
      return tryFunc(recoveryPasswordSendConfirmCodeSuccess);

    // Успешная смена пароля
    case userTypes.RECOVERY_PASSWORD_SUCCESS:
      return tryFunc(recoveryPasswordSuccess);

    // Отправка кода подтверждения смены телефона
    case userTypes.RECOVERY_PHONE_CONFIRM_SMS_SUCCESS:
      return tryFunc(recoveryPhoneSendConfirmSmsSuccess);

    case addressTypes.SAVE_ADDRESS_SUCCESS:
      return tryFunc(saveAddressSuccess);

    //Запрос при отправке формы, если пиццерия еще не открыта
    case types.SIGNUP_REQUEST:
      return tryFunc(sendFormForSale);

    // Изменение ингредиентов
    case constructorTypes.ADD_PORTION:
    case constructorTypes.REMOVE_PORTION:
    case constructorTypes.REMOVE_INGREDIENT:
    case catalogTypes.REMOVE_INGREDIENT:
      return tryFunc(changeIngredients);

    // Применение промокода
    case stockTypes.APPLY_STOCK_SUCCESS:
    case stockTypes.APPLY_STOCK_FAILURE:
      return tryFunc(applyStock);

    default:
      return [];
  }
}
