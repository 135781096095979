import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './Form.styl';

const cx = classNames.bind(styles);

const Row = ({ className, children, last }) => (
  <div className={cx('Form__row', className, { Form__row_last: last })}>{children}</div>
);

Row.defaultProps = {
  className: '',
  last: false,
};

Row.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  last: PropTypes.bool,
};

export default Row;
