import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';

// Icons
import logoAppleEn from './assets/apple-store/en.svg';
import logoAppleRu from './assets/apple-store/ru.svg';
import logoApplePl from './assets/apple-store/pl.svg';

import logoGoogleEn from './assets/google-play/en.png';
import logoGoogleRu from './assets/google-play/ru.png';
import logoGooglePl from './assets/google-play/pl.png';

import logoHuaweiRu from './assets/app-gallery/ru.png';
import logoHuaweiEn from './assets/app-gallery/en.png';

// Styles
import styles from './MobileAppButton.styl';

const cx = classNames.bind(styles);

export default function MobileAppButton(props) {
  const { className, url, type } = props;

  const locale = useSelector(state => state.intl.locale);

  const icons = useMemo(() => {
    switch (locale) {
      case 'pl': {
        return { logoApple: logoApplePl, logoGoogle: logoGooglePl, logoHuawei: logoHuaweiEn };
      }
      case 'en': {
        return { logoApple: logoAppleEn, logoGoogle: logoGoogleEn, logoHuawei: logoHuaweiEn };
      }
      default: {
        return { logoApple: logoAppleRu, logoGoogle: logoGoogleRu, logoHuawei: logoHuaweiRu };
      }
    }
  }, [locale]);

  return (
    <a
      href={url}
      className={cx('MobileAppButton', className, { [`MobileAppButton_${type}`]: type })}
      target="_blank"
      rel="noopener noreferrer"
    >
      {type === 'apple' && <img alt="appstore" src={icons.logoApple} />}
      {type === 'google' && <img alt="googleplay" src={icons.logoGoogle} />}
      {type === 'huawei' && <img alt="appgallery" src={icons.logoHuawei} />}
    </a>
  );
}

MobileAppButton.defaultProps = {
  className: '',
};

MobileAppButton.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['apple', 'google', 'huawei']).isRequired,
};
