import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';

// Components
import SearchBar from '../partials/SearchBar';
import Popup from '../../Popup';

// Utils
import useOutsideClick from '../../../utils/useOutsideClick';

// Styles
import styles from './CitySelector.styl';

const cx = classNames.bind(styles);

export default function CitySelector(props) {
  const { className, children, moreThanOne, isLanding } = props;

  const [isOpen, setIsOpen] = useState(false);

  const name = useSelector(state => state.city.userCity.name);

  const handleClose = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  const handleToggle = () => {
    setIsOpen(prev => !prev);
  };

  const nodeRef = useOutsideClick(handleClose, isOpen);

  return (
    <div className={cx('CitySelector', className)} ref={nodeRef}>
      <button className={cx('CitySelector__toggle')} onClick={handleToggle} type="button">
        <div className={cx('CitySelector__city')}>{name}</div>
        {moreThanOne && <div className={cx('CitySelector__toggle-arrow')} />}
      </button>
      {moreThanOne && (
        <Popup className={cx('CitySelector__popup')} isOpen={isOpen} isLanding={isLanding}>
          <SearchBar />
          <div className={cx('CitySelector__cities')}>{children}</div>
        </Popup>
      )}
    </div>
  );
}

CitySelector.defaultProps = {
  className: '',
  children: null,
  isLanding: false,
};

CitySelector.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  moreThanOne: PropTypes.bool.isRequired,
  isLanding: PropTypes.bool,
};
