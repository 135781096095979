import * as types from './actionTypes';

const initialState = {
  list: [],
  isLoaded: false,
  isLoading: false,
  error: '',
  cardSelect: {
    cardId: null,
    cryptogram: null,
    cpCardNumber: '',
    cpExpDateMonthYear: '',
    cpCvv: '',
    cpName: '',
  },
};

export default function cardReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        error: '',
      };

    case types.FETCH_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        list: action.payload,
        error: '',
        cardSelect: {
          ...state.cardSelect,
          cardId: action.payload?.[0]?.id || null,
        },
      };

    case types.FETCH_LIST_FAILURE:
      return {
        ...state,
        list: [],
        isLoaded: false,
        isLoading: false,
        error: action.payload,
      };

    case types.SET_CARD_ID: {
      return {
        ...state,
        cardSelect: {
          ...state.cardSelect,
          cardId: action.payload,
        },
      };
    }

    case types.SET_CRYPTOGRAM: {
      return {
        ...state,
        cardSelect: {
          ...state.cardSelect,
          cryptogram: action.payload,
        },
      };
    }

    case types.SET_CARD_FIELDS: {
      return {
        ...state,
        cardSelect: {
          ...state.cardSelect,
          ...action.payload,
        },
      };
    }

    case types.RESET_CARD_FIELDS: {
      return {
        ...state,
        cardSelect: {
          ...state.cardSelect,
          cpCardNumber: '',
          cpExpDateMonthYear: '',
          cpCvv: '',
          cpName: '',
        },
      };
    }

    case types.RESET_CARD_STATE: {
      return { ...initialState };
    }

    default:
      return state;
  }
}
