import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

// Components
import Heading from '../../Heading';
import Button from '../../Button';
import ToggleArrow from '../../ToggleArrow';
import Collapse from '../../Collapse';
import AddressesField from '../../AddressFieldGroup/partials/AddressesField';
import useRestaurantSelector from '../useRestaurantSelector';

// Icons
import pinIconLight from '../assets/pin-green_light.svg';
import metroIcon from '../assets/metro.svg';

// Icons
import ActivePinRedIcon from '../../../icons/map/pin-red.svg';
import PointerIcon from '../../../icons/pointer.svg';

// Config
import config from '../../../config';

// Styles
import styles from './RestaurantSelector.styl';

const cx = classNames.bind(styles);

export default function RestaurantSelector(props) {
  const { className, onSuccessCallback } = props;

  const intl = useIntl();
  const list = useSelector(state => state.restaurant.list);
  const [isListOpen, setIsListOpen] = useState(false);

  const {
    buttonRef,
    isMapLoaded,
    selectedRestaurant,
    restaurantAddress,
    currentMode,
    setRestaurantAddress,
    //
    handleOnRestaurantSelect,
    handleSetCurrentMode,
    handleOnAccept,
    handleOnRestaurantSearchClick,
    //
    modes,
    isButtonExist,
    yMap,
    googleMap,
  } = useRestaurantSelector(onSuccessCallback);

  const handleOnListToggle = () => {
    setIsListOpen(prev => !prev);
  };

  const getRestaurantList = () => {
    const getRestaurantButton = restaurant => (
      <button
        key={restaurant.id}
        className={cx('RestaurantSelector__restaurant', {
          RestaurantSelector__restaurant_active: selectedRestaurant?.id === restaurant.id,
          RestaurantSelector__restaurant_disabled: restaurant.forward_restaurant_id > 0,
        })}
        type="button"
        onClick={
          selectedRestaurant?.id !== restaurant.id
            ? () => handleOnRestaurantSelect(restaurant)
            : undefined
        }
      >
        <ActivePinRedIcon className={cx('RestaurantSelector__restaurant-icon')} />
        {' - '}
        {restaurant.address}
      </button>
    );

    if (list.length >= 9) {
      const firstPart = list.slice(0, 6);
      const secondPart = list.slice(6);

      return (
        <>
          <div className={cx('RestaurantSelector__list')}>
            {firstPart.map(restaurant => getRestaurantButton(restaurant))}
          </div>
          <button
            className={cx('RestaurantSelector__list-button')}
            onClick={handleOnListToggle}
            onKeyDown={e => e.key === 'Enter' && handleOnListToggle()}
            type="button"
          >
            {intl.formatMessage({ id: `link.showMore` })}
            <ToggleArrow className={cx('RestaurantSelector__list-arrow')} isOpen={isListOpen} />
          </button>
          <Collapse isOpen={isListOpen}>
            <div className={cx('RestaurantSelector__list')}>
              {secondPart.map(restaurant => getRestaurantButton(restaurant))}
            </div>
          </Collapse>
        </>
      );
    }

    return (
      <div className={cx('RestaurantSelector__list')}>
        {list.map(restaurant => getRestaurantButton(restaurant))}
      </div>
    );
  };

  return (
    <div className={cx('RestaurantSelector', className)}>
      {/* Выберите ресторан на карте */}
      <Heading className={cx('RestaurantSelector__title')} level={3}>
        {intl.formatMessage({ id: 'order.pickup.selectRestaurant.label1' })}
        {': '}
        {modes.map((mode, i) => (
          <>
            <span
              key={mode.key}
              className={cx('RestaurantSelector__title-span', {
                'RestaurantSelector__title-span_active': mode.key === currentMode,
              })}
              onClick={() => handleSetCurrentMode(mode.key)}
              onKeyDown={() => null}
              role="button"
              tabIndex={0}
            >
              {mode.description}
            </span>
            {Boolean(i !== modes.length - 1) && ' | '}
          </>
        ))}
      </Heading>
      {currentMode === 'search' && (
        <>
          <div className={cx('RestaurantSelector__subtitle')}>
            {intl.formatMessage({ id: 'order.pickup.selectRestaurant.search' })}
            {':'}
          </div>
          <form className={cx('RestaurantSelector__search')} autoComplete="off">
            {/* Адрес */}
            <AddressesField
              className={cx('RestaurantSelector__search-input')}
              onAddressSelect={address => handleOnRestaurantSearchClick(address)}
              newest
              input={{
                name: 'restaurantAddress',
                value: restaurantAddress,
                onChange: value => setRestaurantAddress(value),
                placeholder: intl.formatMessage({ id: 'page.contacts.searchAddress.placeholder' }),
              }}
            />
            <Button
              className={cx('RestaurantSelector__search-button')}
              onClick={() => handleOnRestaurantSearchClick(restaurantAddress)}
            >
              <PointerIcon />
            </Button>
          </form>
        </>
      )}
      {currentMode === 'map' && (
        <div className={cx('RestaurantSelector__map')}>
          {!isMapLoaded && <div className={cx('RestaurantSelector__preloader')} />}
          {config.legal === 'pl' ? googleMap : yMap}
        </div>
      )}
      {currentMode === 'list' && (
        <>
          <div className={cx('RestaurantSelector__subtitle')}>
            {intl.formatMessage({ id: 'order.pickup.selectRestaurant.subtitle' })}
            {':'}
          </div>
          {getRestaurantList()}
        </>
      )}
      {/* Выбранный ресторан */}
      {selectedRestaurant && (
        <div className={cx('RestaurantSelector__info')}>
          <div className={cx('RestaurantSelector__address')} level={4}>
            <img className={cx('RestaurantSelector__info-icon')} src={pinIconLight} alt="pin" />
            {`- ${selectedRestaurant.address}`}
          </div>
          {selectedRestaurant.metro && (
            <div className={cx('RestaurantSelector__metro')}>
              <img className={cx('RestaurantSelector__info-icon')} src={metroIcon} alt="pin" />
              {`- ${intl.formatMessage({
                id: 'map.infoWindow.metro',
              })} ${selectedRestaurant.metro.split(';').join('; ')}`}
            </div>
          )}
          {selectedRestaurant.open && (
            <div className={cx('RestaurantSelector__schedule')}>
              {Object.keys(selectedRestaurant.open).map(day => (
                <div key={day} className={cx('RestaurantSelector__schedule-wrapper')}>
                  <div className={cx('RestaurantSelector__schedule-day')}>{day}</div>
                  <div className={cx('RestaurantSelector__schedule-time1')}>
                    {selectedRestaurant.open[day].split(' - ')[0]}
                  </div>
                  <div className={cx('RestaurantSelector__schedule-time2')}>
                    {selectedRestaurant.open[day].split(' - ')[1]}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      {isButtonExist && (
        <Button
          className={cx('RestaurantSelector__accept')}
          onClick={handleOnAccept}
          disabled={!selectedRestaurant}
          buttonRef={buttonRef}
        >
          {intl.formatMessage({ id: 'button.accept' })}
        </Button>
      )}
    </div>
  );
}

RestaurantSelector.defaultProps = {
  className: '',
  onSuccessCallback: undefined,
};

RestaurantSelector.propTypes = {
  className: PropTypes.string,
  onSuccessCallback: PropTypes.func,
};
