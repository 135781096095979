export function sortByPriceUpToDown(arr) {
  return arr.sort(function (a, b) {
    const priceA = a.variations[0].price;
    const priceB = b.variations[0].price;
    return priceB - priceA;

  });
}

export function sortByPriceDownToUp(arr) {
  return arr.sort(function (a, b) {
    const priceA = a.variations[0].price;
    const priceB = b.variations[0].price;
    return priceA - priceB;
  });
}
