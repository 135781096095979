import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Router
import { paths, getRoute } from '../../entry/routes';

// Config
import config from '../../config';

// Styles
import styles from './Nav.styl';

const cx = classNames.bind(styles);

export default function Nav(props) {
  const { className, onClose, view } = props;

  const sailplayType = useSelector(state => state.city.userCity.sailplay?.type);
  const isAllowedCity = useSelector(state => !state.city.waitOpenings);

  const links = [
    {
      to: getRoute(paths.home),
      id: 'header.nav.menu',
    },
    isAllowedCity && {
      to: getRoute(paths.stock),
      id: 'header.nav.stock',
    },
    isAllowedCity &&
    sailplayType !== 'none' &&
    config.legal !== 'pl' && {
      to: getRoute(paths.papabonus),
      id: 'header.nav.papabonus',
    },
    {
      to: getRoute(paths.contacts),
      id: 'header.nav.contacts',
    },
  ].filter(Boolean);

  return (
    <nav className={cx('Nav', `Nav_${view}`, className)}>
      {links.map(link => (
        <NavLink
          key={link.id}
          to={link.to}
          className={cx('Nav__link')}
          activeClassName={cx('Nav__link_active')}
          onClick={onClose}
          exact
        >
          <FormattedMessage id={link.id} />
        </NavLink>
      ))}
    </nav>
  );
}

Nav.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  view: PropTypes.string,
};

Nav.defaultProps = {
  className: '',
  onClose: () => null,
  view: 'desktop',
};
