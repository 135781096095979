import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import DesktopView from './DesktopView';
import MobileView from './MobileView';

export default function RadioField(props) {

  const isPhone = useSelector(state => state.responsive.isPhone || false);

  const { className, children, dispatch, description, label, name, inputRef, ...otherProps } = props;

  const inputProps = {
    ...otherProps,
    ref: inputRef,
    type: 'radio',
    name
  };

  const View = isPhone ? MobileView : DesktopView;

  return (
    <View
      {...props}
      className={className}
      children={children}
      inputProps={inputProps}
      label={label}
      description={description}
    />
  );
}

RadioField.defaultProps = {
  className: '',
  isPhone: false,
  checked: false,
  disabled: false,
  description: ''
};

RadioField.propTypes = {
  className: PropTypes.string,
  isPhone: PropTypes.bool,
  children: PropTypes.any,
  inputRef: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  description: PropTypes.string
};
