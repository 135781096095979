import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Styles
import styles from './Preloader.styl';

const cx = classNames.bind(styles);

export default function Preloader(props) {
  const { className, loaderClassName } = props;

  return (
    <div className={cx('Preloader', className)}>
      <div className={cx('Preloader__spinner', loaderClassName)} />
    </div>
  );
}

Preloader.defaultProps = {
  className: '',
  loaderClassName: '',
};

Preloader.propTypes = {
  className: PropTypes.string,
  loaderClassName: PropTypes.string
};
