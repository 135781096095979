import * as types from './actionTypes';

const initialState = { isLoaded: false, isLoading: false, list: {}, compositeList: [] };

export default function pageReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_COMPOSITE_DETAIL_REQUEST:
    case types.FETCH_PAGE_REQUEST:
      return { ...state, isLoading: true, isLoaded: false, error: '' };

    case types.FETCH_COMPOSITE_DETAIL_SUCCESS:
    case types.FETCH_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        list: { ...state.list, [action.payload.alias]: { ...action.payload.data } },
      };

    case types.FETCH_COMPOSITE_DETAIL_FAILURE:
    case types.FETCH_PAGE_FAILURE:
      return { ...state, isLoading: false, isLoaded: false, error: action.payload.error };

    default:
      return state;
  }
}
