// Utils
import { isCombobox, isPromotional, isUpsale, isGood, isBonus } from '../../../utils/cart';

const formatIngredient = ingredient => ({
  id: ingredient.id,
  type: ingredient.type,
  count: ingredient.count,
});

const getGoodData = good => {
  const {
    id,
    include_ingredients = [],
    added_ingredients = [],
    removed_ingredients = [],
    added_sauces = [],
  } = good;

  const addedIngredients = added_ingredients.map(formatIngredient);
  const removedIngredients = removed_ingredients.map(formatIngredient);
  const manuallyRemovedIngredients = include_ingredients
    .filter(ingredient => ingredient.removed)
    .map(ingredient => ({ id: ingredient.id, count: 0 }));

  return {
    good_id: id,
    ingredients: [...addedIngredients, ...removedIngredients, ...manuallyRemovedIngredients],
    sauces: added_sauces.length > 0 ? added_sauces.map(sauce => sauce.id) : undefined,
  };
};

export const preSaveFormatComposition = composition => {
  const filtredComposition = composition.filter(
    item =>
      isGood(item) || isCombobox(item) || isPromotional(item) || isBonus(item) || isUpsale(item),
  );

  return filtredComposition.map(good => {
    const { item, ...otherProps } = good;

    if (!isCombobox(good)) {
      return {
        ...getGoodData(item),
        ...otherProps,
      };
    }
    return {
      combo_box_id: item.combo_box_id,
      combo_box_items: item.combo_box_items.map(comboBoxItem => ({
        ...getGoodData(comboBoxItem),
        level_id: comboBoxItem.level_id,
        price: comboBoxItem.price,
        discount_price: comboBoxItem.discount_price,
        count: comboBoxItem.count,
        type: 'good',
      })),
      ...otherProps,
    };
  });
};
