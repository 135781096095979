import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { useIntl } from 'react-intl';
import {Link, Route, useHistory, useLocation} from 'react-router-dom';

// Actions
import { addCartItem } from '../../state/modules/cart/actions';
import { fetchBannersIfNeeded } from '../../state/modules/banner/actions';
import { applyStock } from '../../state/modules/stock/actions';
import { fetchCatalogNotFoundSliderRequest } from '../../state/modules/catalog/actions';

// Utils
import { setGlobalMessage } from '../../utils/setGlobalMessage';

// Selectors
import {isThemeNewYear} from "../../state/modules/city/selectors";

// Components
import Container from '../../components/Container';
import Heading from '../../components/Heading';
import Button from '../../components/Button';
import GiftsSliderFourZeroFour from '../../components/GiftsSliderFourZeroFour/DesktopView';
import GiftSlide from '../../components/GiftSlide/GiftSlide';

// Assets
import cover from './assets/new404.png';
import ny_404 from '../../assets/NY_images/NY_404.png';

// Router
import { getRoute, paths } from '../../entry/routes';

// Hooks
import useProductList from "../Home/ProductList/useProductList";

// Styles
import styles from './Error404.styl';

const cx = classNames.bind(styles);

export default function Error404Page() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { goods } = useProductList();
  const categories = useSelector(state => state.catalog.products.categories);

  const [isRedirectChecked, setIsRedirectChecked] = useState(false)

  const categoriesMap = useRef(categories.map(cat =>cat.alias)).current
  const goodsMap = useRef(goods.map(good =>good.slug)).current

  useEffect(() => {
    dispatch(fetchBannersIfNeeded());
  }, []);

  const redirectToCategory = (category) => {
    if (categoriesMap.includes(category)) {
      history.push(getRoute(paths.category, category));
    }
  };

  const redirectToProduct = (category, good) => {
    if (categoriesMap.includes(category) && goodsMap.includes(good)) {
      history.push(getRoute(paths.productAlias, category, good.toLowerCase()));
    }
  };

  useLayoutEffect(() => {
    if (goods.length) {
      const [, , category, good] = location.pathname.split('/')
      if (category) {
        if (good) {
          redirectToProduct(category, good)
        } else {
          redirectToCategory(category)
        }
      }
    }
    setIsRedirectChecked(true)
  }, [goods, categoriesMap, goodsMap]);

  const banners = useSelector(state => state.banner.list);
  const stockCode = useSelector(state => state.stock.data.code);

  const catalogSliderNotFound = useSelector(state => state.catalog.sliderNotFound);
  const isNewYearMode = useSelector(isThemeNewYear);
  const isWaitingForOpenings = useSelector(state => state.city.waitOpenings);

  const [screenWidth, setScreenWidth] = useState();

  const onAddToCart = async id => {
    await dispatch(addCartItem({ composition: [{ type: 'good', item: { id } }] }));
  };

  const banner = useMemo(() => {
    const randomizedBannerIndex = Math.floor(Math.random() * banners.length);

    return banners[randomizedBannerIndex];
  }, [banners]);

  const onBannerClick = async () => {
    // переходим по линку после нажатия
    if (banner.link) {
      const urlRegex = new RegExp('^(?:[a-z]+:)?//', 'i');

      if (urlRegex.test(banner.link.trim())) {
        Object.assign(document.createElement('a'), { href: banner.link }).click();
      } else {
        history.push(getRoute(banner.link));
      }
      // применяем промокод после нажатия
    } else if (banner.stock) {
      try {
        // если ранее не был применен
        if (stockCode !== banner.stock) {
          await dispatch(applyStock({ stock_code: banner.stock }));
          setGlobalMessage(intl.formatMessage({ id: 'promocode.apply.success' }), {
            type: 'success',
          });
        }
      } finally {
        history.push(getRoute(paths.home));
      }
    }
  };

  useEffect(() => {
    setScreenWidth(window.innerWidth);
    dispatch(fetchCatalogNotFoundSliderRequest());
  }, []);

  useEffect(() => {
    const setWidth = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', setWidth);
    return () => window.removeEventListener('resize', setWidth);
  });

  return (
    <Route>
      {({ staticContext }) => {
        if (staticContext) {
          staticContext.statusCode = 404;
        }
        if (!isRedirectChecked){
          return <div className={cx('Error404__spinner', 'Error404__spinner_loadingLayout')} />
        }
        return (
          <>
            <Helmet title={intl.formatMessage({ id: 'page.error404.helmetTitle' })} />
            <Container
              className={cx('Error404', {
                'Error404__new-year': isNewYearMode,
              })}
            >
              <div className={cx('Error404__header')} />
              <div className={cx('Error404__wrapper')}>
                <div className={cx('Error404__content')}>
                  <div className={cx('Error404__wrapper-another')}>
                    {screenWidth <= 1024 && (
                      <Link to={getRoute(paths.home)} className={cx('Error404__button-back')} />
                    )}
                    <Heading className={cx('Error404__title')} level={1}>
                      {intl.formatMessage({ id: 'page.error404.text' })}
                    </Heading>
                    <div>
                      {screenWidth > 1024 && (
                        <p className={cx('Error404__text')}>
                          {intl.formatMessage({ id: 'page.error404.mobile.text2' })}
                        </p>
                      )}
                      {screenWidth > 1024 && (
                        <Button className={cx('Error404__button')} to={getRoute(paths.home)}>
                          {intl.formatMessage({ id: 'button.goToMenu.goToMain2' })}
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className={cx('Error404__wrapper_banner')}>
                    {banner ? (
                      <img
                        className={cx('Error404__image', { Error404__image_banner: banner })}
                        onClick={onBannerClick}
                        src={
                          isNewYearMode
                            ? ny_404
                            : banner?.image_full || banner?.image_full_webp || cover
                        }
                        alt="error"
                      />
                    ) : (
                      <div className={cx('Error404__spinner')} />
                    )}
                  </div>

                  {screenWidth <= 1024 && (
                    <p className={cx('Error404__text')}>
                      {intl.formatMessage({ id: 'page.error404.mobile.text2' })}
                    </p>
                  )}
                  {screenWidth <= 1024 && (
                    <Button className={cx('Error404__button')} to={getRoute(paths.home)}>
                      {intl.formatMessage({ id: 'button.goToMenu.goToMain2' })}
                    </Button>
                  )}
                </div>
              </div>
            </Container>

            <div className={cx('Error404__footer')}>
              {!isWaitingForOpenings ? (
                <div className={cx('Error404__footer-wrapper')}>
                  <p className={cx('Error404__footer-title')}>
                    {intl.formatMessage({ id: 'page.error404.mobile.text3' })}
                  </p>
                  <div className={cx('Error404__footer-slider-block')}>
                    {screenWidth > 1024 ? (
                      <GiftsSliderFourZeroFour
                        screenWidth={screenWidth}
                        catalogSliderNotFound={catalogSliderNotFound}
                        onAddToCart={onAddToCart}
                      />
                    ) : (
                      catalogSliderNotFound?.map(gift => (
                        <GiftSlide
                          key={`${gift?.image_url}`}
                          gift={gift}
                          onAddToCart={onAddToCart}
                          withoutButton={false}
                        />
                      ))
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </>
        );
      }}
    </Route>
  );
}
