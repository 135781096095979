const prefix = '@address';

export const FETCH_ADDRESSES_REQUEST = `${prefix}/FETCH_ADDRESSES_REQUEST`;
export const FETCH_ADDRESSES_SUCCESS = `${prefix}/FETCH_ADDRESSES_SUCCESS`;
export const FETCH_ADDRESSES_FAILURE = `${prefix}/FETCH_ADDRESSES_FAILURE`;

export const SAVE_ADDRESS_REQUEST = `${prefix}/SAVE_ADDRESS_REQUEST`;
export const SAVE_ADDRESS_SUCCESS = `${prefix}/SAVE_ADDRESS_SUCCESS`;
export const SAVE_ADDRESS_FAILURE = `${prefix}/SAVE_ADDRESS_FAILURE`;

export const DELETE_ADDRESS_REQUEST = `${prefix}/DELETE_ADDRESS_REQUEST`;
export const DELETE_ADDRESS_SUCCESS = `${prefix}/DELETE_ADDRESS_SUCCESS`;
export const DELETE_ADDRESS_FAILURE = `${prefix}/DELETE_ADDRESS_FAILURE`;

export const RESET_ADDRESS_STATE = `${prefix}/RESET_ADDRESS_STATE`;
