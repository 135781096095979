import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Transition } from 'react-transition-group';
import { useSelector } from 'react-redux';

// Components
import ClickOutHandler from '../../OnClickOut';
import Lang from '../Lang';

// Utils
import isomorphicCookies from '../../../utils/cookie/isomorphicCookies';

// Configs
import config from '../../../config';

// Styles
import styles from './DesktopView.styl';

const cx = classNames.bind(styles);

export default function DesktopView(props) {
  const { className } = props;

  const locale = useSelector(state => state.intl.locale);
  const [isOpen, setIsOpen] = useState(false);

  const onLocaleChange = newLocale => {
    if (newLocale !== locale) {
      isomorphicCookies().set('locale', newLocale);
      window.location.reload();
    }

    return null;
  };

  const handleOnToggle = () => {
    setIsOpen(prev => !prev);
  };

  const handleOnLocaleChange = newLocale => {
    onLocaleChange(newLocale);
    setIsOpen(false);
  };

  return (
    <div className={cx('LangSwitcher', className)}>
      <Lang onLocaleClick={handleOnToggle} locale={locale} />
      <Transition in={isOpen} timeout={{ enter: 175, exit: 0 }} mountOnEnter unmountOnExit>
        {state => (
          <ClickOutHandler onClickOut={handleOnToggle}>
            <div className={cx('LangSwitcher__dropdown', `LangSwitcher__dropdown_${state}`)}>
              {config.langList.map(l => (
                <Lang
                  key={l}
                  locale={l}
                  onLocaleClick={handleOnLocaleChange}
                  isActive={l === locale}
                />
              ))}
            </div>
          </ClickOutHandler>
        )}
      </Transition>
    </div>
  );
}

DesktopView.defaultProps = {
  className: '',
};

DesktopView.propTypes = {
  className: PropTypes.string,
};
