import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

// Icons
import { useSelector } from 'react-redux';
import ChatIcon from '../../../../icons/chat.svg';

import styles from './ChatButton.styl';

const cx = classNames.bind(styles);

const ChatButton = ({ isVisible, openHandler, unreadedMessages }) => {
  const isWaitingForOpenings = useSelector(state => state.city.waitOpenings);

  return (
    <>
      <button
        type="button"
        className={cx(!isVisible ? 'ChatButton' : 'ChatButton_hidden', {
          ChatButton_offset: isWaitingForOpenings,
        })}
        onClick={openHandler}
      >
        <ChatIcon width={24} height={24} />
        {Boolean(unreadedMessages.length) && (
          <div className={cx('ChatButton__badge')}>{unreadedMessages.length}</div>
        )}
      </button>
    </>
  );
};

ChatButton.defaultProps = {
  isVisible: false,
  unreadedMessages: [],
};

ChatButton.propTypes = {
  isVisible: PropTypes.bool,
  openHandler: PropTypes.func.isRequired,
  unreadedMessages: PropTypes.array,
};

export default ChatButton;
