import { useRef, useEffect } from 'react';

export default function useOutsideClick(onOutsideClick, condition) {
  const elementRef = useRef(null);

  const handleClick = e => {
    if (condition && !elementRef.current?.contains(e.target)) {
      onOutsideClick(e);
    }
  };

  const onEscPress = e => {
    if (e.key === 'Escape') {
      onOutsideClick(e);
    }
  };

  useEffect(() => {
    if (condition) {
      document.addEventListener('click', handleClick, { passive: true });
      document.addEventListener('keydown', onEscPress, { passive: true });
    }

    return () => {
      if (condition) {
        document.removeEventListener('click', handleClick);
        document.removeEventListener('keydown', onEscPress);
      }
    };
  }, [condition]);

  return elementRef;
}
