import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Icons
import SilverIcon from '../../icons/awards/silver.svg';
import GoldIcon from '../../icons/awards/gold.svg';
import PlatinumIcon from '../../icons/awards/platinum.svg';
import UltimateIcon from '../../icons/awards/ultimate.svg';

// Styles
import styles from './Award.styl';

const cx = classNames.bind(styles);

export default function Award(props) {
  const { className, name } = props;

  const Icon = useMemo(() => {
    if (name === 'gold') {
      return GoldIcon;
    }

    if (name === 'platinum') {
      return PlatinumIcon;
    }

    if (name === 'ultimate') {
      return UltimateIcon;
    }

    return SilverIcon;
  }, [name]);

  return <Icon className={cx('Award', className)} />;
}

Award.defaultProps = {
  className: '',
  name: 'silver',
};

Award.propTypes = {
  className: PropTypes.string,
  name: PropTypes.oneOf(['silver', 'gold', 'platinum', 'ultimate']),
};
