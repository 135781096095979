const prefix = '@halves';

export const FETCH_LIST_REQUEST = `${prefix}/FETCH_LIST_REQUEST`;
export const FETCH_LIST_SUCCESS = `${prefix}/FETCH_LIST_SUCCESS`;
export const FETCH_LIST_FAILURE = `${prefix}/FETCH_LIST_FAILURE`;

export const SELECT_PIZZA = `${prefix}/SELECT_PIZZA`;
export const SET_IS_ADDED = `${prefix}/SET_IS_ADDED`;
export const CHANGE_DOUGH = `${prefix}/CHANGE_DOUGH`;
export const CHANGE_SIZE = `${prefix}/CHANGE_SIZE`;
export const TOGGLE_STUFFED_CRUST = `${prefix}/TOGGLE_STUFFED_CRUST`;
export const SELECT_STUFFED_CRUST = `${prefix}/SELECT_STUFFED_CRUST`;
export const RESET = `${prefix}/RESET`;
