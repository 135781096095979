import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './Suggestions.styl';

const cx = classNames.bind(styles);

function debounce(fn, delay) {
  let timer = null;

  return function() {
    clearTimeout(timer);
    timer = setTimeout(() => fn.apply(this, arguments), delay);
  };
}


const initialState = {
  query: '',
  suggestions: [],
  focusedIndex: -1,
  isOpen: true,
  styleStandart: false,
};

class ReactSuggestions extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
      query: props.query || initialState.query,
    };

    if (!props.token) {
      console.warn(
        'react-suggestions: You need pass dadata api-key to props. See https://dadata.ru/api/suggest/',
      );
    }

    this.myRef = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.query !== this.props.query) {
      this.setState({
        query: nextProps.query,
      });
    }

    if (nextProps.token !== this.props.token) {
      if (!nextProps.token) {
        console.warn(
          'react-suggestions: You need pass dadata api-key to props. See https://dadata.ru/api/suggest/',
        );
      }
    }
  }

  loadSuggestions = debounce((query, token, count, locations = []) => {
    if (this.xhr) {
      this.xhr.abort();
    }

    this.xhr = new XMLHttpRequest();
    this.xhr.open('POST', 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address');
    this.xhr.setRequestHeader('Accept', 'application/json');
    this.xhr.setRequestHeader('Authorization', `Token ${token}`);
    this.xhr.setRequestHeader('Content-Type', 'application/json');
    this.xhr.send(JSON.stringify({ query, count, locations }));

    this.xhr.onreadystatechange = () => {
      if (this.xhr.readyState !== 4) {
        return;
      }

      if (this.xhr.status === 200) {
        const response = JSON.parse(this.xhr.response);

        if (response && response.suggestions) {
          this.setState({ suggestions: response.suggestions });
        }
      }
    };
  }, this.props.delay);

  handleChange = evt => {
    const { min, token, count, locations } = this.props;

    if (!token) {
      return;
    }

    const { value: query } = evt.target;
    const state = { query, isOpen: true };

    if (query.length < min) {
      state.suggestions = [];
    } else {
      this.loadSuggestions(query, token, count, locations);
    }

    this.setState({ ...state });
  };

  handleFocus = evt => {
    const { onFocus } = this.props;
    const { suggestions } = this.state;

    this.setState({ isOpen: true });

    if (suggestions.length <= 0) {
      this.handleChange(evt);
    }

    if (typeof onFocus === 'function') {
      onFocus(evt);
    }
  };

  handleBlur = event => {
    const { onBlur } = this.props;
    const { suggestions, focusedIndex } = this.state;
    const { query } = this.state;

    if (typeof onBlur === 'function') {
      const suggIndex = focusedIndex === -1 ? 0 : focusedIndex;
      if ((suggestions || []).length > 0 && query) {
        onBlur(event, suggestions[suggIndex]);
      } else {
        onBlur(event);
      }
    }

    this.setState({
      isOpen: false,
      focusedIndex: -1,
    });
  };

  handleHover = focusedIndex => {
    this.setState({ focusedIndex });
  };

  handleKeyPress = evt => {
    if ([40, 38, 13].includes(evt.which)) {
      evt.preventDefault();

      let { suggestions, focusedIndex: index } = this.state;
      const length = this.props.count - 1;

      if (evt.which === 40) {
        const result = index === length || index === -1 ? 0 : ++index;

        this.setState({ focusedIndex: result });
      }

      if (evt.which === 38) {
        const result = index === 0 || index === -1 ? length : --index;

        this.setState({ focusedIndex: result });
      }

      if (evt.which === 13 && index !== -1 && suggestions[index]) {
        this.handleSelect(suggestions[index], index);
      }
    }
  };

  handleSelect = (suggestion, index) => {
    const { onAddressSelect, handleSetCoordinates } = this.props;
    this.setState({
      query: suggestion.value,
      isOpen: false,
    });
    const coordinates = {
      latitude: suggestion?.data?.geo_lat,
      longitude: suggestion?.data?.geo_lon,
    };
    handleSetCoordinates && handleSetCoordinates(coordinates);
    if (typeof onAddressSelect === 'function') {
      onAddressSelect(suggestion, index);
    }
  };

  renderSuggestions = () => {
    const { suggestions = [], focusedIndex } = this.state;

    const result = suggestions.map((suggestion, index) => {
      return (
        <li
          className={cx({ Suggestions_focused: index === focusedIndex })}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          onMouseDown={() => this.handleSelect(suggestion, index)}
          onMouseEnter={() => this.handleHover(index)}
        >
          {suggestion.value}
        </li>
      );
    });

    return result;
  };

  moveCaret = (e) => {
    this.setState({ ...this.state, styleStandart: true });
  };

  onBlurHandler = () => {
    this.setState({ ...this.state, styleStandart: false });
  };

  componentDidMount() {
    this.state.styleStandart && this.myRef && this.myRef.current?.setSelectionRange(this.myRef.current.value.length, this.myRef.current.value.length);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.styleStandart !== this.state.styleStandart) {
      this.state.styleStandart && this.myRef && this.myRef.current?.setSelectionRange(this.myRef.current.value.length, this.myRef.current.value.length);
    }
  }


  render() {
    const {
      query: omit,
      token,
      min,
      count,
      className,
      delay,
      locations,
      cityList,
      userCity,
      label,
      onBlur,
      ...rest
    } = this.props;
    const { query, suggestions, isOpen } = this.state;

    return (
      <div className={cx('Suggestions')}>
        {Boolean(label) && (
          <label className={cx('Suggestions__label')} htmlFor={rest.name}>
            {label} {rest.required && '*'}
          </label>
        )}
        <input
          className={cx(className, 'Suggestions__input', { 'Suggestions__standart': this.state.styleStandart })}
          type="text"
          value={query}
          onChange={this.handleChange}
          ref={this.myRef}
          onKeyDown={() => this.setState({ ...this.state, styleStandart: true })}
          onClick={this.moveCaret}
          autoComplete={true}
          placeholder={label || rest.placeholder}
          onBlur={this.onBlurHandler}
        />
        {Boolean(suggestions.length) && isOpen && <ul>{this.renderSuggestions()}</ul>}
      </div>
    );
  }
}

export default ReactSuggestions;

ReactSuggestions.defaultProps = {
  token: '',
  query: '',
  min: 2,
  count: 10,
  delay: 0,
};

ReactSuggestions.propTypes = {
  query: PropTypes.string,
  token: PropTypes.string,
  min: PropTypes.number,
  count: PropTypes.number,
  delay: PropTypes.number,
  locations: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onSelect: PropTypes.func,
};
