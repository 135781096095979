import * as actionTypes from './actionTypes';

import config from '../../../config';

const initialState = {
  locale: config.lang,
  messages: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOCALE_CHANGE: {
      return {
        ...state,
        locale: action.payload.locale,
        messages: action.payload.messages,
      };
    }

    default:
      return state;
  }
};
