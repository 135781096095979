import { getAccessToken } from '../../../utils/auth';
import { get, put, patch, del } from '../../../utils/api';

export default {
  getAddresses: () =>
    getAccessToken()
      ? get({ url: 'user/address' })
      : Promise.reject(new Error('User is not Authenticated')),

  saveAddress: (params, isUpdate) =>
    isUpdate
      ? patch({ url: 'user/address/update', data: { ...params } })
      : put({ url: 'user/address/create', data: { ...params } }),

  deleteAddress: id => del({ url: 'user/address/delete', data: { address_id: id } }),
};
