import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Helpers
import { formatNumber } from '../../utils/formatters';

// Selectors
import {isThemeHalloween, isThemeNewYear} from "../../state/modules/city/selectors";

// Styles
import styles from './Amount.styl';

const cx = classNames.bind(styles);

export default function Amount(props) {
  const { className, value, newValue, showCurrency, size, prefix, bonus, specialStylesMode } = props;

  const intl = useIntl();
  const unit = useSelector(state => state.city.userCity.currency?.unit);
  const isHalloweenMode = useSelector(isThemeHalloween);
  const isNewYearMode = useSelector(isThemeNewYear);

  const isNewValueExist = Boolean(newValue) || newValue === 0;
  const currentPrice = isNewValueExist && newValue !== value ? newValue : value;
  const oldPrice = isNewValueExist && newValue !== value ? value : null;
  const currency = bonus ? (
    intl.formatMessage({ id: 'bonus' })
  ) : (
    <span className={cx('Amount__currency')}>{unit}</span>
  );

  if (oldPrice !== null) {
    return (
      <div className={cx('Amount', className, `Amount_size_${size}`, {
        'Amount__halloween': isHalloweenMode && specialStylesMode,
        'Amount__new-year': isNewYearMode && specialStylesMode,
      })}>
        <div className={cx('Amount__oldPrice')}>
          {formatNumber(oldPrice, 2)} {showCurrency && currency}
        </div>
        {Boolean(prefix) && <span className={cx('Amount__prefix')}>{prefix} </span>}
        <div className={cx('Amount__price')}>
          {formatNumber(currentPrice, 2)} {showCurrency && currency}
        </div>
      </div>
    );
  }

  return (
    <div className={cx('Amount', 'Amount__price', className, `Amount_size_${size}`,{
        'Amount__halloween': isHalloweenMode && specialStylesMode,
        'Amount__new-year': isNewYearMode && specialStylesMode,
      })} data-test-id='amount_price'>
      {Boolean(prefix) && <span className={cx('Amount__prefix')}>{prefix} </span>}
      {formatNumber(currentPrice, 2)} {showCurrency && currency}
    </div>
  );
}

Amount.defaultProps = {
  className: '',
  value: '-',
  size: 'l',
  showCurrency: true,
  prefix: '',
  bonus: false,
  newValue: undefined,
};

Amount.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  newValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, undefined]),
  className: PropTypes.string,
  showCurrency: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 's', 'm', 'l']),
  prefix: PropTypes.any,
  bonus: PropTypes.bool,
};
