import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

// Components
import Amount from '../../../Amount';
import Avatar from '../../Avatar';

// Icons
import ExitIcon from '../../../../icons/exit.svg';

// Router
import { paths, getRoute } from '../../../../entry/routes';

// Styles
import styles from './DesktopView.styl';

const cx = classNames.bind(styles);

export default function DesktopView(props) {
  const { onSignOut, bonuses, avatar, loyalty, onProfileClick } = props;

  const handleProfileClick = () => {
    onProfileClick?.();
  };

  return (
    <div className={cx('Profile')}>
      <div className={cx('Profile__body')}>
        <Link
          to={getRoute(paths.profile)}
          className={cx('Profile__main')}
          onClick={handleProfileClick}
        >
          <div className={cx('Profile__bonus')}>
            <Amount className={cx('Profile__bonus-count')} value={bonuses} showCurrency={false} />
            <div className={cx('Profile__bonus-label')}>
              <FormattedMessage id="profile.popup.bonuses" />
            </div>
          </div>
          <Avatar className={cx('Profile__avatar')} avatar={avatar} status={loyalty?.status} />
        </Link>
      </div>
      <div className={cx('Profile__logout')}>
        <div
          className={cx('Profile__logout-link')}
          onClick={onSignOut}
          onKeyDown={e => e.key === 'Enter' && onSignOut()}
          role="button"
          tabIndex={0}
        >
          <div className={cx('Profile__logout-icon')}>
            <ExitIcon width={24} height={24} />
          </div>
          <FormattedMessage id="link.signOut" />
        </div>
      </div>
    </div>
  );
}

DesktopView.defaultProps = {
  bonuses: 0,
  avatar: '',
  loyalty: {},
  onProfileClick: undefined,
};

DesktopView.propTypes = {
  onSignOut: PropTypes.func.isRequired,
  onProfileClick: PropTypes.func,
  bonuses: PropTypes.number,
  avatar: PropTypes.string,
  loyalty: PropTypes.object,
};
