import * as types from './actionTypes';

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: '',
  history: null,
  points: null,
  city_points: [],
};

export default function sailplayReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_SAILPLAY_BONUSES_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        error: '',
        history: null,
        points: null,
      };
    }

    case types.FETCH_SAILPLAY_BONUSES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        error: '',
        ...action.payload,
      };
    }

    case types.FETCH_SAILPLAY_BONUSES_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        error: action.payload,
        history: null,
        points: null,
      };
    }

    case types.RESET_SAILPLAY_STATE: {
      return { ...initialState };
    }

    default:
      return state;
  }
}
