import {themeNameSpaces} from '../city/themeNameSpaces';
/*
  position object example
  {
  id: artefactPosition,
  variants:[]artefactPositionVariants,
  isFalsyArtefact: boolean
  imageIndex?: number  - should be checked what happened if index not exist
  }
 */

export const artefactPositionVariants = {
coupon: 'coupon',
  filter: 'filter',
  footer: 'footer',
  body: 'body',

  sideLeft: 'sideLeft',
  side: 'side',
  button: 'button',
  bottom: 'bottom',
  top: 'top',
  top_outside: 'top_outside'
}
export const promotionConfigs = {
  [themeNameSpaces.FOOLS_DAY]: {
    artefactsCountToGenerate: 5,
    defaultTruePositions: ['coupon', 'filter', 'footer'],
    defaultPositionsFalsy: false,
    positionVarints: ['side', 'sideLeft', 'button'],
    picturesCount: null,
    countDefaultInTotal: false,
    // categoriesArtefacts добавляет артефакт для каждой категории. значения: false - не добавляет, falsy - не участвует в игре, true - играет
    categoriesArtefacts: false
  },
  [themeNameSpaces.HALLOWEEN_QUEST]: {
    artefactsCountToGenerate: 5,
    defaultFalsyPositions: ['coupon', 'filter', 'body'],
    defaultPositionsFalsy: true,

    positionVarints: ['sideLeft', 'side', 'button', 'bottom', 'top', 'top_outside'],
    picturesCount: null,
    countDefaultInTotal: false,
    categoriesArtefacts: 'falsy'
  },
  [themeNameSpaces.MASTER_MARGO]: {
    artefactsCountToGenerate: 0,
    defaultTruePositions: [
      {id:null, variants:[], imageIndex: 0}, // 0 - шкатулка
      {id:'body', variants:[], imageIndex: 6}, // 6 - большой кот
      {id:null, variants:['button'], imageIndex: 2}, // 2 - книга
      {id:null, variants:[], imageIndex: 3}, // 3 - трамвай
      {id:null, variants:[], imageIndex: 4}, // 4 - подсвечник
    ],
    defaultFalsyPositions: [
      {id:'basket',   variants:['basket'],  imageIndex: 5}  // - 5 голова
    ],
    defaultPositionsFalsy: true,
    positionVarints: ['sideLeft', 'side', 'button', 'bottom', 'top', 'top_outside'],
    picturesCount: null,
    countDefaultInTotal: false,
    categoriesArtefacts: false
  }
};
