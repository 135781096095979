import * as types from './actionTypes';
import giftsApi from './api';

export const fetchGifts = () => {
  return dispatch => {
    dispatch({ type: types.FETCH_GIFTS_REQUEST });

    return giftsApi
      .getSailplayGifts()
      .then(data => {
        return dispatch({ type: types.FETCH_GIFTS_SUCCESS, payload: data });
      })
      .catch(({ message }) => {
        return dispatch({ type: types.FETCH_GIFTS_FAILURE, payload: message });
      });
  };
};

export const fetchGiftsIsNeeded = () => {
  return (dispatch, getState) => {
    const { isLoading, isLoaded } = getState().gifts;

    if (!isLoading && !isLoaded) {
      return dispatch(fetchGifts());
    }

    return false;
  };
};
