import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import _isObject from 'lodash/isObject';
import _isString from 'lodash/isString';

// Styles
import styles from './Form.styl';

const cx = classNames.bind(styles);

export default function Error(props) {
  const { className, error, dirtySinceLastSubmit } = props;

  if (error && !dirtySinceLastSubmit) {
    return (
      <div className={cx('Form__errors', className)}>
        {_isObject(error) ? (
          Object.keys(error).map(key =>
            _isObject(error[key]) ? (
              error[key].map(err => (
                <div className={cx('Form__error')} key={err}>
                  {_isString(err) ? err : <FormattedMessage id="field.unknownError" />}
                </div>
              ))
            ) : (
              <div className={cx('Form__error')}>
                {_isString(error[key]) ? error[key] : <FormattedMessage id="field.unknownError" />}
              </div>
            ),
          )
        ) : (
          <div className={cx('Form__error')} dangerouslySetInnerHTML={{ __html: error }} />
        )}
      </div>
    );
  }

  return null;
}

Error.defaultProps = {
  className: '',
  dirtySinceLastSubmit: false,
};

Error.propTypes = {
  className: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]).isRequired,
  dirtySinceLastSubmit: PropTypes.bool,
};
