const prefix = '@restaurant';

export const FETCH_LIST_REQUEST = `${prefix}/FETCH_LIST_REQUEST`;
export const FETCH_LIST_SUCCESS = `${prefix}/FETCH_LIST_SUCCESS`;
export const FETCH_LIST_FAILURE = `${prefix}/FETCH_LIST_FAILURE`;

export const FETCH_RESTAURANT_STOPLIST_REQUEST = `${prefix}/FETCH_RESTAURANT_STOPLIST_REQUEST`;
export const FETCH_RESTAURANT_STOPLIST_SUCCESS = `${prefix}/FETCH_RESTAURANT_STOPLIST_SUCCESS`;
export const FETCH_RESTAURANT_STOPLIST_FAILURE = `${prefix}/FETCH_RESTAURANT_STOPLIST_FAILURE`;

export const CLEAR_RESTAURANT_STOPLIST = `${prefix}/CLEAR_RESTAURANT_STOPLIST`;
