import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import InputField from '../../InputField';
import PhoneField from '../../PhoneField';
import Heading from '../../Heading';
import Button from '../../Button';

// State
import { signInWithSms } from '../../../state/modules/user/actions';
import userApi from '../../../state/modules/user/api';

// Utils
import { required, requiredNumber, numberByCode } from '../../../utils/validators';

// Styles
import styles from './NewSignInForm.styl';

const cx = classNames.bind(styles);

export default function NewSignInForm(props) {
  const { className, successCallback, goToSignUp } = props;

  const intl = useIntl();
  const dispatch = useDispatch();
  const [isSigningIn, setIsSigninIn] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(true);
  const [timer, setTimer] = useState(0);
  const intervalRef = useRef(null);

  const { register, handleSubmit, errors, control, getValues } = useForm({
    mode: 'onSubmit',
    defaultValues: { phone: '', code: '' },
  });

  const sendConfirmCode = async () => {
    const { phone } = getValues(['phone']);

    await userApi
      .postUserConfirmCode({ phone, type: 'login' })
      .then(() => {
        setTimer(60);
        intervalRef.current = setInterval(() => {
          setTimer(prev => {
            if (prev - 1 === 0) {
              clearInterval(intervalRef.current);
            }

            return prev - 1;
          });
        }, 1000);

        setIsFirstStep(false);
      })
      .catch(() => null);
  };

  const onSubmit = handleSubmit(async formValues => {
    setIsSigninIn(true);

    if (isFirstStep) {
      await sendConfirmCode();
    } else {
      const { phone, code } = formValues;

      await dispatch(signInWithSms({ phone, code }, successCallback));
    }

    setIsSigninIn(false);
  });

  return (
    <form className={cx('NewSignInForm', className)} onSubmit={onSubmit}>
      <Heading className={cx('NewSignInForm__title')} level={3}>
        {intl.formatMessage({ id: 'page.signIn' })}
      </Heading>
      <div className={cx('NewSignInForm__phone')}>
        <Controller
          name="phone"
          control={control}
          rules={{ validate: { required, requiredNumber, numberByCode } }}
          render={({ onChange, value, name }) => (
            <PhoneField
              name={name}
              value={value}
              label={intl.formatMessage({ id: 'field.phone.label' })}
              autoFocus
              onChange={e => {
                // при любом изменении номера сбрасываем к первому шагу
                setIsFirstStep(true);
                return onChange(e);
              }}
            />
          )}
        />
        <span className={cx('NewSignInForm__error')}>{errors.phone?.message}</span>
      </div>
      {!isFirstStep && (
        <div className={cx('NewSignInForm__code')}>
          <InputField
            name="code"
            type="password"
            label={intl.formatMessage({ id: 'field.sms.label' })}
            register={register({
              validate: {
                length: value =>
                  value.length === 4
                    ? undefined
                    : intl.formatMessage({ id: 'validators.requiredCode' }),
              },
            })}
          />
          <span className={cx('NewSignInForm__error')}>{errors.code?.message}</span>
        </div>
      )}
      <div className={cx('NewSignInForm__actions')}>
        {!isFirstStep && (
          <button
            className={cx('NewSignInForm__action', {
              NewSignInForm__action_disabled: Boolean(timer),
            })}
            onClick={sendConfirmCode}
            type="button"
            disabled={Boolean(timer)}
          >
            {intl.formatMessage(
              { id: timer ? 'button.receiveCodeAfter' : 'button.receiveCode' },
              { second: timer },
            )}
          </button>
        )}
        {isFirstStep && goToSignUp && (
          <button className={cx('NewSignInForm__action')} onClick={goToSignUp} type="button">
            {intl.formatMessage({ id: 'link.signUp' })}
          </button>
        )}
        <Button className={cx('NewSignInForm__submit')} type="submit" isLoading={isSigningIn}>
          {intl.formatMessage({ id: isFirstStep ? 'button.continue' : 'button.signIn' })}
        </Button>
      </div>
    </form>
  );
}

NewSignInForm.defaultProps = {
  className: '',
  successCallback: undefined,
  goToSignUp: undefined,
};

NewSignInForm.propTypes = {
  className: PropTypes.string,
  successCallback: PropTypes.func,
  goToSignUp: PropTypes.func,
};
