import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import Heading from '../Heading';

// Utils
import { plainPhoneNumber } from '../../utils/telephone';

// Icons
import PhoneIcon from '../../icons/phone.svg';

// Styles
import styles from './MobilePhone.styl';

const cx = classNames.bind(styles);

export default function MobilePhone(props) {
  const { className, phone } = props;

  return (
    <a className={cx('MobilePhone', className)} href={`tel:${plainPhoneNumber(phone)}`}>
      <Heading className={cx('MobilePhone__phone')} level={3} tagName="span">
        {phone}
      </Heading>
      <span className={cx('MobilePhone__icon')}>
        <PhoneIcon width={24} height={24} />
      </span>
    </a>
  );
}

MobilePhone.defaultProps = {
  className: '',
};

MobilePhone.propTypes = {
  className: PropTypes.string,
  phone: PropTypes.string.isRequired,
};
