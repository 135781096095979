/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Styles
import styles from './Button.styl';

const cx = classNames.bind(styles);

export default function Button(props) {
  const {
    type,
    to,
    className,
    external,
    secondary,
    dark,
    disabled,
    isLoading,
    small,
    link,
    danger,
    children,
    buttonRef,
    ...otherProps
  } = props;

  const newChildren = <div className={cx('Button__text')}>{children}</div>;

  const buttonClassName = cx('Button', className, {
    Button_secondary: secondary,
    Button_dark: dark,
    Button_disabled: disabled,
    Button_isLoading: isLoading,
    Button_link: link,
    Button_small: small,
    Button_danger: danger,
  });

  if (external) {
    return (
      <a
        {...otherProps}
        className={buttonClassName}
        target="_blank"
        rel="noopener noreferrer"
        ref={buttonRef}
      >
        {newChildren}
      </a>
    );
  }

  if (to) {
    return (
      <Link {...otherProps} to={to} className={buttonClassName} ref={buttonRef}>
        {newChildren}
      </Link>
    );
  }

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      {...otherProps}
      type={type}
      className={buttonClassName}
      disabled={disabled || isLoading}
      ref={buttonRef}
    >
      {newChildren}
      {isLoading && <span className={cx('Button__spinner')} />}
    </button>
  );
}

Button.defaultProps = {
  className: '',
  type: 'button',
  buttonRef: undefined,
  external: false,
  to: '',
  small: false,
  disabled: false,
  isLoading: false,
  secondary: false,
  link: false,
  danger: false,
  dark: false,
};

Button.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
  external: PropTypes.bool,
  to: PropTypes.string,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  secondary: PropTypes.bool,
  link: PropTypes.bool,
  danger: PropTypes.bool,
  dark: PropTypes.bool,
  buttonRef: PropTypes.object,
};
