import * as types from './actionTypes';

export const showFreeSaucesPanel = (goodId, sauces = [], freeCount) => {
  return dispatch => {
    if (sauces.length > 0) {
      return dispatch({ type: types.SHOW_PANEL, payload: { sauces, goodId, freeCount } });
    }

    return false;
  };
};

export function hideFreeSaucesPanel() {
  return dispatch => {
    setTimeout(() => {
      dispatch({ type: types.CLEAN_FREE_SAUCES });
    }, 350);

    return dispatch({ type: types.HIDE_PANEL });
  };
}

export const addFreeSauce = () => {
  return { type: types.ADD_FREE_SAUCE };
};
