const prefix = '@order';

export const RESET_ORDER_STATE = `${prefix}/RESET_ORDER_STATE`;

export const FETCH_LIST_REQUEST = `${prefix}/FETCH_LIST_REQUEST`;
export const FETCH_LIST_SUCCESS = `${prefix}/FETCH_LIST_SUCCESS`;
export const FETCH_LIST_FAILURE = `${prefix}/FETCH_LIST_FAILURE`;

export const FETCH_ORDER_LIST_NEXT_PAGE_REQUEST = `${prefix}/FETCH_ORDER_LIST_NEXT_PAGE_REQUEST`;
export const FETCH_ORDER_LIST_NEXT_PAGE_SUCCESS = `${prefix}/FETCH_ORDER_LIST_NEXT_PAGE_SUCCESS`;
export const FETCH_ORDER_LIST_NEXT_PAGE_FAILURE = `${prefix}/FETCH_ORDER_LIST_NEXT_PAGE_FAILURE`;

export const SET_ORDER_RATING_REQUEST = `${prefix}/SET_ORDER_RATING_REQUEST`;
export const SET_ORDER_RATING_SUCCESS = `${prefix}/SET_ORDER_RATING_SUCCESS`;
export const SET_ORDER_RATING_FAILURE = `${prefix}/SET_ORDER_RATING_FAILURE`;

export const SET_CHECKOUT_USER_DATA = `${prefix}/SET_CHECKOUT_USER_DATA`;
export const SET_CHECKOUT_DELIVERY_TYPE = `${prefix}/SET_CHECKOUT_DELIVERY_TYPE`;
export const SEND_CHECKOUT_DELIVERY_TYPE_ANALYTIC = `${prefix}/SEND_CHECKOUT_DELIVERY_TYPE_ANALYTIC`;
export const SET_CHECKOUT_BONUSES_TYPE = `${prefix}/SET_CHECKOUT_BONUSES_TYPE`;
export const SET_CHECKOUT_DELIVERY_TIME_TYPE = `${prefix}/SET_CHECKOUT_DELIVERY_TIME_TYPE`;
export const SET_CHECKOUT_DELIVERY_TIME = `${prefix}/SET_CHECKOUT_DELIVERY_TIME`;
export const SET_CHECKOUT_PAY_TYPE = `${prefix}/SET_CHECKOUT_PAY_TYPE`;
export const SET_CHECKOUT_CHANGE = `${prefix}/SET_CHECKOUT_CHANGE`;
export const SET_CHECKOUT_IS_CARD_SAVED = `${prefix}/SET_CHECKOUT_IS_CARD_SAVED`;
export const SET_CHECKOUT_CONTACT_FREE_DELIVERY = `${prefix}/SET_CHECKOUT_CONTACT_FREE_DELIVERY`;
export const SET_CHECKOUT_DESCRIPTION = `${prefix}/SET_CHECKOUT_DESCRIPTION`;
export const SET_CHECKOUT_IS_PAYED = `${prefix}/SET_CHECKOUT_IS_PAYED`;
export const SET_CHECKOUT_HOURS_MINUTES = `${prefix}/SET_CHECKOUT_HOURS_MINUTES`;
export const SEND_CHECKOUT_CANCEL_ORDER_ANALYTIC = `${prefix}/SEND_CHECKOUT_CANCEL_ORDER_ANALYTIC`;

export const SET_PICKUP_RESTAURANT_DETAILS = `${prefix}/SET_PICKUP_RESTAURANT_DETAILS`;
export const SET_CHECKOUT_DELIVERY_ADDRESS = `${prefix}/SET_CHECKOUT_DELIVERY_ADDRESS`;
export const SET_RESTAURANT_DETAILS_WITHOUT_TIME = `${prefix}/SET_RESTAURANT_DETAILS_WITHOUT_TIME`;

export const SET_CHECKOUT_DELIVERY_ADDRESS_COORDINATES = `${prefix}/SET_CHECKOUT_DELIVERY_ADDRESS_COORDINATES`;

export const FETCH_DELIVERY_RESTAURANT_DETAILS_REQUEST = `${prefix}/FETCH_DELIVERY_RESTAURANT_DETAILS_REQUEST`;
export const FETCH_DELIVERY_RESTAURANT_DETAILS_SUCCESS = `${prefix}/FETCH_DELIVERY_RESTAURANT_DETAILS_SUCCESS`;
export const FETCH_DELIVERY_RESTAURANT_DETAILS_FAILURE = `${prefix}/FETCH_DELIVERY_RESTAURANT_DETAILS_FAILURE`;

export const FETCH_RESTAURANT_DETAILS_WITH_TIME_REQUEST = `${prefix}/FETCH_RESTAURANT_DETAILS_WITH_TIME_REQUEST`;
export const FETCH_RESTAURANT_DETAILS_WITH_TIME_SUCCESS = `${prefix}/FETCH_RESTAURANT_DETAILS_WITH_TIME_SUCCESS`;
export const FETCH_RESTAURANT_DETAILS_WITH_TIME_FAILURE = `${prefix}/FETCH_RESTAURANT_DETAILS_WITH_TIME_FAILURE`;

export const FETCH_RESTAURANT_INTERVALS_REQUEST = `${prefix}/FETCH_RESTAURANT_INTERVALS_REQUEST`;
export const FETCH_RESTAURANT_INTERVALS_SUCCESS = `${prefix}/FETCH_RESTAURANT_INTERVALS_SUCCESS`;
export const FETCH_RESTAURANT_INTERVALS_FAILURE = `${prefix}/FETCH_RESTAURANT_INTERVALS_FAILURE`;

export const FETCH_RESTAURANT_DELIVERY_TIME_REQUEST = `${prefix}/FETCH_RESTAURANT_DELIVERY_TIME_REQUEST`;
export const FETCH_RESTAURANT_DELIVERY_TIME_SUCCESS = `${prefix}/FETCH_RESTAURANT_DELIVERY_TIME_SUCCESS`;
export const FETCH_RESTAURANT_DELIVERY_TIME_FAILURE = `${prefix}/FETCH_RESTAURANT_DELIVERY_TIME_FAILURE`;

export const GET_LOCAL_STORAGE_CHECKOUT_FAILURE = `${prefix}/GET_LOCAL_STORAGE_CHECKOUT_FAILURE`;
export const GET_LOCAL_STORAGE_CHECKOUT_SUCCESS = `${prefix}/GET_LOCAL_STORAGE_CHECKOUT_SUCCESS`;
export const RESET_CHECKOUT = `${prefix}/RESET_CHECKOUT`;
export const RESET_CHECKOUT_FIELD = `${prefix}/RESET_CHECKOUT_FIELD`;
export const RESET_CHECKOUT_PAYMENT = `${prefix}/RESET_CHECKOUT_PAYMENT`;
export const SEND_ANALYTIC_ORDER_DATA = `${prefix}/SEND_ANALYTIC_ORDER_DATA`;

export const PAY_REQUEST = `${prefix}/PAY_REQUEST`;
export const PAY_SUCCESS = `${prefix}/PAY_SUCCESS`;
export const PAY_FAILURE = `${prefix}/PAY_FAILURE`;

export const RESET_MAX_SUM = `${prefix}/RESET_MAX_SUM`;

export const SAVE_CARD_FOR_MIR = `${prefix}/SAVE_CARD_FOR_MIR`;

export const TRACKING_ORDER_DATA = `${prefix}/TRACKING_ORDER_DATA`;
