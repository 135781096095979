import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import classNames from 'classnames/bind';
import config from '../../../../config';
import { mapLocaleSelector } from '../../../../state/modules/intl/selectors';

//components
import AddressesField from '../../../AddressFieldGroup/partials/AddressesField';
import InputField from '../../../InputField';
import Button from '../../../Button';
import Map from '../../../AddressFieldGroup/partials/Map/Map';

//State
import { selectUserCity } from '../../../../state/modules/city/actions';

//icons
import ToggleGreen from '../../../../icons/toggle-green.svg';

//styles
import styles from './ModalMapView.styl';

const cx = classNames.bind(styles);

const ModalMapView = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const userCity = useSelector(state => state.city.userCity);
  const mapLocale = useSelector(mapLocaleSelector);

  const [openBuldingBlock, setOpenBuldingBlock] = useState(false);

  const {
    openCityList,
    address,
    addressInLineValue,
    setAddressInLineValue,
    handleAddressOnChange,
    handleOnAddressInLineSelect,
    handleOnAccept,
    isError
  } = props;

  /** Ищем координаты центра */

  const cityCenter =
    config.legal === 'pl'
      ? [...userCity.coordinates]
      : [userCity.coordinates[1], userCity.coordinates[0]];

  /** Установливаем центр карты */

  const onCitySelect = () => {
    handleOnAccept()
      .then(() => {
        dispatch(selectUserCity(userCity));
      });
  };

  return (
    <div className={cx('ModalMapView')}>
      <Map
        className={cx('ModalMapView__map')}
        mapAddressOnChange={handleOnAddressInLineSelect}
        selectedAddress={address.address_in_line}
        center={cityCenter}
        mapLocale={mapLocale}
      />
      <AddressesField
        className={cx({ 'ModalMapView__text-address_not-margin': openCityList }, 'ModalMapView__padding')}
        label={intl.formatMessage({ id: isError ? 'field.address.error' : 'field.address.label' })}
        onAddressSelect={handleOnAddressInLineSelect}
        newest
        textBlockInput={true}
        input={{
          name: 'address_in_line',
          value: addressInLineValue,
          inputClassname: cx('ModalMapView__text-address'),
          onChange: value => setAddressInLineValue(value),
          required: true,
          placeholder: intl.formatMessage({ id: 'modal.address.placeholderStreet' }),
          isError,
        }}
      />

      <div className={cx('ModalMapView__address-mini-block', 'ModalMapView__padding')}>
        <InputField
          inputClassname={cx('ModalMapView__text-mini_padding-left')}
          marginClassName={cx('ModalMapView__text-mini_padding-flat')}
          className={cx({ 'ModalMapView__text-mini': openCityList })}
          name="flat"
          value={address.flat}
          onChange={handleAddressOnChange}
          mask='кв.'
          placeholder={intl.formatMessage({ id: 'modal.address.placeholderBuilding' })}
        />

        <InputField
          name="floor"
          inputClassname={cx('ModalMapView__text-mini_padding-left', 'ModalMapView__text-mini_padding-floor')}
          marginClassName={cx('ModalMapView__text-mini_padding-floor')}
          className={cx({ 'ModalMapView__text-mini': openCityList })}
          value={address.floor}
          onChange={handleAddressOnChange}
          mask='э.'
          placeholder={intl.formatMessage({ id: 'modal.address.placeholderFloor' })}
        />

      </div>

      <button
        className={cx('ModalMapView__address-button', 'ModalMapView__padding', 'ModalMapView__address-button_bottom')}
        onClick={() => setOpenBuldingBlock(!openBuldingBlock)}>
        Указать домофон и подъезд
        <div
          className={cx('ModalMapView__address-button-icon', { 'ModalMapView__address-button-icon_open': openBuldingBlock })}>
          <ToggleGreen />
        </div>
      </button>

      {openBuldingBlock && (
        <div className={cx('ModalMapView__address-mini-block', 'ModalMapView__padding')}>
          <InputField
            name="intercom"
            inputClassname={cx('ModalMapView__text-mini_padding-left')}
            className={cx({ 'ModalMapView__text-mini': openCityList })}
            marginClassName={cx('ModalMapView__text-mini_padding-intercom')}
            value={address.intercom}
            onChange={handleAddressOnChange}
            mask='дмфн.'
            placeholder={intl.formatMessage({ id: 'modal.address.placeholderIntercom' })}
          />

          <InputField
            inputClassname={cx('ModalMapView__text-mini_padding-left')}
            className={cx({ 'ModalMapView__text-mini': openCityList })}
            marginClassName={cx('ModalMapView__text-mini_padding-porch')}
            name="porch"
            value={address.porch}
            onChange={handleAddressOnChange}
            mask='под.'
            placeholder={intl.formatMessage({ id: 'modal.address.placeholderEntrance' })}
          />
        </div>
      )}

      <div className={cx('ModalMapView__padding', 'ModalMapView__button-wrapper')}>
        <Button className={cx('ModalMapView__button', { 'ModalMapView__button_open': address })}
                disabled={(addressInLineValue?.length < 4)}
                onClick={onCitySelect}>
          {intl.formatMessage({ id: 'modal.address.buttonConfirm' })}
        </Button>
      </div>
    </div>
  );
};

export default ModalMapView;
