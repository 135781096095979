import React, {useMemo, useRef} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Assets
import logoRu from './assets/logo_ru_new.svg';
import logoEn from './assets/logo_en_new.svg';
import ny_logoRu from '../../assets/NY_images/NY_logo.svg';
import ny_logoEn from '../../assets/NY_images/NY_logo_en.svg';
import fool_day_logo from '../../assets/FoolDay_images/Fool_day_logo.png';
import fool_day_logoEn from '../../assets/FoolDay_images/Fool_day_logo_en.png';
import cosmos_logo from '../../assets/Astronaut_images/logo.png';

// Config
import config from '../../config';

// Selectors
import {isAstronautTheme, isThemeNewYear} from "../../state/modules/city/selectors";

// Styles
import styles from './Logo.styl';
import {themeNameSpaces} from "../../state/modules/city/themeNameSpaces";

const cx = classNames.bind(styles);

const logos = {
  ru: logoRu,
  en: logoEn,
  pl: logoEn,
};

const nyLogos = {
  ru: ny_logoRu,
  en: ny_logoEn,
};

const foolDayLogos = {
  ru: fool_day_logo,
  en: fool_day_logoEn,
};

const cosmosLogos = {
  ru: cosmos_logo,
  en: cosmos_logo,
};


export default function Logo(props) {
  const {className} = props;

  const locale = useSelector(state => state.intl.locale);
  const lang = config.legal === 'kz' || config.legal === 'kg' ? 'en' : locale;
  const isNewYearMode = useSelector(isThemeNewYear);
  const isAstronaut = useSelector(isAstronautTheme);

  const {isPromotionGameActive, gameName} = useSelector(state => state.promotionGame)

  const isFoolDayGame = useRef(gameName === themeNameSpaces.FOOLS_DAY).current
  const isHalloweenGame = useRef(gameName === themeNameSpaces.HALLOWEEN_QUEST).current

  const logoSrc = useMemo(() => {
    if (isNewYearMode){
      return nyLogos[lang]
    }
    if (isFoolDayGame){
      return foolDayLogos[lang]
    }
    if (isAstronaut){
      return cosmosLogos[lang]
    }
    return logos[lang]
  },[lang, isNewYearMode, isAstronaut, isPromotionGameActive, isFoolDayGame])

  return <div className={cx('Logo',className, {'Logo__hw': isHalloweenGame})}>
    <img className={cx( {
      'Logo__new_year': isNewYearMode,
      'Logo__cosmos': isAstronaut,
      Logo__FoolDay: isPromotionGameActive && isFoolDayGame,
    })} src={logoSrc} alt="Papa John's"/>
    {isHalloweenGame && (<div className={cx({'Logo__hw-decor': isHalloweenGame})}></div>)}
  </div>;
}

Logo.defaultProps = {
  className: '',
};

Logo.propTypes = {
  className: PropTypes.string,
};
