import * as types from './actionTypes';

const initialState = {
  panelOpened: false,
  list: [],
  goodId: null,
  freeCount: 0,
  freeAddedCount: 0,
};

export default function freeSaucesReducer(state = initialState, action) {
  switch (action.type) {
    case types.SHOW_PANEL:
      return {
        ...state,
        panelOpened: true,
        list: action.payload.sauces,
        goodId: action.payload.goodId,
        freeCount: action.payload.freeCount,
      };

    case types.HIDE_PANEL:
      return {
        ...state,
        panelOpened: false,
      };

    case types.ADD_FREE_SAUCE: {
      return {
        ...state,
        freeAddedCount: state.freeAddedCount + 1,
      };
    }

    case types.CLEAN_FREE_SAUCES:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}
