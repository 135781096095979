const prefix = '@catalog';

export const REMOVE_INGREDIENT = `${prefix}/REMOVE_INGREDIENT`;
export const CANCEL_REMOVED_INGREDIENTS = `${prefix}/CANCEL_REMOVED_INGREDIENTS`;

export const FETCH_CATALOG_GOODS_REQUEST = `${prefix}/FETCH_CATALOG_GOODS_REQUEST`;
export const FETCH_CATALOG_GOODS_SUCCESS = `${prefix}/FETCH_CATALOG_GOODS_SUCCESS`;
export const FETCH_CATALOG_GOODS_FAILURE = `${prefix}/FETCH_CATALOG_GOODS_FAILURE`;

export const FETCH_CATALOG_ACTIVE_INGREDIENTS_REQUEST = `${prefix}/FETCH_CATALOG_ACTIVE_INGREDIENTS_REQUEST`;
export const FETCH_CATALOG_ACTIVE_INGREDIENTS_SUCCESS = `${prefix}/FETCH_CATALOG_ACTIVE_INGREDIENTS_SUCCESS`;
export const FETCH_CATALOG_ACTIVE_INGREDIENTS_FAILURE = `${prefix}/FETCH_CATALOG_ACTIVE_INGREDIENTS_FAILURE`;

export const FETCH_CATALOG_UPSALE_REQUEST = `${prefix}/FETCH_CATALOG_UPSALE_REQUEST`;
export const FETCH_CATALOG_UPSALE_SUCCESS = `${prefix}/FETCH_CATALOG_UPSALE_SUCCESS`;
export const FETCH_CATALOG_UPSALE_FAILURE = `${prefix}/FETCH_CATALOG_UPSALE_FAILURE`;

export const FETCH_CATALOG_NOT_FOUND_SLIDER_REQUEST = `${prefix}/FETCH_CATALOG_NOT_FOUND_SLIDER_REQUEST`;
export const FETCH_CATALOG_NOT_FOUND_SLIDER_SUCCESS = `${prefix}/FETCH_CATALOG_NOT_FOUND_SLIDER_SUCCESS`;
export const FETCH_CATALOG_NOT_FOUND_SLIDER_FAILURE = `${prefix}/FETCH_CATALOG_NOT_FOUND_SLIDER_FAILURE`;
export const SET_FOOTBALL_GOODS = `${prefix}/SET_FOOTBALL_GOODS`;
