import * as types from './actionTypes';

const initialState = {
  list: [],
  isLoading: false,
  isLoaded: false,
};

export default function districtsReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_CITY_DISTRICTS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        list: action.payload,
      };
    }

    default:
      return state;
  }
}
