/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames/bind';

// Styles
import styles from './ChatPreloader.styl';
import { useIntl } from 'react-intl';

const cx = classNames.bind(styles);

function ChatPreloader(props) {
  const { className } = props;
  const intl = useIntl();

  return (
    <div className={cx('Preloader', className)}>
      <p className={cx('Preloader__text')}>{intl.formatMessage({ id: 'chat.preloader' })}</p>
      <div className={cx('Preloader__animation')}>
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}

export default ChatPreloader;
