import * as immutable from 'object-path-immutable';
import * as types from './actionTypes';

const initialState = {
  list: [],
  isLoaded: false,
  isLoading: false,
  error: '',
};

export default function addressReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_ADDRESSES_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        error: '',
      };

    case types.FETCH_ADDRESSES_SUCCESS:
      return {
        ...state,
        list: action.payload,
        isLoading: false,
        isLoaded: true,
        error: '',
      };

    case types.FETCH_ADDRESSES_FAILURE:
      return {
        ...state,
        list: [],
        isLoaded: false,
        isLoading: false,
        error: '',
      };

    case types.SAVE_ADDRESS_SUCCESS:
      return action.payload.isUpdate
        ? {
            ...state,
            error: '',
            list: state.list.map(address =>
              address.id === action.payload.data.id ? action.payload.data : address,
            ),
          }
        : {
            ...state,
            list: [...state.list, action.payload.data],
            error: '',
          };

    case types.SAVE_ADDRESS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case types.DELETE_ADDRESS_REQUEST:
      return {
        ...state,
        error: '',
      };

    case types.DELETE_ADDRESS_SUCCESS: {
      const indexRemovedAddress = state.list.findIndex(address => address.id === action.payload);

      return {
        ...immutable.del(state, `list.${indexRemovedAddress}`),
        error: '',
      };
    }

    case types.DELETE_ADDRESS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case types.RESET_ADDRESS_STATE: {
      if (action.payload) {
        return { ...state, [action.payload]: initialState[action.payload] };
      }

      return { ...initialState };
    }

    default:
      return state;
  }
}
