import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './RadioField.styl';

const cx = classNames.bind(styles);

export default function RadioField(props) {

  const { className, label, description, inputProps } = props;

  const classes = cx('RadioField', className, {
    RadioField_checked: inputProps.checked,
    RadioField_disabled: inputProps.disabled
  });

  return (
    <div className={classes}>
      <label className={cx('RadioField__field')}>
        <input {...inputProps} className={cx('RadioField__input')} />
        {label}
      </label>
      {description && <div className={cx('RadioField__description')}>{description}</div>}
    </div>
  );
}

RadioField.defaultProps = {
  className: '',
  checked: false,
  disabled: false,
  description: ''
};

RadioField.propTypes = {
  className: PropTypes.string,
  inputRef: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  description: PropTypes.string,
  inputProps: PropTypes.object
};
