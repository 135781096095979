import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Heading from '../Heading';

import styles from './Form.styl';

const cx = classNames.bind(styles);

const Group = ({ className, children, title }) => (
  <fieldset className={cx('Form__group', className)}>
    {title && (
      <Heading className={cx('Form__group-title')} level={3} tagName="legend">
        {title}
      </Heading>
    )}
    {children}
  </fieldset>
);

Group.defaultProps = {
  className: '',
  title: '',
};

Group.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.any.isRequired,
};

export default Group;
