import React from 'react';
import classNames from 'classnames/bind';
import { ToastContainer, Slide } from 'react-toastify';

// Icons
import CloseIcon from '../../icons/close.svg';

// Styles
import styles from './Alert.styl';

const cx = classNames.bind(styles);

export default function Alert() {
  // const location = useLocation();

  // useEffect(() => {
  //   toast.dismiss();
  // }, [location.pathname]);

  return (
    <ToastContainer
      className={cx('Alert')}
      toastClassName={cx('Alert__toast')}
      bodyClassName={cx('Alert__body')}
      position="bottom-right"
      autoClose={4000}
      limit={3}
      transition={Slide}
      hideProgressBar
      pauseOnFocusLoss={false}
      draggable={false}
      closeButton={<CloseIcon style={{ width: '12px', height: '12px', flexShrink: '0' }} />}
    />
  );
}
