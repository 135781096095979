import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import InputFieldMobile from './MobileView';
import InputFieldDesktop from './DesktopView';

// Styles
import styles from './InputField.styl';

const cx = classNames.bind(styles);

export default function InputField(props) {
  const {
    className,
    inputClassname,
    marginClassName,
    children,
    label,
    isNumberInput,
    newest,
    //
    name,
    required,
    disabled,
    _ref,
    register,
    isError,
    autoFocus,
    mask,
    ...otherProps
  } = props;

  const [maskStatus, setMaskStatus] = useState(false);

  const isRealPhone = useSelector(state => state.responsive.isRealPhone);

  // используется только в AddressesField. Удалить флаг newest, когда там будет разделение на десктопную и мобильную версии
  if (newest) {
    const NewInputField = isRealPhone ? InputFieldMobile : InputFieldDesktop;

    return <NewInputField {...props} />;
  }

  const inputProps = {
    ...otherProps,
    className: cx('InputField__field', inputClassname),
    name,
    disabled,
    required,
    ref: _ref || register,
    id: name,
  };

  const onNumberChange = e => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      inputProps.onChange(e);
    }
  };

  const showMask = () => {
    if (props.value) {
      setMaskStatus(true);
    }
  };

  return (
    <div
      className={cx('InputField', className, {
        InputField_disabled: disabled,
        InputField_error: isError,
      })}
    >
      {Boolean(label) && (
        <label className={cx('InputField__label')} htmlFor={name}>
          {label} {required && '*'}
        </label>
      )}
      <div className={cx('InputField__wrapper')}>
        <input {...inputProps}
               onBlur={showMask}
          // onFocus={() => setMaskStatus(false)}

               onChange={isNumberInput ? onNumberChange : inputProps.onChange} autoFocus={autoFocus}
               className={cx(inputProps.className, inputProps.inputClassname, mask && maskStatus && marginClassName)} />
        {mask && maskStatus && <p className={cx('InputField__mask')}>{mask}</p>}
        {children}
      </div>
    </div>
  );
}

InputField.defaultProps = {
  className: '',
  inputClassname: '',
  marginClassName: '',
  type: 'text',
  required: false,
  label: '',
  name: undefined, // НИ В КОЕМ СЛУЧАЕ НЕ ПРОКИДЫВАТЬ, ИНАЧЕ ОТВАЛИТСЯ CLOUDPAYMENTS
  disabled: false,
  _ref: undefined,
  register: undefined, // для react-hook-form
  isNumberInput: false,
  children: null,
  newest: false,
};

InputField.propTypes = {
  className: PropTypes.string,
  inputClassname: PropTypes.string,
  marginClassName: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  _ref: PropTypes.object,
  disabled: PropTypes.bool,
  register: PropTypes.func,
  isNumberInput: PropTypes.bool,
  children: PropTypes.any,
  newest: PropTypes.bool,
};
