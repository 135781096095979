const prefix = '@constructor';

export const FETCH_GOOD_REQUEST = `${prefix}/FETCH_GOOD_REQUEST`;
export const FETCH_GOOD_SUCCESS = `${prefix}/FETCH_GOOD_SUCCESS`;
export const FETCH_GOOD_FAILURE = `${prefix}/FETCH_GOOD_FAILURE`;

export const SET_IS_ADDED = `${prefix}/SET_IS_ADDED`;
export const SET_CUSTOM_NAME = `${prefix}/SET_CUSTOM_NAME`;
export const CHANGE_DOUGH = `${prefix}/CHANGE_DOUGH`;
export const CHANGE_SIZE = `${prefix}/CHANGE_SIZE`;
export const TOGGLE_STUFFED_CRUST = `${prefix}/TOGGLE_STUFFED_CRUST`;
export const SELECT_STUFFED_CRUST = `${prefix}/SELECT_STUFFED_CRUST`;
export const CHANGE_AVAILABLE_VARIATIONS = `${prefix}/CHANGE_AVAILABLE_VARIATIONS`;

export const ADD_PORTION = `${prefix}/ADD_PORTION`;
export const REMOVE_PORTION = `${prefix}/REMOVE_PORTION`;
export const REMOVE_INGREDIENT = `${prefix}/REMOVE_INGREDIENT`;

export const RESET = `${prefix}/RESET`;
