import Cookies from 'js-cookie';
import analyticalCookiesKeysList from './analyticalCookiesKeysList';
import strictlyCookiesKeysList from './strictlyCookiesKeysList';

class IsomorphicCookies {
  constructor(req, res) {
    this.req = req;
    this.res = res;
  }

  static strictlyCookies = true;

  static analyticalCookies = true;

  get(key) {
    if (this.req) {
      return this.req.cookies[key];
    }

    return Cookies.get(key);
  }

  set(key, val, params) {
    let isStrictlyCookie = false;
    let isAnalyticalCookie = false;

    strictlyCookiesKeysList.forEach(item => {
      if (key.includes(item)) {
        isStrictlyCookie = true;
      }
    });
    analyticalCookiesKeysList.forEach(item => {
      if (key.includes(item)) {
        isAnalyticalCookie = true;
      }
    });

    if (
      (IsomorphicCookies.analyticalCookies && isAnalyticalCookie) ||
      (IsomorphicCookies.strictlyCookies && isStrictlyCookie) ||
      (!isStrictlyCookie && !isAnalyticalCookie)
    ) {
      return this.setCookie(key, val, params);
    }
  }

  setCookie(key, val, params) {
    if (this.res) {
      if (params?.expires) {
        const newExpires = new Date();
        newExpires.setDate(newExpires.getDate() + params.expires);
        params.expires = newExpires;
      }
      return this.res.cookie(key, val, params);
    }

    return Cookies.set(key, val, params);
  }

  remove(key, params) {
    if (this.res) {
      return this.res.clearCookie(key, params);
    }

    return Cookies.remove(key, params);
  }
}

export { IsomorphicCookies };

export default (req = null, res = null) => {
  return new IsomorphicCookies(req, res);
};
