import React, {useEffect, useMemo, useRef, useState} from 'react';
import classNames from 'classnames/bind';

// Icons
import {useDispatch, useSelector} from 'react-redux';

// Assets
import PropTypes from 'prop-types';
import pepper2 from '../../../../assets/FoolDay_images/pepper2.png';
import pepper3 from '../../../../assets/FoolDay_images/pepper3.png';
import pepper4 from '../../../../assets/FoolDay_images/pepper4.png';
import pepper5 from '../../../../assets/FoolDay_images/pepper5.png';
import hw_art_1 from '../../../../assets/Halloween_quest/icons/artefact_1.png';
import hw_art_2 from '../../../../assets/Halloween_quest/icons/artefact_2.png';
import hw_art_3 from '../../../../assets/Halloween_quest/icons/artefact_3.png';
import hw_art_4 from '../../../../assets/Halloween_quest/icons/artefact_4.png';
import hw_1 from '../../../../assets/Halloween_quest/icons/hw_1.png';
import hw_2 from '../../../../assets/Halloween_quest/icons/hw_2.png';
import hw_3 from '../../../../assets/Halloween_quest/icons/hw_3.png';
import hw_4 from '../../../../assets/Halloween_quest/icons/hw_4.png';
import hw_6 from '../../../../assets/Halloween_quest/icons/hw_6.png';
import hw_7 from '../../../../assets/Halloween_quest/icons/hw_7.png';
import hw_8 from '../../../../assets/Halloween_quest/icons/hw_8.png';
import hw_9 from '../../../../assets/Halloween_quest/icons/hw_9.png';
import hw_10 from '../../../../assets/Halloween_quest/icons/hw_10.png';
import mm0 from '../assets/mm_game/artefact_4.png';
import mm1 from '../assets/mm_game/artefact_2.png';
import mm2 from '../assets/mm_game/artefact_1.png';
import mm3 from '../assets/mm_game/artefact_3.png';
import mm4 from '../assets/mm_game/popup_repeat.png';
import mm5 from '../assets/mm_game/popup_err.png';
import mm6 from '../assets/mm_game/artefact_6.png';

// Styles
import styles from './PromoArtefact.styl';
import {gameCheckpoint, gameFalsyCheckpoint} from '../../../../state/modules/promotionGame/actions';
import {usePromoArtefact} from "../usePromoArtefact";
import {themeNameSpaces} from "../../../../state/modules/city/themeNameSpaces";
import {artefactPositionVariants} from "../../../../state/modules/promotionGame/promotionConfigs";

const cx = classNames.bind(styles);

const images = {
  [themeNameSpaces.FOOLS_DAY]: [pepper2, pepper3, pepper4],
  [themeNameSpaces.HALLOWEEN_QUEST]: [pepper2, pepper3, pepper4],
  [themeNameSpaces.MASTER_MARGO]: [mm0, mm1, mm2, mm3, mm4, mm5],
};
const specificImages = {
  [themeNameSpaces.FOOLS_DAY]: [pepper5],
  [themeNameSpaces.HALLOWEEN_QUEST]: [],
  [themeNameSpaces.MASTER_MARGO]: [mm0, mm1, mm2, mm3, mm4, mm5, mm6],
};

const HW_Image_Variants = {
  [artefactPositionVariants.button]: hw_art_3,
  [artefactPositionVariants.side]: hw_art_3,
  [artefactPositionVariants.sideLeft]: hw_art_4,
  [artefactPositionVariants.bottom]: hw_art_2,
  [artefactPositionVariants.top]: hw_art_1,
  [artefactPositionVariants.top_outside]: hw_art_1,

  [artefactPositionVariants.coupon]: hw_3,
  [artefactPositionVariants.filter]: hw_7,
  [artefactPositionVariants.body]: hw_1,

  pizza: hw_4,
  zakuski: hw_9,
  napitki: hw_10,
  deserty: hw_6,
  hot: hw_1,
  sauce: hw_2,
  combo: hw_8,
  salaty: hw_7,
}

const effectVariant = {
  [artefactPositionVariants.button]: 'toTop',
  [artefactPositionVariants.side]: 'toLeft',
  [artefactPositionVariants.sideLeft]: 'toRight',
  [artefactPositionVariants.bottom]: 'toTop',
  [artefactPositionVariants.top]: 'toBottom',
  [artefactPositionVariants.top_outside]: 'toTop',

  [artefactPositionVariants.coupon]: 'opacity',
  [artefactPositionVariants.filter]: 'toTop',
}

const PromoArtefact = ({id, activeEffect = 'toLeft', imageIndex, className, ...restProps}) => {
  const dispatch = useDispatch();
  const timerRef = useRef(null);

  const {imageProps, isFalsyArtefact} = usePromoArtefact(id)
  const {gameName} = useSelector(state => state.promotionGame)

  const [isActive, setActive] = useState(false);

  const image = useMemo(() => {

    if (gameName === themeNameSpaces.HALLOWEEN_QUEST){
      return HW_Image_Variants[imageProps?.positionVariant] ?? hw_8
    }
    if (gameName === themeNameSpaces.MASTER_MARGO && imageProps && imageProps?.imageIndex){
      return specificImages[gameName][imageProps.imageIndex] || specificImages[gameName][[Math.floor(Math.random() * specificImages[gameName].length)]]
    }
    const isSpecific = typeof (imageIndex) === "number" && specificImages[gameName][imageIndex]

    return !imageProps ? null : isSpecific
      ? specificImages[gameName][imageIndex]
      : images[gameName][imageProps.imageIndex]
        ? images[gameName][imageProps.imageIndex]
        : images[gameName][Math.floor(Math.random() * images[gameName].length)]

  }, [gameName, imageProps])

  const disableActiveClass = () => {
    timerRef.current = setTimeout(() => setActive(false), 5000);
  };

  const clickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setActive(true);
    if (isFalsyArtefact) {
      dispatch(gameFalsyCheckpoint());
    } else {
      dispatch(gameCheckpoint(id));
    }
    disableActiveClass()
  };

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);


  const effect = useMemo(() => {
    if (gameName === themeNameSpaces.MASTER_MARGO && imageProps?.positionVariant === artefactPositionVariants.button){
      // в версии мастер и маргарита переопределяем эффект на кнопке
      return activeEffect ?? effectVariant[artefactPositionVariants.coupon]
    }
    return effectVariant[imageProps?.positionVariant]  ?? activeEffect
  },[activeEffect,imageProps, gameName])
  if (!imageProps) {
    return <div className={'WTF'}/>
  }

  return (
    <div
      className={cx(
        'PromoArtefact',
        `PromoArtefact__${effect}`,
        isActive && `PromoArtefact__${effect}_active`,
        className,
      )}
      onClick={clickHandler}
      {...restProps}
    >
      <img src={image} className={cx('PromoArtefact__img')} alt="flags"/>
    </div>
  );
};

export default PromoArtefact;

PromoArtefact.defaultProps = {
  className: '',
};

PromoArtefact.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};
