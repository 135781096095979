import _findLastIndex from 'lodash/findLastIndex';

import { paySuccess } from '../order';
import { getProductsFromCart } from '../../helpers';

// State
import * as orderTypes from '../../../modules/order/actionTypes';

function sendDelivery(action, prevState, nextState) {
  const { cart, order } = nextState;
  const { deliveryType } = order.checkout;

  const actionField = {
    step: 2,
    option: deliveryType === 'delivery' ? 'Доставка' : 'Самовывоз',
  };

  let products = [];

  if (cart) {
    products = getProductsFromCart(cart);
  }

  const outData = {
    event: 'checkout',
    eventCategory: 'Ecommerce',
    eventAction: 'checkout',
    eventLabel: 'Step 2',

    ecommerce: {
      checkout: {
        actionField,
        products,
      },
    },
  };

  return outData;
}

function sendPayment(action, prevState, nextState) {
  const { cart } = nextState;

  const actionField = {
    step: 3,
    option: 'Наличными курьеру',
  };

  let products = [];

  if (cart) {
    products = getProductsFromCart(cart);
  }

  const outData = {
    event: 'checkout',
    eventCategory: 'Ecommerce',
    eventAction: 'checkout',
    eventLabel: 'Step 3',

    ecommerce: {
      checkout: {
        actionField,
        products,
      },
    },
  };

  return outData;
}

function changeDeliveryType(action, prevState, nextState) {
  const outItem = {
    event: 'checkoutOption',
    ecommerce: {
      checkout_option: {
        actionField: {
          step: 2,
          option: action.payload === 'delivery' ? 'Доставка' : 'Самовывоз',
        },
      },
    },
  };

  return outItem;
}

function changePaymentType(action, prevState, nextState) {
  const getPayType = () => {
    switch (action.payload) {
      case 'cash': {
        return 'Наличными курьеру';
      }
      case 'card_to_courier': {
        return 'Картой курьеру';
      }
      case 'online': {
        return 'Банковской картой сейчас';
      }
      default: {
        return action.payload;
      }
    }
  };

  const outItem = {
    event: 'checkoutOption',
    ecommerce: {
      checkout_option: {
        actionField: {
          step: 3,
          option: getPayType(),
        },
      },
    },
  };

  return outItem;
}

export const getCheckoutEvents = (actionType, tryFunc) => {
  try {
    let dataLayer = [];

    if (window.dataLayer) {
      dataLayer = [...window.dataLayer];
    }

    // Находим индекс последнего захода в корзину
    const lastStepOneEventIndex = _findLastIndex(
      dataLayer,
      item => item.event === 'checkout' && item.ecommerce?.checkout?.actionField?.step === 1,
    );

    // Находим индекс последнего действия в корзине
    const lastEventIndex = _findLastIndex(dataLayer, item => item.event === 'checkout');

    if (dataLayer[lastEventIndex]?.ecommerce?.checkout?.actionField?.step === 4) {
      // Если последним ивентом был PAY_SUCCESS и не было нового захода в корзину, то в GTM ничего не отправляем
      return null;
    }

    if (lastStepOneEventIndex <= lastEventIndex) {
      const outActions = [];

      // Get last Checkout GTM events Step 1..Step 4
      const subArr = dataLayer
        .slice(lastStepOneEventIndex, lastEventIndex + 1)
        .filter(item => item.event === 'checkout');

      // Check if STEP 2 was already submitted
      const hasSecond =
        subArr.findIndex(item => item.ecommerce?.checkout?.actionField?.step === 2) > -1;

      // Check if STEP 3 was already submitted
      const hasThird =
        subArr.findIndex(item => item.ecommerce?.checkout?.actionField?.step === 3) > -1;

      // Build Array of events depending on ActionType, hasSecond, hasThird
      switch (actionType) {
        case orderTypes.SEND_CHECKOUT_DELIVERY_TYPE_ANALYTIC:
          // Если второй шаг не был отправлен, отправляем его
          if (!hasSecond) {
            outActions.push(tryFunc(sendDelivery));
          }

          // Push DELIVERY_TYPE change event
          outActions.push(tryFunc(changeDeliveryType));
          break;

        case orderTypes.SET_CHECKOUT_PAY_TYPE:
          // Если второй шаг не был отправлен, отправляем его
          if (!hasSecond) {
            outActions.push(tryFunc(sendDelivery));
          }

          // Если третий шаг не был отправлен, отправляем его
          if (!hasThird) {
            outActions.push(tryFunc(sendPayment));
          }

          // Push PAY_TYPE change event
          outActions.push(tryFunc(changePaymentType));
          break;

        case orderTypes.SEND_ANALYTIC_ORDER_DATA:
          // Если второй шаг не был отправлен, отправляем его
          if (!hasSecond) {
            outActions.push(tryFunc(sendDelivery));
          }

          // Если третий шаг не был отправлен, отправляем его
          if (!hasThird) {
            outActions.push(tryFunc(sendPayment));
          }

          // Push PAY_SUCCESS event
          outActions.push(tryFunc(paySuccess));
          break;

        default:
          break;
      }

      return outActions;
    }

    return null;
  } catch ({ message }) {
    console.warn(`[checkout analyt] Error when getting checkout events: ${message}`);
    return null;
  }
};
