export const INIT_APPLE_PAY = '@payTypes/INIT_APPLE_PAY';

export const FETCH_APPLE_PAY_DELIVERY_PRICE_REQUEST =
  '@payTypes/FETCH_APPLE_PAY_DELIVERY_PRICE_REQUEST';
export const FETCH_APPLE_PAY_DELIVERY_PRICE_SUCCESS =
  '@payTypes/FETCH_APPLE_PAY_DELIVERY_PRICE_SUCCESS';
export const FETCH_APPLE_PAY_DELIVERY_PRICE_FAILURE =
  '@payTypes/FETCH_APPLE_PAY_DELIVERY_PRICE_FAILURE';

export const FETCH_APPLE_PAY_STATUS_REQUEST = '@payTypes/FETCH_APPLE_PAY_STATUS_REQUEST';
export const FETCH_APPLE_PAY_STATUS_SUCCESS = '@payTypes/FETCH_APPLE_PAY_STATUS_SUCCESS';
export const FETCH_APPLE_PAY_STATUS_FAILURE = '@payTypes/FETCH_APPLE_PAY_STATUS_FAILURE';

export const INIT_GOOGLE_PAY_REQUEST = '@payTypes/INIT_GOOGLE_PAY_REQUEST';
export const INIT_GOOGLE_PAY_SUCCESS = '@payTypes/INIT_GOOGLE_PAY_SUCCESS';
export const INIT_GOOGLE_PAY_FAILURE = '@payTypes/INIT_GOOGLE_PAY_FAILURE';
