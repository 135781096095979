import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import isomorphicCookies from '../../../utils/cookie/isomorphicCookies';

// Components
import Modal from '../../Modal';
import ModalChooseCity from '../partials/ModalChooseCity/ModalChooseCity';
import ModalFirstView from '../partials/ModalFirstView/ModalFirstView';
import { selectUserCity } from '../../../state/modules/city/actions';

// State
import useAddressSelector from '../../AddressSelector/useAddressSelector';
import { toggleCityPopup } from '../../../state/modules/ui/actions';

// Styles
import styles from './ModalAddress.styl';

const cx = classNames.bind(styles);

const ModalAddress = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { name, id: cityId } = useSelector(state => state.city.userCity);
  const cities = useSelector(state => state.city.list);
  const isOpen = useSelector(state => state.ui.isCityPopupOpen);

  const [openCityList, setOpenCityList] = useState(0);
  const [valueCity, setValueCity] = useState('');
  const [filteredCities, setFilteredCities] = useState([]);

  const {
    addressInLineValue,
    setAddressInLineValue,
    handleOnAddressInLineSelect,
    handleOnAccept,
    isError,
  } = useAddressSelector();

  /** Открываем второе состояние модального окна со списком городов */

  const clickInputCity = () => {
    setValueCity('');
    setOpenCityList(1);
  };

  /** Фильтрация городов на клиенте во втором состоянии */

  const changeFilteredCities = () => {
    const filter = cities.filter(elem => elem.name.toLowerCase().includes(valueCity.toLowerCase()));
    setFilteredCities(filter);
  };

  /** Обработка выбора города и открытие третьего состояния */

  const clickCity = value => {
    isomorphicCookies().set('city_confirm', '1', { expires: 7 });
    dispatch(selectUserCity(value));
  };

  /** Когда у пользователя город верен и нужно запомнить это */

  const onCorrectCity = () => {
    handleOnAccept();
    isomorphicCookies().set('city_confirm', '1', { expires: 7 });
    dispatch(toggleCityPopup(false));
  };

  /** Запуск фильтрации каждый раз при введении символа в инпут */

  useEffect(() => {
    changeFilteredCities();
  }, [valueCity]);

  /** Устанавливаем имя в инпут
   *  Устанавливаем состояние фильтрации городов
   *  Проверяем куки */

  useEffect(() => {
    setValueCity(name);
    setFilteredCities([...cities]);

    if (!isomorphicCookies().get('city_confirm')) {
      dispatch(toggleCityPopup(true));
    }
  }, []);

  useEffect(() => {
    if (cityId !== 1) {
      dispatch(toggleCityPopup(false));
    }
  }, [cityId]);

  return (
    <Modal
      className={cx('ModalAddress', { ModalAddress__open: openCityList === 1 })}
      isOpen={isOpen}
      onClose={onCorrectCity}
    >
      <h3 className={cx('ModalAddress__title')}>
        {intl.formatMessage(
          openCityList === 0
            ? { id: 'modal.address.title' }
            : openCityList === 1
            ? { id: 'modal.address.titleCity' }
            : { id: 'modal.address.titleAddress' },
        )}
      </h3>

      <p className={cx('ModalAddress__subtitle')}>
        {intl.formatMessage({ id: 'modal.address.subtitle' })}
      </p>

      {openCityList === 0 ? (
        <ModalFirstView
          openCityList={openCityList}
          clickInputCity={clickInputCity}
          setValueCity={setValueCity}
          valueCity={valueCity}
          addressInLineValue={addressInLineValue}
          setAddressInLineValue={setAddressInLineValue}
          handleOnAddressInLineSelect={handleOnAddressInLineSelect}
          isError={isError}
          onCorrectCity={onCorrectCity}
          textBlockInput={false}
        />
      ) : (
        <ModalChooseCity
          openCityList={openCityList}
          clickInputCity={clickInputCity}
          valueCity={valueCity}
          setValueCity={setValueCity}
          filteredCities={filteredCities}
          clickCity={clickCity}
        />
      )}
    </Modal>
  );
};

export default ModalAddress;
