import userApi from './api';
import * as types from './actionTypes';

import { resetAddressState } from '../address/actions';
import { fetchCards, resetCardState } from '../card/actions';
import { resetCartState } from '../cart/actions';
import { resetOrderState } from '../order/actions';
import { clearStockDone } from '../stock/actions';
import { resetSailplayState } from '../sailplay/actions';
import { resetChatState } from '../chat/actions';
import {
  removeAccessToken,
  removeUnauthorizedToken,
  removeUserId,
  removeUserStatus,
  setAccessToken,
  setUserId,
  setUserStatus,
} from '../../../utils/auth';
import {
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PHONE_FAILURE,
  CHANGE_PHONE_REQUEST,
  CHANGE_PHONE_SUCCESS,
} from './actionTypes';

export const login = () => ({
  type: types.LOGIN_SUCCESS,
});

export const signUp = (params, onSuccessCallback, onFailureCallback) => {
  return dispatch => {
    dispatch({ type: types.SIGNUP_REQUEST });

    return userApi
      .signUp(params)
      .then(data => {
        removeUnauthorizedToken();

        if (data.access_token) {
          setAccessToken(data.access_token);
        }

        dispatch(login());
        onSuccessCallback?.(data);
        return dispatch({ type: types.SIGNUP_SUCCESS, payload: data });
      })
      .catch(({ message }) => {
        onFailureCallback?.(message);

        return dispatch({ type: types.SIGNUP_FAILURE, error: message });
      });
  };
};

export const signUpWithSms = (params, onSuccessCallback, onFailureCallback) => {
  return dispatch => {
    dispatch({ type: types.SIGNUP_WITH_SMS_REQUEST });

    return userApi
      .postUserSignupWithSms(params)
      .then(data => {
        removeUnauthorizedToken();

        if (data.access_token) {
          setAccessToken(data.access_token);
        }

        dispatch(login());
        onSuccessCallback?.(data);
        return dispatch({ type: types.SIGNUP_WITH_SMS_SUCCESS, payload: data });
      })
      .catch(({ message }) => {
        onFailureCallback?.(message);

        return dispatch({ type: types.SIGNUP_WITH_SMS_FAILURE, error: message });
      });
  };
};

export const signIn = (params, onSuccessCallback, onFailureCallback) => {
  return dispatch => {
    dispatch({ type: types.SIGNIN_REQUEST });

    return userApi
      .signIn(params)
      .then(data => {
        const { access_token, id, loyalty } = data;

        removeUnauthorizedToken();

        if (access_token) {
          setAccessToken(access_token);
        }

        if (id) {
          setUserId(id);
        }

        if (loyalty?.statuses && loyalty?.status) {
          const statusLabel = loyalty.statuses.find(x => x.code === loyalty.status)?.name;
          setUserStatus(statusLabel);
        }

        dispatch(login());
        onSuccessCallback?.(data);

        dispatch({ type: types.SIGNIN_SUCCESS, payload: data });
      })
      .catch(({ message }) => {
        onFailureCallback?.(message);

        return dispatch({ type: types.SIGNIN_FAILURE });
      });
  };
};

export const signInWithSms = (params, onSuccessCallback, onFailureCallback) => {
  return dispatch => {
    dispatch({ type: types.SIGNIN_WITH_SMS_REQUEST });

    return userApi
      .postUserLoginWithSms(params)
      .then(data => {
        const { access_token, id, loyalty } = data;

        removeUnauthorizedToken();

        if (access_token) {
          setAccessToken(access_token);
        }

        if (id) {
          setUserId(id);
        }

        if (loyalty?.statuses && loyalty?.status) {
          const statusLabel = loyalty.statuses.find(x => x.code === loyalty.status)?.name;
          setUserStatus(statusLabel);
        }

        dispatch(login());
        onSuccessCallback?.(data);

        return dispatch({ type: types.SIGNIN_WITH_SMS_SUCCESS, payload: data });
      })
      .catch(({ message }) => {
        onFailureCallback?.(message);

        return dispatch({ type: types.SIGNIN_WITH_SMS_FAILURE });
      });
  };
};

export const signOut = () => {
  return dispatch => {
    dispatch({ type: types.SIGNOUT_REQUEST });

    return userApi
      .signOut()
      .then(() => {
        // сбрасываем адреса в стейте
        dispatch(resetAddressState());
        // сбрасываем карты в стейте
        dispatch(resetCardState());
        // сбрасываем введеный промокод
        dispatch(clearStockDone());
        // сбрасываем корзину
        dispatch(resetCartState());
        // сбрасываем заказ
        dispatch(resetOrderState());
        // сбрасываем историю бонусов
        dispatch(resetSailplayState());
        // сбрасываем чат
        dispatch(resetChatState());

        removeAccessToken();
        removeUserId();
        removeUserStatus();

        return dispatch({ type: types.SIGNOUT_SUCCESS });
      })
      .catch(({ message }) => dispatch({ type: types.SIGNOUT_FAILURE, payload: message }));
  };
};

export const deleteAccount = () => {
  return dispatch => {
    return (
      userApi
        .delAccount()
        // .then(() => dispatch(signOut()))
        .catch(({ message }) => {
          return dispatch({ type: types.UPDATE_PROFILE_FAILURE, error: message });
        })
    );
  };
};
export const recoveryPhoneConfirmSms = params => {
  return dispatch => {
    dispatch({ type: types.RECOVERY_PHONE_CONFIRM_SMS_REQUEST });

    return userApi
      .postUserConfirmCode(params)
      .then(({ status }) => {
        return status ? dispatch({ type: types.RECOVERY_PHONE_CONFIRM_SMS_SUCCESS }) : false;
      })
      .catch(({ message }) => {
        return dispatch({ type: types.RECOVERY_PHONE_CONFIRM_SMS_FAILURE, error: message });
      });
  };
};
export const recoveryPasswordSendConfirmCodeOld = params => {
  return dispatch => {
    dispatch({ type: types.RECOVERY_PASSWORD_SEND_CONFIRM_CODE_REQUEST });

    return userApi
      .postUserConfirmCode(params)
      .then(() => {
        return dispatch({ type: types.RECOVERY_PASSWORD_SEND_CONFIRM_CODE_SUCCESS });
      })
      .catch(({ message }) => {
        return dispatch({
          type: types.RECOVERY_PASSWORD_SEND_CONFIRM_CODE_FAILURE,
          error: message,
        });
      });
  };
};

export const recoveryPasswordSendConfirmCode = params => {
  return dispatch => {
    dispatch({ type: types.RECOVERY_PASSWORD_SEND_CONFIRM_CODE_REQUEST });
    return userApi
      .postUserConfirmationCodeSend(params)
      .then(() => {
        return dispatch({ type: types.RECOVERY_PASSWORD_SEND_CONFIRM_CODE_SUCCESS });
      })
      .catch(({ message }) => {
        return dispatch({
          type: types.RECOVERY_PASSWORD_SEND_CONFIRM_CODE_FAILURE,
          error: message,
        });
      });
  };
};

export function recoveryPasswordSendConfirmCodeReset() {
  return {
    type: types.RECOVERY_PASSWORD_SEND_CONFIRM_CODE_RESET,
  };
}

export const recoveryPasswordMail = params => {
  return dispatch => {
    dispatch({ type: types.RECOVERY_PASSWORD_REQUEST });

    return userApi
      .recoveryPasswordMail(params)
      .then(() => {
        return dispatch({ type: types.RECOVERY_PASSWORD_SUCCESS });
      })
      .catch(({ message }) => {
        return dispatch({ type: types.RECOVERY_PASSWORD_FAILURE, error: message });
      });
  };
};

export const recoveryPasswordPhone = params => {
  return dispatch => {
    dispatch({ type: types.RECOVERY_PASSWORD_REQUEST });
    return userApi
      .recoveryPasswordPhone(params)
      .then(() => {
        return dispatch({ type: types.RECOVERY_PASSWORD_SUCCESS });
      })
      .catch(({ message }) => {
        return dispatch({ type: types.RECOVERY_PASSWORD_FAILURE, error: message });
      });
  };
};

export const changePasswordPhone = params => {
  return dispatch => {
    dispatch({ type: types.CHANGE_PASSWORD_REQUEST });
    return userApi
      .changePasswordPhone(params)
      .then(() => {
        return dispatch({ type: types.CHANGE_PASSWORD_SUCCESS });
      })
      .catch(({ message }) => {
        return dispatch({ type: types.CHANGE_PASSWORD_FAILURE, error: message });
      });
  };
};

export const changePhone = params => {
  return dispatch => {
    dispatch({ type: types.CHANGE_PHONE_REQUEST });
    return userApi
      .postUserChangePhone(params)
      .then(() => {
        dispatch({ type: types.CHANGE_PHONE_SUCCESS });
        return dispatch(updateProfile(params));
      })
      .catch(({ message }) => {
        return dispatch({ type: types.CHANGE_PHONE_FAILURE, error: message });
      });
  };
};

export const recoveryPassword = params => {
  return dispatch => {
    dispatch({ type: types.RECOVERY_PASSWORD_REQUEST });
    return userApi
      .recoveryPasswordMail(params)
      .then(() => {
        return dispatch({ type: types.RECOVERY_PASSWORD_SUCCESS });
      })
      .catch(({ message }) => {
        return dispatch({ type: types.RECOVERY_PASSWORD_FAILURE, error: message });
      });
  };
};

export function recoveryPasswordReset() {
  return {
    type: types.RECOVERY_PASSWORD_RESET,
  };
}

export const rememberPhone = phone => {
  return {
    type: types.RECOVERY_PASSWORD_REMEMBER_PHONE,
    payload: phone,
  };
};

export const rememberEmail = email => {
  return {
    type: types.RECOVERY_PASSWORD_REMEMBER_EMAIL,
    payload: email,
  };
};

export const fetchProfile = params => {
  return (dispatch, getState) => {
    const isAllowedCity = !getState().city.waitOpenings;

    if (!isAllowedCity) return;

    dispatch({ type: types.FETCH_PROFILE_REQUEST });

    return userApi
      .getProfile(params)
      .then(data => {
        return dispatch({ type: types.FETCH_PROFILE_SUCCESS, payload: data });
      })
      .catch(({ message }) => {
        console.error(message);
        return dispatch({ type: types.FETCH_PROFILE_FAILURE });
      });
  };
};

export const updateProfileReset = () => {
  return {
    type: types.UPDATE_PROFILE_RESET,
  };
};

export const updateProfile = params => {
  return dispatch => {
    dispatch({ type: types.UPDATE_PROFILE_REQUEST });

    return userApi
      .updateProfile(params)
      .then(data => {
        return dispatch({ type: types.UPDATE_PROFILE_SUCCESS, data });
      })
      .then(() => {
        return dispatch(fetchCards());
      })
      .catch(({ message }) => {
        return dispatch({ type: types.UPDATE_PROFILE_FAILURE, error: message });
      });
  };
};

export const setAvatar = params => {
  return dispatch => {
    dispatch({ type: types.UPDATE_PROFILE_REQUEST });

    return userApi
      .setAvatar(params)
      .then(data => {
        return dispatch({ type: types.UPDATE_PROFILE_SUCCESS, data });
      })
      .catch(({ message }) => {
        return dispatch({ type: types.UPDATE_PROFILE_FAILURE, error: message });
      });
  };
};

export const setBonuses = number => {
  return {
    type: types.SET_BONUSES,
    payload: number,
  };
};

export const clearUserErrors = keys => {
  return {
    type: types.CLEAR_USER_ERRORS,
    payload: keys,
  };
};

export const landingRegistration = (data, onFailureCallback, onSuccessCallback) => {
  return async dispatch => {
    const { phone, email } = data;
    let hasError = false;

    await userApi
      .hasPhone(phone)
      .then(({ status }) => {
        if (status) {
          hasError = true;
          onFailureCallback(true, 'phone');
        }
      })
      .catch(({ message }) => {
        hasError = true;
        onFailureCallback(false, 'phone', message);
      });

    await userApi
      .hasMail(email)
      .then(({ status }) => {
        if (status) {
          hasError = true;
          onFailureCallback(true, 'email');
        }
      })
      .catch(({ message }) => {
        hasError = true;
        onFailureCallback(false, 'email', message);
      });

    if (hasError) return;

    await dispatch(
      signUp({
        username: `${data.firstName}`,
        phone: data.phone,
        email: data.email,
        password: data.password,
        password_repeat: data.password_repeat,
        sms_state: data.sms_state,
        subscription_state: data.subscription_state,
        promo: '20off',
      }),
    );
    onSuccessCallback();
  };
};

export const changeBonuses = payload => ({
  type: types.CHANGE_BONUSES,
  payload,
});
