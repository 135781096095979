import { useSelector } from 'react-redux';
import cartApi from './api';
import * as types from './actionTypes';

// State
import { applyStock, clearStock } from '../stock/actions';
import { getDeliveryAreaIdSelector, maxCashbackCanUseSelector } from '../order/selectors';

// Utils
import { setUnauthorizedToken } from '../../../utils/auth';
import { setGlobalMessage } from '../../../utils/setGlobalMessage';

export const updateCartItems = data => {
  return {
    type: types.UPDATE_CART_ITEMS,
    payload: data,
  };
};

export const addCartItem = (params, onSuccessCallback, onFailureCallback) => {
  return (dispatch, getState) => {
    dispatch({ type: types.ADD_CART_ITEM_REQUEST });

    const { composition, isUpsale, withoutAnalytic } = params;

    return cartApi
      .postCartAdd(composition, {
        is_upsale: isUpsale,
        delivery_area_id: getDeliveryAreaIdSelector(getState()),
      })
      .then(data => {
        const { unauthorized_token, stock_code } = data;

        if (stock_code) {
          dispatch(applyStock({ stock_code }));
        }

        if (unauthorized_token) {
          setUnauthorizedToken(unauthorized_token);
        }

        onSuccessCallback?.(data);
       return dispatch({
          type: types.ADD_CART_ITEM_SUCCESS,
          payload: { data, withoutAnalytic },
        });

      }).then(() => {
        const cartState = getState().cart.state;

        if (!cartState?.isValid) {
          cartState?.messages?.forEach(message => setGlobalMessage(message));
        }
      })
      .catch(({ message }) => {
        onFailureCallback?.(message);
        return dispatch({ type: types.ADD_CART_ITEM_FAILURE });
      });
  };
};

export function addCartItemFromUpsale(payload) {
  return { type: types.ADD_CART_ITEM_FROM_UPSALE, payload };
}

export function addCartItemFromHistory(payload) {
  return { type: types.ADD_CART_ITEM_FROM_HISTORY, payload };
}

export const fetchCartItems = newStockCode => {
  return (dispatch, getState) => {
    dispatch({ type: types.FETCH_ITEMS_REQUEST });
    const isAllowedCity = !getState().city.waitOpenings

    if (!isAllowedCity) return

    return cartApi
      .getCart({ delivery_area_id: getDeliveryAreaIdSelector(getState()) })
      .then(data => {
        const { unauthorized_token, stock_code, composition = [] } = data;

        if (unauthorized_token) {
          setUnauthorizedToken(unauthorized_token);
        }

        const gifts = composition
          .filter(good => good.type === 'gift')
          .map(({ item }) => ({ id: item.id, ingredients: [...item.added_ingredients, ...item.removed_ingredients] }));
        if (newStockCode && stock_code !== newStockCode) {
          dispatch(applyStock({ stock_code: newStockCode }));
        } else if (stock_code && stock_code !== getState().stock.data.code) {
          dispatch(applyStock({ stock_code, gifts, withCartUpdate: false }));
        }

        return dispatch({ type: types.FETCH_ITEMS_SUCCESS, payload: data });
      })
      .catch(({ message }) => {
        if (newStockCode) {
          dispatch(applyStock({ stock_code: newStockCode }));
        }

        return dispatch({ type: types.FETCH_ITEMS_FAILURE, error: message });
      });
  };
};

export const clearCart = () => {
  return (dispatch, getState) => {
    dispatch({ type: types.CLEAR_REQUEST });

    return cartApi
      .delCartClear()
      .then(() => {
        const stock_code = getState().stock.data.code;

        if (stock_code) {
          dispatch(applyStock({ stock_code }));
        }

        return dispatch({ type: types.CLEAR_SUCCESS });
      })
      .catch(({ message }) => {
        return dispatch({ type: types.CLEAR_FAILURE, payload: message });
      });
  };
};

export const updateCartItem = (params, onSuccessCallback, onFailureCallback) => {
  return (dispatch, getState) => {
    dispatch({ type: types.UPDATE_CART_ITEM_REQUEST });

    return cartApi
      .postCartUpdate(params, { delivery_area_id: getDeliveryAreaIdSelector(getState()) })
      .then(data => {
        const { stock_code } = data;

        if (stock_code) {
          dispatch(applyStock({ stock_code }));
        }

        onSuccessCallback?.(data);
        return dispatch({ type: types.UPDATE_CART_ITEM_SUCCESS, payload: data });
      })
      .then(() => {
        const cartState = getState().cart.state;

        if (!cartState?.isValid) {
          cartState?.messages?.forEach(message => setGlobalMessage(message));
        }
      })
      .catch(({ message }) => {
        onFailureCallback?.(message);
        return dispatch({ type: types.UPDATE_CART_ITEM_FAILURE });
      });
  };
};

export function removeCartItem(hash) {
  return (dispatch, getState) => {
    dispatch({ type: types.REMOVE_CART_ITEM_REQUEST });

    return cartApi
      .delCartRemove(hash, { delivery_area_id: getDeliveryAreaIdSelector(getState()) })
      .then(data => {
        const { stock_code } = data;

        if (stock_code) {
          dispatch(applyStock({ stock_code }));
        }

        return dispatch({ type: types.REMOVE_CART_ITEM_SUCCESS, payload: data });
      })
      .then(() => {
        const cartState = getState().cart.state;

        if (!cartState?.isValid) {
          cartState?.messages?.forEach(message => setGlobalMessage(message));
        }
      })
      .catch(() => {
        return dispatch({ type: types.REMOVE_CART_ITEM_FAILURE });
      });
  };
}

export function changeCartSailplayDiscount(papabonus_charge) {
  return async (dispatch, getState) => {
    const stockPromocode = getState().stock?.data?.code;
    if (stockPromocode) {
      await dispatch(clearStock());
    }

    dispatch({ type: types.CHANGE_CART_SAILPLAY_DISCOUNT_REQUEST });

    return cartApi
      .postCartchangeCartSailplayDiscount(papabonus_charge, getDeliveryAreaIdSelector(getState()))
      .then(data => {
        const { stock_code } = data;

        if (stock_code) {
          dispatch(applyStock({ stock_code }));
        }

        return dispatch({ type: types.CHANGE_CART_SAILPLAY_DISCOUNT_SUCCESS, payload: data });
      })
      .then(() => {
        const cartState = getState().cart.state;

        if (!cartState?.isValid) {
          cartState?.messages?.forEach(message => setGlobalMessage(message));
        }
      })
      .catch(() => {
        return dispatch({ type: types.CHANGE_CART_SAILPLAY_DISCOUNT_FAILURE });
      });
  };
}

export const setAllowedCartSailplayDiscount = () => {
  return (dispatch, getState) => {
    const state = getState();
    const { sailplay_discount } = state.cart;
    const maxBonusesCanUse = maxCashbackCanUseSelector(state);

    if (maxBonusesCanUse > 0 && sailplay_discount > maxBonusesCanUse) {
      return dispatch(changeCartSailplayDiscount(maxBonusesCanUse));
    }

    if (maxBonusesCanUse <= 0 && sailplay_discount !== 0) {
      return dispatch(changeCartSailplayDiscount(0));
    }

    return null;
  };
};

export const resetCartState = () => ({ type: types.RESET_CART_STATE });
