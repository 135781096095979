function clientConfig() {
  const isItNew2 = process.env.NEW2 === 'true';
  const isLocal = process.env.LOCAL === 'true';

  if (isItNew2) {
    console.warn('NEW2:', true);
  }

  if (process.env.COUNTRY) {
    console.log(`It is ${process.env.COUNTRY} config...`);
    const getConfig = require(`./config.${process.env.COUNTRY}`);

    return getConfig(isItNew2, isLocal);
  }
  console.log('^_^ where is your country config?');
  return null;
}

module.exports = clientConfig();
