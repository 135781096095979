export const SEARCH_CITIES = '@city/SEARCH_CITIES';
export const SET_USER_CITY = '@city/SET_USER_CITY';

export const FETCH_CITIES_REQUEST = '@city/FETCH_CITIES_REQUEST';
export const FETCH_CITIES_SUCCESS = '@city/FETCH_CITIES_SUCCESS';
export const FETCH_CITIES_FAILURE = '@city/FETCH_CITIES_FAILURE';

export const FETCH_USER_CITY_REQUEST = '@city/FETCH_USER_CITY_REQUEST';
export const FETCH_USER_CITY_SUCCESS = '@city/FETCH_USER_CITY_SUCCESS';
export const FETCH_USER_CITY_FAILURE = '@city/FETCH_USER_CITY_FAILURE';

export const FETCH_CURRENT_GEO_POSITION_REQUEST = '@city/FETCH_CURRENT_GEO_POSITION_REQUEST';
export const FETCH_CURRENT_GEO_POSITION_SUCCESS = '@city/FETCH_CURRENT_GEO_POSITION_SUCCESS';
export const FETCH_CURRENT_GEO_POSITION_FAILURE = '@city/FETCH_CURRENT_GEO_POSITION_FAILURE';

export const SET_WAIT_OPENINGS_CITY = '@city/SET_WAIT_OPENINGS_CITY';
