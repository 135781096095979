/**
 * Создает ивент "productClick" для отправки в dataLayer
 * (используется только перед addToCart)
 *
 * @param {object} product Товар со структурой для аналитики
 * @returns {object} outItem Ивент нажатия на товар для dataLayer
 */
export function productClickHandler(product) {
  if (product) {
    product.position = product.count;
    delete product.count;

    const products = [{ ...product }]; // Продукт, на который кликнули
    const actionField = { list: product.category };

    const outItem = {
      event: 'productClick',
      eventCategory: 'Ecommerce',
      eventAction: 'productClick',
      eventLabel: undefined,

      ecommerce: {
        click: {
          actionField,
          products: [...products],
        },
      },
    };

    return outItem;
  }

  return null;
}

/**
 * Создает ивент "addToCart" для отправки в dataLayer
 *
 * @param {object} product Товар со структурой для аналитики
 * @param {string} currency Код валюты
 * @returns {object} outItem Ивент добавления товара в корзину для dataLayer
 */
export function addToCartHandler(product, currency) {
  if (product) {
    product.position = product.count;
    delete product.count;

    const products = [{ ...product }]; // Продукт, который добавили в корзину

    const outItem = {
      event: 'addToCart',
      eventCategory: 'Ecommerce',
      eventAction: 'addToCart',
      eventLabel: undefined,

      ecommerce: {
        add: {
          currencyCode: currency.toUpperCase(),
          products: [...products],
        },
      },
    };

    return outItem;
  }

  return null;
}

/**
 * Создает ивент "removeCartItem" для отправки в dataLayer
 *
 * @param {object|array} product Товар со структурой для аналитики
 * @returns {object} outItem Ивент удаления для dataLayer
 */
export function removeFromCartHandler(product) {
  if (product) {
    delete product.count;

    const products = Array.isArray(product) ? [...product] : [{ ...product }]; // Продукт, который удалили из корзины

    const outItem = {
      event: 'removeFromCart',
      eventCategory: 'Ecommerce',
      eventAction: 'removeFromCart',
      eventLabel: undefined,

      ecommerce: {
        remove: {
          products: [...products],
        },
      },
    };

    return outItem;
  }

  return null;
}
