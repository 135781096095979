import { getProductsFromCart } from '../../helpers';

// State
import * as cartTypes from '../../../modules/cart/actionTypes';
import * as orderTypes from '../../../modules/order/actionTypes';

// Routes
import { paths, getRoute } from '../../../../entry/routes';
import isomorphicCookies from '../../../../utils/cookie/isomorphicCookies';

/**
 * Ивент для посещения корзины (Step 1) => Отправка Step 2(доставка)(по умолчанию) => Отправка Step 3(оплата)(по умолчанию)
 * @param {object} action
 * @param {object} prevState
 * @param {object} nextState
 * @returns {object} Ивент
 */
export function visitCheckout(action, prevState, nextState) {
  const {
    location: { current, previous },
    cart,
  } = nextState;

  const currentPath = current?.pathname;
  const prevPath = previous?.pathname;

  if (
    (prevPath !== getRoute(paths.order) && currentPath === getRoute(paths.order)) ||
    (prevPath === getRoute(paths.order) &&
      currentPath === getRoute(paths.order) &&
      action.type === cartTypes.FETCH_ITEMS_SUCCESS)
  ) {
    const actionField = {
      step: 1,
      option: nextState.user.isAuthenticated ? 'Авторизация' : 'Регистрация',
    };

    let products = [];

    if (cart) {
      products = getProductsFromCart(cart);
    }

    if (!products.length) {
      return null;
    }

    return {
      event: 'checkout',
      eventCategory: 'Ecommerce',
      eventAction: 'checkout',
      eventLabel: 'Step 1',
      ecommerce: {
        checkout: {
          actionField,
          products,
        },
      },
    };
  }

  return null;
}

export function addressCheck(action, prevState, nextState) {
  const { analytAddress } = action;
  const isSuccess = action.type === orderTypes.FETCH_DELIVERY_RESTAURANT_DETAILS_SUCCESS;

  const actionPayloadAddress = ((action || {}).payload || {}).address;
  let outAddress = '';
  if (actionPayloadAddress) {
    outAddress = actionPayloadAddress;
  }

  const outData = {
    event: 'UA event',
    eventCategory: 'Проверка адреса',
    eventAction: isSuccess ? 'Доставка есть' : outAddress,
    eventLabel: analytAddress,
    deliveryAvailable: isSuccess ? 'Доставка есть' : 'Доставки нет',
  };

  return analytAddress ? outData : null;
}

export function sendCheckoutCancelOrderAnalytic(action, prevState, nextState) {
  const {
    responsive: { isRealPhone, isRealTablet },
    city: { userCity },
    user: { isAuthenticated },
  } = nextState;
  const platform = isRealPhone || isRealTablet ? 'Мобайл' : 'Десктоп';
  const pj_clientId = isomorphicCookies().get('pj_clientId');
  const pj_sessionId = isomorphicCookies().get('pj_sessionId');

  return {
    v: 1,
    tid: 'UA-37025725-1',
    cid: pj_clientId,
    t: 'event',
    ec: 'Ecommerce',
    ea: 'Refund',
    cd2: pj_clientId,
    cd5: isAuthenticated ? 'Авторизован' : 'Не авторизован',
    cd7: pj_sessionId,
    cd8: userCity.name,
    cd16: 'Версия 2.0',
    // cd21: 'Русский', //Язык пользователя (Русский/English)
    // cd27: '00:01:56', //Custom Dimension 27 - HitID - время хита в формате ЧЧ:ММ:СС - переменная
    cd26: platform,
    pa: 'refund',
    ti: action.payload,
    ni: 1,
  };
}
