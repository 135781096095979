import { addUserId } from '../../helpers';

// Router
import { paths, getRoute } from '../../../../entry/routes';

export function paySuccess(action, prevState, nextState) {
  let outData = {
    event: 'transaction',
    eventCategory: 'Ecommerce',
    eventAction: 'transaction',
    eventLabel: undefined,
  };
  outData = addUserId(outData, nextState);
  outData = Object.assign(outData, action.payload);

  return outData;
}

export function repeatOrder(action, prevState, nextState) {
  let fromPage = '';
  if (nextState && nextState.location && nextState.location.current) {
    const { pathname } = nextState.location.current;
    if (pathname) {
      if (pathname === getRoute(paths.home)) {
        fromPage = 'С главной страницы';
      } else if (pathname === getRoute(paths.profile)) {
        fromPage = 'Из истории заказов';
      } else {
        fromPage = pathname;
      }
    }
  }

  const outData = {
    event: 'UA event',
    eventCategory: 'Повтор заказа',
    eventAction: fromPage,
    eventLabel: undefined,
  };

  return outData;
}
