import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useSelector, useDispatch } from 'react-redux';

// Components
import Modal from '../Modal';
import Paper from '../Paper';
import Button from '../Button';

// State
import { hideModalConfirm } from '../../state/modules/modalConfirm/actions';

// Icons
import CloseIcon from '../../icons/close.svg';

// Styles
import styles from './ModalConfirm.styl';

const cx = classNames.bind(styles);

export default function ModalConfirm() {
  const dispatch = useDispatch();
  const {
    isOpen,
    onConfirm,
    btnConfirmText,
    onCancel,
    btnCancelText,
    message,
    container,
    className,
  } = useSelector(state => state.modalConfirm);

  const [isLoading, setIsLoading] = useState(false);

  const onClose = () => {
    dispatch(hideModalConfirm());
  };

  const handleOnCancel = async () => {
    if (onCancel) {
      await onCancel();
    }

    onClose();
  };

  const handleOnConfirm = async () => {
    if (onConfirm) {
      setIsLoading(true);
      await onConfirm();
      setIsLoading(false);
    }

    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Paper className={cx('ModalConfirm', className)}>
        <div
          className={cx('ModalConfirm__closeButton')}
          onClick={onClose}
          onKeyDown={e => e.key === 'Enter' && onClose()}
          role="button"
          tabIndex={0}
        >
          <CloseIcon width={16} height={16} />
        </div>
        <div className={cx('ModalConfirm__text')}>
          <div>{message}</div>
          {container}
        </div>
        <div className={cx('ModalConfirm__actions')}>
          <Button className={cx('ModalConfirm__actions__item')} onClick={handleOnCancel} link>
            <span>{btnCancelText}</span>
          </Button>
          <Button
            className={cx('ModalConfirm__actions__item')}
            onClick={handleOnConfirm}
            isLoading={isLoading}
          >
            <span>{btnConfirmText}</span>
          </Button>
        </div>
      </Paper>
    </Modal>
  );
}
