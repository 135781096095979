import { toast } from 'react-toastify';
import _isObject from 'lodash/isObject';
import _isEmpty from 'lodash/isEmpty';

export const setGlobalMessage = (payload, { type = 'warn', options = {} } = {}) => {
  const toaster = toast[type];
  if (!_isEmpty(payload)) {
    if (_isObject(payload)) {
      Object.keys(payload).map(key =>
        _isObject(payload[key])
          ? payload[key].map(err => toaster(err, options))
          : toaster(payload[key], options),
      );
    }

    toaster(payload, options);
  }
};
