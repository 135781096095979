import { post, get } from '../../../utils/api';
import { withUnauthorizedToken } from '../../../utils/auth';

export default {
  getStockList: () => get({ url: 'stock/list' }),
  postStockApply: params => post({ url: 'stock/apply', data: withUnauthorizedToken(params) }),
  postStockApplyExtra: params =>
    post({ url: 'stock/apply-extra', data: withUnauthorizedToken(params) }),
  postStockClear: params => post({ url: 'stock/clear', data: withUnauthorizedToken(params) }),
  getStockUnconditionalList: () =>
    get({ url: 'stock/unconditional-list', data: withUnauthorizedToken() }),
};
