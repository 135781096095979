import * as types from './actionTypes';

const initialState = {
  cash_back: 0,
  composition: [],
  discount_price: 0,
  price: 0,
  isLoading: false,
  isLoaded: false,
  error: '',
  sailplay_discount: 0,
};

export default function cartReducer(state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_CART_ITEMS:
      return {
        ...state,
        ...action.payload,
        isLoaded: true,
        error: '',
      };

    case types.ADD_CART_ITEM_SUCCESS: {
      return {
        ...state,
        ...action.payload.data,
        isLoaded: true,
        error: '',
      };
    }

    //

    case types.FETCH_ITEMS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        error: '',
      };

    case types.FETCH_ITEMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        error: '',
        ...action.payload,
      };

    case types.FETCH_ITEMS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        error: action.error,
      };

    case types.CHANGE_CART_SAILPLAY_DISCOUNT_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    case types.CLEAR_SUCCESS:
      return {
        ...initialState,
        isLoaded: true,
      };

    case types.UPDATE_CART_ITEM_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case types.REMOVE_CART_ITEM_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case types.RESET_CART_STATE:
      return {
        ...initialState,
        isLoaded: true,
      };

    default:
      return state;
  }
}
