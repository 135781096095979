import React, { PureComponent } from 'react';
import { Polygon } from 'react-yandex-maps';

export default class MapPolygons extends PureComponent {
  state = {};

  geometry = [this.props.item.polygon || []];

  render() {
    const color = 'rgb(0, 148, 113)';
    const zIndex = 1;

    const standardPolygonOptions = {
      fillColor: color,
      strokeColor: color,
      fillOpacity: 0.2,
      zIndex,
      interactive: false,
    };

    return (
      <Polygon
        defaultGeometry={this.geometry}
        defaultOptions={{ ...standardPolygonOptions }}
      />
    );
  }
}

