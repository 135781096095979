const prefix = '@promotionGame';

export const ACTIVATE_PROMOTION_GAME = `${prefix}/ACTIVATE_PROMOTION_GAME`;
export const SET_ARTEFACTS_COUNT = `${prefix}/SET_ARTEFACTS_COUNT`;
export const SET_ARTEFACTS_POSITIONS = `${prefix}/SET_ARTEFACTS_POSITIONS`;

export const OPEN_PROMO_GAME_POPUP = `${prefix}/OPEN_PROMO_GAME_POPUP`;
export const CLOSE_PROMO_GAME_POPUP = `${prefix}/CLOSE_PROMO_GAME_POPUP`;

export const PROMO_GAME_CHECKPOINT = `${prefix}/PROMO_GAME_CHECKPOINT`;
export const PROMO_GAME_CHECKPOINT_REPEATED = `${prefix}/PROMO_GAME_CHECKPOINT_REPEATED`;

export const PROMO_GAME_FALSY_ARTEFACT = `${prefix}/PROMO_GAME_FALSY_ARTEFACT`;

export const PROMO_GAME_REWARD_REQUEST_SUCCESS = `${prefix}/PROMO_GAME_REWARD_REQUEST_SUCCESS`;

