import addressApi from './api';
import * as types from './actionTypes';

import { resetCheckout } from '../order/actions';

/**
 * Запрашиваем список адресов
 */
export const fetchAddresses = () => {
  return dispatch => {
    dispatch({ type: types.FETCH_ADDRESSES_REQUEST });

    return addressApi
      .getAddresses()
      .then(data => {
        return dispatch({ type: types.FETCH_ADDRESSES_SUCCESS, payload: data });
      })
      .catch(({ message }) => {
        return dispatch({ type: types.FETCH_ADDRESSES_FAILURE, payload: message });
      });
  };
};

/**
 * Cохраняем новый адрес/редактируем существующий
 * @param {*} values - {address_in_line, flat, floor, intercom, porch}
 * @param {*} address_id - id, если редактируем существующий адрес
 */
export const saveAddress = ({ address, address_id, city_id }, onSuccessCallback) => {
  return dispatch => {
    dispatch({ type: types.SAVE_ADDRESS_REQUEST });

    const { address_in_line, flat = '', floor = '', intercom = '', porch = '' } = address;

    // если есть id, значит мы изменяем существующий адрес, а не добавляем новый
    const params = {
      address_id,
      address: { address_in_line, flat, floor, intercom, porch },
      city_id,
    };

    return addressApi
      .saveAddress(params, Boolean(address_id))
      .then(data => {
        onSuccessCallback?.();

        return dispatch({
          type: types.SAVE_ADDRESS_SUCCESS,
          payload: { data, isUpdate: Boolean(address_id) },
        });
      })
      .catch(({ message }) => {
        return dispatch({ type: types.SAVE_ADDRESS_FAILURE, payload: message });
      });
  };
};

/**
 * Удаляем существующий адрес из списка
 * @param {*} payload - id адреса
 */
export const deleteAddress = id => {
  return (dispatch, getState) => {
    dispatch({ type: types.DELETE_ADDRESS_REQUEST });

    return addressApi
      .deleteAddress(id)
      .then(() => {
        // сбрасываем чекаут, если удаляется выбранный адрес
        if (getState().order.checkout.deliveryAddress.address_id === id) {
          dispatch(resetCheckout());
        }

        return dispatch({ type: types.DELETE_ADDRESS_SUCCESS, payload: id });
      })
      .catch(({ message }) => {
        return dispatch({ type: types.DELETE_ADDRESS_FAILURE, payload: message });
      });
  };
};

export function resetAddressState(payload) {
  return { type: types.RESET_ADDRESS_STATE, payload };
}
