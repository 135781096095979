import {useSelector} from "react-redux";
import {useMemo} from "react";

export const usePromoArtefact = (id) => {

  const {artefacts} = useSelector(state => state.promotionGame)

  const artefact = useMemo(() => {
    return artefacts.find(art => art.id === id)
  }, [artefacts, id])

  return artefact ?? {imageProps: null, isFalsyArtefact: null}
}
