import { addUserId } from '../../helpers';

// Utils
import { difference } from '../../../../utils/helpers';

export function loginSuccess(action, prevState, nextState) {
  let eventAction = 'Без покупки';
  if (nextState.location.current.pathname.includes('order')) {
    eventAction = 'В процессе покупки';
  }

  const userStatus = nextState?.user?.profile?.loyalty?.status;
  const statuses = nextState?.user?.profile?.loyalty?.statuses;
  const statusLabel = statuses?.find(x => x.code === userStatus)?.name;

  let outData = {
    event: 'UA event',
    eventCategory: 'Авторизация',
    eventAction, // 'В процессе покупки',	//Если из шапки, то «Без покупки»
    eventLabel: undefined,
  };

  if (statusLabel) {
    outData.userStatus = statusLabel;
  }

  outData = addUserId(outData, nextState);

  return outData;
}

// @user/SIGNUP_SUCCESS -> Успешная регистрация
export function signUpSuccess(action, prevState, nextState) {
  let eventAction = 'Без покупки';
  if (nextState.location.current.pathname.includes('order')) {
    eventAction = 'В процессе покупки';
  }

  let outData = {
    event: 'UA event',
    eventCategory: 'Регистрация',
    eventAction, // 'В процессе покупки',	//Если из шапки, то «Без покупки»
    eventLabel: undefined,
  };

  outData = addUserId(outData, nextState);

  return outData;
}

// @user/UPDATE_PROFILE_SUCCESS -> Успешное обновление данных
export function updateProfileSuccess(action, prevState, nextState) {
  let diff = null;

  const nextUserProfile = nextState?.user?.profile;
  const prevUserProfile = prevState?.user?.profile;

  if (nextUserProfile && prevUserProfile) {
    diff = difference(nextUserProfile, prevUserProfile);
  }

  const outData = {
    event: 'UA event',
    eventCategory: 'Личный кабинет',
    eventAction: 'Изменение данных',
    eventLabel: undefined,
  };

  const eventLabelArr = [];

  Object.keys(diff).forEach(key => {
    const label = nextState?.intl?.messages?.[`field.${key}.label`];

    if (label) {
      eventLabelArr.push(`Смена ${label}`);
    } else {
      switch (key) {
        case 'birth_date':
          eventLabelArr.push(`Смена Даты рождения`);
          break;
        case 'last_name':
          eventLabelArr.push(`Смена Имени`);
          break;
        case 'sms_state':
          eventLabelArr.push(`Смена SMS с рекламными рассылками`);
          break;
        case 'subscription_state':
          eventLabelArr.push(`Смена Рассылка о подарках и акциях компании`);
          break;
        case 'addresses':
          eventLabelArr.push(`Смена Адреса`);
          break;

        default:
          eventLabelArr.push(`Смена ${key}`);
          break;
      }
    }
  });

  outData.eventLabel = eventLabelArr.join(', ');

  return outData;
}

// @user/RECOVERY_PHONE_CONFIRM_SMS_SUCCESS -> Запрошен код смены телефона
export function recoveryPhoneSendConfirmSmsSuccess(action, prevState, nextState) {
  const outData = {
    event: 'UA event',
    eventCategory: 'Смена телефона',
    eventAction: 'Запрошен код',
    eventLabel: undefined,
  };

  return outData;
}

// @user/RECOVERY_PASSWORD_SEND_CONFIRM_CODE_SUCCESS -> Запрошен код смены пароля
export function recoveryPasswordSendConfirmCodeSuccess(action, prevState, nextState) {
  const outData = {
    event: 'UA event',
    eventCategory: 'Восстановление пароля',
    eventAction: 'Запрошен код',
    eventLabel: undefined,
  };

  return outData;
}

// @user/RECOVERY_PASSWORD_SUCCESS -> Пароль сменен
export function recoveryPasswordSuccess(action, prevState, nextState) {
  const outData = {
    event: 'UA event',
    eventCategory: 'Восстановление пароля',
    eventAction: 'Введен новый пароль',
    eventLabel: undefined,
  };

  return outData;
}

// @address/SAVE_ADDRESS_SUCCESS -> Смена адреса
export function saveAddressSuccess(action, prevState, nextState) {
  const {
    current: { pathname },
  } = nextState.location;

  const outData = {
    event: 'UA event',
    eventCategory: 'Добавление нового адреса',
    eventAction: 'Домашний',
    eventLabel: undefined,
  };

  if (pathname) {
    if (pathname.includes('order')) {
      outData.eventLabel = 'В процессе покупки';
    } else {
      outData.eventLabel = 'Без покупки';
    }
  }

  return outData;
}


// @address/SAVE_ADDRESS_SUCCESS -> Смена адреса
export function sendFormForSale(action, prevState, nextState) {
  const outData = {
    event: 'UA event',
    eventCategory: 'form',
    eventAction: 'submit',
    eventLabel: `form-${nextState.city.userCity._supertag}`,
  };

  return outData;
}
