import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// Actions
import { resetUnreadedMessages } from '../../../../state/modules/chat/actions';

import styles from './ChatMessages.styl';

const cx = classNames.bind(styles);

const ChatMessages = ({
  chats,
  activeChat,
  className,
  viewImageHandler,
  messagesVisibleHandler,
  messagesHiddenHandler,
}) => {
  const dispatch = useDispatch();
  const messagesEndRef = useRef(null);
  const allMesagesRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const renderScroll = () => {
    const currentHeight = allMesagesRef.current?.scrollHeight;
    allMesagesRef.current?.scroll(0, currentHeight);
  };
  const allChats = [...chats, activeChat];

  useEffect(() => {
    scrollToBottom();
  }, [chats, activeChat]);

  useEffect(() => {
    renderScroll();
    messagesVisibleHandler();
    dispatch(resetUnreadedMessages());

    return () => {
      messagesHiddenHandler();
    };
  }, []);

  return (
    <div className={cx(className)} ref={allMesagesRef}>
      {allChats.map(chat => {
        return chat.messages?.map(message => {
          return (
            <div className={cx('Message')} key={message.text + message.time}>
              {message.text && (
                <div
                  className={
                    message.is_message_from_user
                      ? cx('Message__container', 'Message__container_right')
                      : cx('Message__container')
                  }
                >
                  <span
                    className={
                      message.is_message_from_user
                        ? cx('Message__text', 'Message__text_right')
                        : cx('Message__text', 'Message__text_left')
                    }
                  >
                    {message.text}
                  </span>
                </div>
              )}
              {message.attachment_links?.length > 0 && (
                <div
                  className={
                    message.is_message_from_user
                      ? cx('Message__image', 'Message__image_right')
                      : cx('Message__image', 'Message__image_left')
                  }
                  style={{ backgroundImage: `url('${message.attachment_links[0]}')` }}
                  onClick={() => viewImageHandler(message.attachment_links[0])}
                />
              )}
              <span
                className={
                  message.is_message_from_user
                    ? cx('Message__time', 'Message__time_right')
                    : cx('Message__time')
                }
              >
                {message.time?.slice(11, 12) !== ' '
                  ? message.time?.slice(11, 16)
                  : message.time?.slice(12, 17)}
              </span>
            </div>
          );
        });
      })}
      <div ref={messagesEndRef} />
    </div>
  );
};

ChatMessages.defaultProps = {
  chats: [],
  activeChat: {},
  className: '',
};

ChatMessages.propTypes = {
  chats: PropTypes.array,
  activeChat: PropTypes.object,
  className: PropTypes.string,
  viewImageHandler: PropTypes.func.isRequired,
  messagesVisibleHandler: PropTypes.func.isRequired,
  messagesHiddenHandler: PropTypes.func.isRequired,
};

export default ChatMessages;
