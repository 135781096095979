import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';

import Container from '../Container';
import Heading from '../Heading';

import DesktopImage from './common/desktop.png';
import MobileImage from './common/mobile.png';

import styles from './Error.styl';

const list = [
  { Ангарск: ['+7 (3955) 535-535'] },
  { Астрахань: ['+7 (8512) 66666-1', '+7 (917) 093-22-24'] },
  { Балашиха: ['+7 (499) 503-73-56'] },
  { Барнаул: ['+7 (3852) 25-75-00'] },
  { Белгород: ['+7 (4722) 25-73-29'] },
  { Благовещенск: ['+7 (914) 557-35-55'] },
  { Брянск: ['+7 (4832) 770-338', '+7 (4832) 306-040'] },
  { 'Великий Новгород': ['+7 (816) 2-150-140'] },
  { Видное: ['+7 (966) 170-05-11'] },
  { Владимир: ['+7 (492) 277-30-80'] },
  { Воронеж: ['+7 (473) 260-22-33'] },
  { Геленджик: ['+7 (86141) 5-81-81'] },
  { Дмитров: ['+7 (499) 530-83-18'] },
  { Долгопрудный: ['+7 (499) 530-80-97'] },
  { Домодедово: ['+7 (499) 530-80-75'] },
  { Екатеринбург: ['+7 (343) 333-55-55'] },
  { Железнодорожный: ['+7 (499) 270-64-63'] },
  { Зеленоград: ['+7 (499) 503-73-20'] },
  { Иваново: ['+7 (495) 276-70-54'] },
  { Ижевск: ['+7 (3412) 22-11-77'] },
  { Иркутск: ['+7 (3952) 33-33-55'] },
  { Истра: ['+7 (499) 530-82-86'] },
  { Йошкар: ['+7 (8362) 232-555'] },
  { Казань: ['+7 (843) 203-8-444'] },
  { Кемерово: ['+7 (3842) 555-333'] },
  { Кировск: ['+7 (815) 313-33-00'] },
  { Королев: ['+7 (964) 628-54-19'] },
  { Котельники: ['+7 (495) 798-61-62'] },
  { Красногорск: ['+7 (499) 270-64-29'] },
  { Краснодар: ['+7 (861) 272-52-52'] },
  { Красноярск: ['+7 (391) 222-7-222'] },
  { 'Куркино (Химки)': ['+7 (964) 628-49-36'] },
  { Липецк: ['+7 (4742) 55-66-08', '+7 (4742) 55-66-09'] },
  { Лобня: ['+7 (495) 740-84-86'] },
  { Люберцы: ['+7 (903) 018-89-46'] },
  { Мытищи: ['+7 (499) 503-73-86'] },
  { Нефтеюганск: ['+7 (982) 577-21-30'] },
  { Нижневартовск: ['+7 (3466) 24-22-22'] },
  { 'Нижний Новгород': ['+7 (831) 214-22-14'] },
  { Новокузнецк: ['+7 (3843) 777-333'] },
  { Новороссийск: ['+7 (8617) 302-000'] },
  { Новосибирск: ['+7 (383) 202-22-22'] },
  { Ногинск: ['+7 (499) 530-82-84', '+7 (995) 119-68-06'] },
  { Норильск: ['+7 (3919) 45-70-46'] },
  { Обнинск: ['+7 (484) 395-72-22'] },
  { Одинцово: ['+7 (906) 029-33-84'] },
  { Октябрьский: ['+7 (499) 270-64-58'] },
  { Омск: ['+7 (3812) 66-55-66'] },
  { Пенза: ['+7 (8412) 707-999'] },
  { Подольск: ['+7 (499) 271-37-48'] },
  { Пушкино: ['+7 (495) 139-62-63'] },
  { Раменское: ['+7 (499) 270-64-28'] },
  { Реутов: ['+7 (499) 271-37-42'] },
  { 'Ростов-на-Дону': ['+7 (988) 518-51-28', '+7 (989) 517-74-48'] },
  { Рязань: ['+7 (951) 109-97-21'] },
  { 'Санкт-Петербург': ['+7 (812) 449-20-17'] },
  { Салехард: ['+7 (349) 223-01-01'] },
  { Самара: ['+7 (846) 201-2-201'] },
  { Саранск: ['+7 (937) 513-01-60'] },
  { Саратов: ['+7 (845) 233-87-05'] },
  { Солнечногорск: ['+7 (977) 488-88-81'] },
  { Сочи: ['+7 862 444 08 90'] },
  { 'Сочи (Адлер)': ['+7 (800) 300-37-33'] },
  { 'Сочи (Центр)': ['+7 (862) 444-44-45'] },
  { 'Старая Купавна': ['+7 (495) 789-05-25'] },
  { Сургут: ['550-555'] },
  { Сходня: ['+7 (495) 792 63 64'] },
  { Тверь: ['+7 (4822) 78-21-21'] },
  { Томск: ['+7 (3822) 777-888'] },
  { Троицк: ['+7 (903) 019-54-25'] },
  { Тюмень: ['+7 (3452) 68-01-01'] },
  { Уфа: ['+7 (347) 225-44-44'] },
  { Фрязино: ['+7 (495) 792-43-45'] },
  { Химки: ['+7 (495) 775-07-94'] },
  { Чехов: ['+7 (495) 276-70-38'] },
  { Щербинка: ['+7 (964) 628-67-38'] },
  { Щёлково: ['+7 (499) 503-73-42'] },
  { Электросталь: ['+7 (499) 530-80-95'] },
  { Якутск: ['+7 (914) 275-96-05'] },
  { Ярославль: ['+7 (4852) 595-255'] },
];

function groupByFirstLetter(list) {
  const grouped = {};

  list.forEach(item => {
    const cityName = Object.keys(item)[0];
    const firstLetter = cityName.charAt(0).toUpperCase();

    if (!grouped[firstLetter]) {
      grouped[firstLetter] = [];
    }

    grouped[firstLetter].push(item);
  });

  return grouped;
}

const mainCities = [{ Москва: ['+7 (495) 775-07-94'] }];

const contacts = groupByFirstLetter(list);

const cx = classNames.bind(styles);

export const Error = () => {
  const { isPhone } = useSelector(state => state.responsive);

  const image = isPhone ? MobileImage : DesktopImage;

  return (
    <div className={cx('Error')}>
      <img className={cx('Error__image')} src={image} alt="" />
      <Container className={cx('Error__content')}>
        <Heading level={2} className={cx('Error__title')}>
          Вы можете оформить заказ, позвонив по одному из номеров Вашего города
        </Heading>

        {/*<div className={cx('Error__mainCities')}>*/}
        {/*  {mainCities.map((city, index) => {*/}
        {/*    return (*/}
        {/*      <div className={cx('Error__city')} key={index}>*/}
        {/*        {Object.entries(city).map(([cityName, phones]) => {*/}
        {/*          return (*/}
        {/*            <CityValue*/}
        {/*              key={cityName}*/}
        {/*              cityName={cityName}*/}
        {/*              phones={phones}*/}
        {/*              cityNameClassName={cx('Error__mainCities_city')}*/}
        {/*              phoneClassName={cx('Error__mainCities_phone')}*/}
        {/*            />*/}
        {/*          );*/}
        {/*        })}*/}
        {/*      </div>*/}
        {/*    );*/}
        {/*  })}*/}
        {/*</div>*/}

        {/*<Heading level={3} className={cx('Error__other')}>*/}
        {/*  Другие города*/}
        {/*</Heading>*/}

        <ul className={cx('Error__list')}>
          {Object.entries(contacts).map(([letter, letterCities]) => {
            return <LetterGroup key={letter} letter={letter} letterCities={letterCities} />;
          })}
        </ul>
      </Container>
    </div>
  );
};

const LetterGroup = ({ letter, letterCities }) => {
  return (
    <li className={cx('Error__letterGroup')}>
      <span className={cx('Error__letterGroup-title')}>{letter}</span>
      <div className={cx('Error__letterGroup-list')}>
        {letterCities.map((city, index) => {
          return (
            <div className={cx('Error__city')} key={index}>
              {Object.entries(city).map(([cityName, phones]) => {
                return <CityValue key={cityName} cityName={cityName} phones={phones} />;
              })}
            </div>
          );
        })}
      </div>
    </li>
  );
};

const CityValue = ({ cityName, phones, cityNameClassName, phoneClassName }) => {
  return (
    <>
      <span className={cx('Error__city-name', cityNameClassName)}>{cityName}</span>
      {phones.length ? (
        phones.map(phone => {
          return (
            <a
              className={cx('Error__city-phone', phoneClassName)}
              key={phone}
              // href={`tel:${phone.replace(/[\(\)\-\s]/g, '')}`}
              href={`tel:${phone}`}
            >
              {phone}
            </a>
          );
        })
      ) : (
        <span className={cx('Error__city-phone', phoneClassName)} style={{ pointerEvents: 'none' }}>
          Телефон не указан
        </span>
      )}
    </>
  );
};
