export const LOGIN_SUCCESS = "@user/LOGIN_SUCCESS";

export const SIGNUP_REQUEST = "@user/SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "@user/SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "@user/SIGNUP_FAILURE";
export const SIGNUP_WITH_SMS_REQUEST = "@user/SIGNUP_WITH_SMS_REQUEST";
export const SIGNUP_WITH_SMS_SUCCESS = "@user/SIGNUP_WITH_SMS_SUCCESS";
export const SIGNUP_WITH_SMS_FAILURE = "@user/SIGNUP_WITH_SMS_FAILURE";
export const RESET_SIGNUP = "@user/RESET_SIGNUP";

export const SIGNIN_REQUEST = "@user/SIGNIN_REQUEST";
export const SIGNIN_SUCCESS = "@user/SIGNIN_SUCCESS";
export const SIGNIN_FAILURE = "@user/SIGNIN_FAILURE";
export const SIGNIN_WITH_SMS_REQUEST = "@user/SIGNIN_WITH_SMS_REQUEST";
export const SIGNIN_WITH_SMS_SUCCESS = "@user/SIGNIN_WITH_SMS_SUCCESS";
export const SIGNIN_WITH_SMS_FAILURE = "@user/SIGNIN_WITH_SMS_FAILURE";

export const SIGNOUT_REQUEST = "@user/SIGNOUT_REQUEST";
export const SIGNOUT_SUCCESS = "@user/SIGNOUT_SUCCESS";
export const SIGNOUT_FAILURE = "@user/SIGNOUT_FAILURE";

export const FETCH_PROFILE_REQUEST = "@user/FETCH_PROFILE_REQUEST";
export const FETCH_PROFILE_SUCCESS = "@user/FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILE_FAILURE = "@user/FETCH_PROFILE_FAILURE";

export const UPDATE_PROFILE_REQUEST = "@user/UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "@user/UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "@user/UPDATE_PROFILE_FAILURE";
export const UPDATE_PROFILE_RESET = "@user/UPDATE_PROFILE_RESET";

export const RECOVERY_PHONE_CONFIRM_SMS_REQUEST = "@user/RECOVERY_PHONE_CONFIRM_SMS_REQUEST";
export const RECOVERY_PHONE_CONFIRM_SMS_SUCCESS = "@user/RECOVERY_PHONE_CONFIRM_SMS_SUCCESS";
export const RECOVERY_PHONE_CONFIRM_SMS_FAILURE = "@user/RECOVERY_PHONE_CONFIRM_SMS_FAILURE";

export const RECOVERY_PASSWORD_SEND_CONFIRM_CODE_REQUEST =
  "@user/RECOVERY_PASSWORD_SEND_CONFIRM_CODE_REQUEST";
export const RECOVERY_PASSWORD_SEND_CONFIRM_CODE_SUCCESS =
  "@user/RECOVERY_PASSWORD_SEND_CONFIRM_CODE_SUCCESS";
export const RECOVERY_PASSWORD_SEND_CONFIRM_CODE_FAILURE =
  "@user/RECOVERY_PASSWORD_SEND_CONFIRM_CODE_FAILURE";
export const RECOVERY_PASSWORD_SEND_CONFIRM_CODE_RESET =
  "@user/RECOVERY_PASSWORD_SEND_CONFIRM_CODE_RESET";

export const RECOVERY_PASSWORD_REQUEST = "@user/RECOVERY_PASSWORD_REQUEST";
export const RECOVERY_PASSWORD_SUCCESS = "@user/RECOVERY_PASSWORD_SUCCESS";
export const RECOVERY_PASSWORD_FAILURE = "@user/RECOVERY_PASSWORD_FAILURE";
export const RECOVERY_PASSWORD_RESET = "@user/RECOVERY_PASSWORD_RESET";

export const CHANGE_PASSWORD_REQUEST = "@user/CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "@user/CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "@user/CHANGE_PASSWORD_FAILURE";

export const CHANGE_PHONE_REQUEST = "@user/CHANGE_PHONE_REQUEST";
export const CHANGE_PHONE_SUCCESS = "@user/CHANGE_PHONE_SUCCESS";
export const CHANGE_PHONE_FAILURE = "@user/CHANGE_PHONE_FAILURE";

export const RECOVERY_PASSWORD_REMEMBER_PHONE = "@user/RECOVERY_PASSWORD_REMEMBER_PHONE";
export const RECOVERY_PASSWORD_REMEMBER_EMAIL = "@user/RECOVERY_PASSWORD_REMEMBER_EMAIL";

export const SET_BONUSES = "@user/SET_BONUSES";

export const CLEAR_USER_ERRORS = "@user/CLEAR_USER_ERRORS";

export const CHANGE_BONUSES = "@user/CHANGE_BONUSES";
