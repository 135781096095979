import * as types from './actionTypes';

const initialState = {
  applePay: false,
  applePayDeliveryPrice: {
    delivery_cost: 0,
    isLoading: false,
    isLoaded: false,
    error: '',
  },
  applePayStatus: {
    cart: 0,
    checkout: 0,
    isLoading: false,
    isLoaded: false,
    error: '',
  },
  googlePay: {
    isAvailable: false,
    isLoading: false,
    isLoaded: false,
    error: '',
  },
};

export default function actionTypesReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_APPLE_PAY_DELIVERY_PRICE_REQUEST: {
      return {
        ...state,
        applePayDeliveryPrice: {
          isLoading: true,
          isLoaded: false,
          error: '',
        },
      };
    }
    case types.FETCH_APPLE_PAY_DELIVERY_PRICE_SUCCESS: {
      return {
        ...state,
        applePayDeliveryPrice: {
          ...action.payload,
          isLoading: false,
          isLoaded: true,
          error: '',
        },
      };
    }
    case types.FETCH_APPLE_PAY_DELIVERY_PRICE_FAILURE: {
      return {
        ...state,
        applePayDeliveryPrice: {
          isLoading: false,
          isLoaded: false,
          error: action.payload,
        },
      };
    }
    case types.FETCH_APPLE_PAY_STATUS_REQUEST: {
      return {
        ...state,
        applePayStatus: {
          ...initialState.applePayStatus,
          isLoading: true,
          isLoaded: false,
          error: '',
        },
      };
    }
    case types.FETCH_APPLE_PAY_STATUS_SUCCESS: {
      return {
        ...state,
        applePayStatus: {
          ...action.payload,
          isLoading: false,
          isLoaded: true,
          error: '',
        },
      };
    }
    case types.FETCH_APPLE_PAY_STATUS_FAILURE: {
      return {
        ...state,
        applePayStatus: {
          ...initialState.applePayStatus,
          isLoading: false,
          isLoaded: false,
          error: action.payload,
        },
      };
    }
    case types.INIT_APPLE_PAY: {
      return {
        ...state,
        ...action.data,
      };
    }
    case types.INIT_GOOGLE_PAY_REQUEST: {
      return {
        ...state,
        googlePay: {
          ...state.googlePay,
          isLoading: true,
          isLoaded: false,
          error: '',
        },
      };
    }
    case types.INIT_GOOGLE_PAY_SUCCESS: {
      return {
        ...state,
        googlePay: {
          ...state.googlePay,
          isAvailable: true,
          isLoading: false,
          isLoaded: true,
          error: '',
        },
      };
    }
    case types.INIT_GOOGLE_PAY_FAILURE: {
      return {
        ...state,
        googlePay: {
          ...state.googlePay,
          isAvailable: false,
          isLoading: false,
          isLoaded: false,
          error: action.payload,
        },
      };
    }

    default:
      return state;
  }
}
