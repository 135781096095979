// State
import { exportableStore as store } from '../state/store';

// Utils
import isomorphicCookies from './cookie/isomorphicCookies';
import Decimal from 'decimal.js';

const locale = isomorphicCookies().get('locale');
const naming =
  locale === 'ru'
    ? { flat: 'кв.', floor: 'этаж', porch: 'подъезд', intercom: 'домофон' }
    : { flat: 'flat', floor: 'floor', porch: 'porch', intercom: 'intercom' };

/**
 * Format numbers (prices etc)
 * @param {number|string} value: Unformatted number
 * @param {FormatNumbersOptions} options
 * @return {string} Formatted number string
 */
export const formatNumber = (value, digits = 0) => {
  if (value && !isNaN(value)) {
    const processedValue = typeof value === 'string' ? value?.replace(',', '') : value;
    const decimalValue = new Decimal(processedValue);
    const rounded = decimalValue.toFixed(digits);
    const parts = String(rounded).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    if (digits === 0) {
      return parts[0] !== 'NaN' ? parts[0] : '-';
    }
    return parts[1] && Number(parts[1]) === 0 ? parts[0] : parts.join('.');
  }
  return value;
};

// формируем address_in_line
export const formatMinAddress = address => {
  const { street_type, street, house, housing, housing_type, city_id } = address;
  const { userCity, list } = store.getState().city;

  // при нажатии на сохраненный адрес есть city_id и он не обязательно равен текущему городу
  const currentCityId = city_id || userCity.id;
  const currentCityName = list.find(city => city.id === currentCityId)?.name;

  const cityString = currentCityName ? `${currentCityName}, ` : '';
  const streetTypeString = street_type ? `${street_type} ` : '';
  const streetString = street ? `${street}` : '';
  const houseString = house ? `, ${house}` : '';
  const housingTypeString = housing_type ? `, ${housing_type} ` : '';
  const housingString = housing ? `${housing}` : '';

  return `${cityString}${streetTypeString}${streetString}${houseString}${housingTypeString}${housingString}`;
};

// формируем полный адрес
export const formatMaxAddress = address => {
  const { address_in_line, flat, floor, porch, intercom } = address;

  const flatString = flat ? `, ${naming.flat} ${flat}` : '';
  const floorString = floor ? `, ${naming.floor} ${floor}` : '';
  const porchString = porch ? `, ${naming.porch} ${porch}` : '';
  const intercomString = intercom ? `, ${naming.intercom} ${intercom}` : '';

  return `${address_in_line}${flatString}${floorString}${porchString}${intercomString}`;
};

// ул. Кравченко, 8 с. 6 к. 1'
// нужен, чтобы отображать список сохраненных адресов
export const formatSavedAddress = address => {
  return formatMaxAddress({ ...address, address_in_line: formatMinAddress(address) });
};

// превращаем байты в килобайты
export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

//трансформируем объект в массив
export const reduceObjectOnArray = (object) => {
  if (!object) {
    return null;
  } else if (object && typeof object === 'object') {
    let array = [];
    for (let key in object) {
      array.push(object[key]);
    }
    return array;
  }

  return object;
};
