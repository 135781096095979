import { get, post, put } from '../../../utils/apiChat';

export default {
  getChats: () => get({ url: 'support/messages' }),
  sendMessage: (message, attachment) =>
    post({
      url: 'support/messages',
      body: message,
      options: attachment ? { attachment_links: [attachment] } : {},
    }),
  resetUnreadedMessages: () => put({ url: 'support/read-all', body: null }),
  saveImage: image => post({ url: 'support/upload', body: { ...image }, type: 'form-data' }),
};
