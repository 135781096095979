// Utils
import { getAccessToken } from './auth';
import isomorphicCookies from './cookie/isomorphicCookies';

// Config
import config from '../config';

const request = params => {
  const { method = 'GET', url, body, type, options = {} } = params;

  const access_token = getAccessToken();
  const city_id = isomorphicCookies().get('city_id');

  const newHeaders = {
    Accept: 'application/json',
    Authorization: `Bearer ${access_token}`,
  };

  if (type !== 'form-data') {
    newHeaders['Content-Type'] = 'application/json';
  }

  const isGetOrDelete = ['GET', 'DELETE'].includes(method);

  let newData = {
    city_id: Number(city_id),
    text: body,
    ...options,
  };

  // let newBody;

  if (type === 'form-data') {
    const form_data = new FormData();

    Object.keys(body).forEach(key => {
      if (Array.isArray(body[key])) {
        return form_data.append(key, JSON.stringify(body[key]));
      }

      return form_data.append(key, body[key]);
    });

    newData = form_data;
  } else if (isGetOrDelete) {
    newData = undefined;
  } else {
    newData = JSON.stringify(newData);
  }

  const newUrl = config.messageApiUrl + url;

  return fetch(newUrl, {
    method,
    headers: newHeaders,
    body: newData,
  }).then(response => {
    return response.json().then(json => {
      if (response.ok) {
        return json;
      }

      return Promise.reject({ ...json, status: response.status, statusText: response.statusText });
    });
  });
};

export const get = params => request({ method: 'GET', ...params });
export const post = params => request({ method: 'POST', ...params });
export const put = params => request({ method: 'PUT', ...params });
