import React, { useEffect, useRef, useCallback } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Transition } from 'react-transition-group';
import _debounce from 'lodash/debounce';

// Utils
import { disableBodyScroll, clearAllBodyScrollLocks } from '../../utils/body-scroll-lock';

// Styles
import styles from './Modal.styl';

const cx = classNames.bind(styles);

const modalRoot = typeof window !== 'undefined' ? document.getElementById('modal-root') : null;

export default function Modal(props) {
  const { className, children, isOpen, onClose, changeFormHeight, classNameModal } = props;

  const isHandleScrollTo = useRef(false);
  const overlayRef = useRef(null);
  const contentRef = useRef(null);
  const emptyRef = useRef(null);
  const prevHeight = useRef(null);

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
      vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }, []);

  const handleScrollTo = () => {
    overlayRef.current.scrollTo(0, contentRef.current.offsetTop - 20);
    isHandleScrollTo.current = true;
  };

  const handleScrollToDebounced = useCallback(
    _debounce(e => handleScrollTo(e), 150),
    [],
  );

  const handleScroll = () => {
    if (isHandleScrollTo.current) {
      isHandleScrollTo.current = false;

      return;
    }

    const contentRect = contentRef.current.getBoundingClientRect();
    // начинаем расчеты только когда видно верхнюю границу контента
    if (contentRect.y >= 0) {
      const emptyRect = emptyRef.current.getBoundingClientRect();
      if (
        emptyRect.y > -20 &&
        emptyRect.height === prevHeight.current &&
        prevHeight.current !== null
      ) {
        onClose();
        handleScrollToDebounced.cancel();
      } else {
        handleScrollToDebounced();
      }

      prevHeight.current = emptyRect.height;
    }
  };

  useEffect(() => {
    if (contentRef.current !== null) {
      handleScrollTo();
    }
  }, [changeFormHeight]);

  const handleScrollDebounced = useCallback(
    _debounce(e => handleScroll(e), 10),
    [],
  );

  useEffect(() => {
    if (isOpen) {
      disableBodyScroll(overlayRef.current);
      handleScrollTo();
      overlayRef?.current?.addEventListener('scroll', handleScrollDebounced, { passive: true });
      const emptyRect = emptyRef.current.getBoundingClientRect();
      prevHeight.current = emptyRect.height;
    }

    return () => {
      if (isOpen) {
        clearAllBodyScrollLocks();
        overlayRef?.current?.removeEventListener('scroll', handleScrollDebounced);
      }
    };
  }, [isOpen]);

  return (
    modalRoot &&
    createPortal(
      <Transition in={isOpen} timeout={{ enter: 0, exit: 700 }} mountOnEnter unmountOnExit>
        {state => (
          <>
            <div className={cx('ModalPhone__overlay', `ModalPhone__overlay_${state}`)} />
            <div className={cx('ModalPhone', `ModalPhone_${state}`, classNameModal)} ref={overlayRef} id="modal">
              <button
                className={cx('ModalPhone__empty')}
                onClick={onClose}
                type="button"
                ref={emptyRef}
              />
              <div className={cx('ModalPhone__content', className)} ref={contentRef}>
                {children}
                <div className={cx('ModalPhone__padding')} />
              </div>
            </div>
          </>
        )}
      </Transition>,
      modalRoot,
    )
  );
}

Modal.defaultProps = {
  className: '',
  isOpen: false,
  onClose: () => null,
  children: null,
  changeFormHeight: null,
};

Modal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.any,
  changeFormHeight: PropTypes.any,
};
