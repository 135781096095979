import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Styles
import styles from './ToggleArrow.styl';

const cx = classNames.bind(styles);

export default function ToggleArrow(props) {
  const { className, isOpen } = props;

  return (
    <div className={cx('ToggleArrow', className, { ToggleArrow_opened: isOpen })} data-test-id='toggle_arrow'>
      <div className={cx('ToggleArrow__triangle')} />
    </div>
  );
}

ToggleArrow.defaultProps = {
  className: '',
  isOpen: false,
};

ToggleArrow.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
};
