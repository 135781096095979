import { withUnauthorizedToken } from '../../../utils/auth';
import { get, post, del } from '../../../utils/api';
import { preSaveFormatComposition } from './utils';

export default {
  getCart: params => get({ url: 'cart/get', data: withUnauthorizedToken(params) }),
  delCartClear: () => del({ url: 'cart/clear', data: withUnauthorizedToken() }),
  postCartAdd: (params, options = {}) =>
    post({
      url: 'cart/add',
      data: withUnauthorizedToken({
        composition: preSaveFormatComposition(params),
        ...options,
      }),
    }),
  postCartUpdate: (params, options = {}) =>
    post({
      url: 'cart/update',
      data: withUnauthorizedToken({
        composition:
          params.type === 'combo'
            ? [{ type: 'combo', count: params.count, hash: params.hash }]
            : preSaveFormatComposition([params]),
        ...options,
      }),
    }),
  delCartRemove: (hash, options = {}) => {
    // Может передаваться строка (хеш) а может массив хешей
    const args =
      typeof hash === 'string'
        ? { data: withUnauthorizedToken({ hash, ...options }) }
        : { data: withUnauthorizedToken({ ...options }), body: { hash } };

    return del({ url: 'cart/remove', ...args });
  },
  postCartchangeCartSailplayDiscount: (papabonus_charge, delivery_area_id) =>
    post({ url: 'cart/add-sailplay-discount', data: withUnauthorizedToken({ papabonus_charge, delivery_area_id }) }),
};
