const prefix = '@chat';

export const FETCH_CHATS_REQUEST = `${prefix}/FETCH_CHATS_REQUEST`;
export const FETCH_CHATS_SUCCESS = `${prefix}/FETCH_CHATS_SUCCESS`;
export const FETCH_CHATS_FAILURE = `${prefix}/FETCH_CHATS_FAILURE`;

export const SET_ACTIVE_CHAT_SUCCESS = `${prefix}/SET_ACTIVE_CHAT_SUCCESS`;
export const SET_ACTIVE_CHAT_FAILURE = `${prefix}/SET_ACTIVE_CHAT_FAILURE`;
export const CLOSE_ACTIVE_CHAT = `${prefix}/CLOSE_ACTIVE_CHAT`;

export const SEND_MESSAGE_REQUEST = `${prefix}/SEND MESSAGE_REQUEST`;
export const SEND_MESSAGE_FAILURE = `${prefix}/SEND MESSAGE_FAILURE`;
export const SEND_MESSAGE_SUCCESS = `${prefix}/SEND_MESSAGE_SUCCESS`;
export const GET_LIA_RESPONSE = `${prefix}/GET_LIA_RESPONSE`;
export const SET_NEW_MESSAGE = `${prefix}/SET_NEW_MESSAGE`;

export const SET_UNREADED_MESSAGE = `${prefix}/SET_UNREADED_MESSAGE`;
export const RESET_UNREADED_MESSAGES_REQUEST = `${prefix}/RESET_UNREADED_MESSAGES_REQUEST`;
export const RESET_UNREADED_MESSAGES_SUCCESS = `${prefix}/RESET_UNREADED_MESSAGES_SUCCESS`;
export const RESET_UNREADED_MESSAGES_FAILURE = `${prefix}/RESET_UNREADED_MESSAGES_FAILURE`;

export const SAVE_IMAGE_REQUEST = `${prefix}/SAVE_IMAGE_REQUEST`;
export const SAVE_IMAGE_SUCCESS = `${prefix}/SAVE_IMAGE_SUCCESS`;
export const SAVE_IMAGE_FAILURE = `${prefix}/SAVE_IMAGE_FAILURE`;
export const DELETE_IMAGE = `${prefix}/DELETE_IMAGE`;

export const SET_ISSUE_ID = `${prefix}/SET_ISSUE_ID`;

export const RESET_CHAT_STATE = `${prefix}/RESET_CHAT_STATE`;

export const CHANGE_LOADING_LIA_STATUS = `${prefix}/CHANGE_LOADING_LIA_STATUS`;
