import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './GiftSlide.styl';
import Heading from '../Heading';
import Button from '../Button';
import PlusIcon from '../../icons/plus.svg';

const cx = classNames.bind(styles);

const GiftSlide = ({ gift, withoutButton, onAddToCart }) => {
  return (
    <div className={cx('GiftSlide')}>
      <div className={cx('GiftSlide__slide-image')}>
        <img src={gift.image_url} alt={gift.name} />
      </div>
      <div className={cx('GiftSlide__slide-group')}>
        <div className={cx('GiftSlide__slide-group-head')}>
          <Heading className={cx('GiftSlide__slide-name')} level={4} medium>
            {gift.name}
          </Heading>
          {gift?.description && <p className={cx('GiftSlide__slide-text')}>{gift?.description}</p>}
        </div>
        <div className={cx('GiftSlide__slide-footer')}>
          <p className={cx('GiftSlide__slide-price')}>
            {gift.price.discount_value} ₽
            {gift.price.discount_value < gift.price.value && (
              <span className={cx('GiftSlide__slide-old-price')}>( {gift.price.value} ₽)</span>
            )}
          </p>

          {!withoutButton && (
            <Button
              className={cx('GiftSlide__slide-button')}
              onClick={() => onAddToCart(gift.good_id)}
              // isLoading={isLoading.includes(gift.good_id)}
            >
              <PlusIcon className={cx('GiftSlide__slide-plus')} />
            </Button>
          )}
        </div>
        {gift.points !== 0 && (
          <div className={cx('GiftSlide__slide-points')}>+ {gift.points} Б</div>
        )}
        {gift.price.discount_percent !== 0 && (
          <div className={cx('GiftSlide__slide-percent')}>{gift.price.discount_percent}%</div>
        )}
      </div>
    </div>
  );
};
export default GiftSlide;

GiftSlide.defaultProps = {
  gift: {},
  withoutButton: false,
  onAddToCart: () => {},
};

GiftSlide.propTypes = {
  gift: PropTypes.object,
  withoutButton: PropTypes.bool,
  onAddToCart: PropTypes.func,
};
