import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Styles
import styles from './Lang.styl';

const cx = classNames.bind(styles);

export default function Lang(props) {
  const { isActive, locale, onLocaleClick } = props;

  const handleOnClick = () => {
    onLocaleClick(locale);
  };

  return (
    <button className={cx('Lang', { Lang_active: isActive })} onClick={handleOnClick} type="button">
      {locale}
    </button>
  );
}

Lang.defaultProps = {
  isActive: false,
};

Lang.propTypes = {
  isActive: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  onLocaleClick: PropTypes.func.isRequired,
};
