import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// State
import { isDiscountCashbackSystemSelector } from '../../../state/modules/city/selectors';

// Components
import Award from '../../Award';

// Assets
import avatarPlaceholder from './assets/avatar-placeholder.svg';
import coverGold from './assets/cover_gold.png';
import coverPlatinum from './assets/cover_platinum.png';
import coverSilver from './assets/cover_silver.png';

// Styles
import styles from './Avatar.styl';

const cx = classNames.bind(styles);

const covers = {
  silver: coverSilver,
  gold: coverGold,
  platinum: coverPlatinum,
};

export default function Avatar(props) {
  const { className, status, avatar } = props;

  return (
    <div
      className={cx('Avatar', className)}
      style={{ backgroundImage: `url(${covers[status] || covers.silver})` }}
    >
      <div
        className={cx('Avatar__person')}
        style={{ backgroundImage: `url(${avatar || avatarPlaceholder})` }}
      />
    </div>
  );
}

Avatar.defaultProps = {
  className: '',
  avatar: '',
  status: 'silver',
};

Avatar.propTypes = {
  className: PropTypes.string,
  avatar: PropTypes.string,
  status: PropTypes.string,
};
