const prefix = '@cart';

export const UPDATE_CART_ITEMS = `${prefix}/UPDATE_CART_ITEMS`;
export const ADD_CART_ITEM_REQUEST = `${prefix}/ADD_CART_ITEM_REQUEST`;
export const ADD_CART_ITEM_SUCCESS = `${prefix}/ADD_CART_ITEM_SUCCESS`;
export const ADD_CART_ITEM_FAILURE = `${prefix}/ADD_CART_ITEM_FAILURE`;
export const ADD_CART_ITEM_FROM_UPSALE = `${prefix}/ADD_CART_ITEM_FROM_UPSALE`;
export const ADD_CART_ITEM_FROM_HISTORY = `${prefix}/ADD_CART_ITEM_FROM_HISTORY`;

export const FETCH_ITEMS_REQUEST = `${prefix}/FETCH_ITEMS_REQUEST`;
export const FETCH_ITEMS_SUCCESS = `${prefix}/FETCH_ITEMS_SUCCESS`;
export const FETCH_ITEMS_FAILURE = `${prefix}/FETCH_ITEMS_FAILURE`;

export const CLEAR_REQUEST = `${prefix}/CLEAR_REQUEST`;
export const CLEAR_SUCCESS = `${prefix}/CLEAR_SUCCESS`;
export const CLEAR_FAILURE = `${prefix}/CLEAR_FAILURE`;

export const UPDATE_CART_ITEM_REQUEST = `${prefix}/UPDATE_CART_ITEM_REQUEST`;
export const UPDATE_CART_ITEM_SUCCESS = `${prefix}/UPDATE_CART_ITEM_SUCCESS`;
export const UPDATE_CART_ITEM_FAILURE = `${prefix}/UPDATE_CART_ITEM_FAILURE`;

export const REMOVE_CART_ITEM_REQUEST = `${prefix}/REMOVE_CART_ITEM_REQUEST`;
export const REMOVE_CART_ITEM_SUCCESS = `${prefix}/REMOVE_CART_ITEM_SUCCESS`;
export const REMOVE_CART_ITEM_FAILURE = `${prefix}/REMOVE_CART_ITEM_FAILURE`;

export const CHANGE_CART_SAILPLAY_DISCOUNT_REQUEST = `${prefix}/CHANGE_CART_SAILPLAY_DISCOUNT_REQUEST`;
export const CHANGE_CART_SAILPLAY_DISCOUNT_SUCCESS = `${prefix}/CHANGE_CART_SAILPLAY_DISCOUNT_SUCCESS`;
export const CHANGE_CART_SAILPLAY_DISCOUNT_FAILURE = `${prefix}/CHANGE_CART_SAILPLAY_DISCOUNT_FAILURE`;

export const RESET_CART_STATE = `${prefix}/RESET_CART_STATE`;
