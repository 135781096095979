// API Version 1
const isItNew2 = process.env.NEW2 === 'true';

const allowedCardNetworks = ['MASTERCARD', 'VISA'];
const allowedPaymentMethods = ['CARD', 'TOKENIZED_CARD'];
const paymentConfig = { environment: isItNew2 ? 'PRODUCTION' : 'TEST' };
const isReadyToPayRequest = { allowedPaymentMethods };

const loadScript = () => {
  return new Promise((resolve, reject) => {
    const sqPaymentScript = document.createElement('script');
    sqPaymentScript.src = 'https://payments.developers.google.com/js/apis/pay.js';
    sqPaymentScript.type = 'text/javascript';
    sqPaymentScript.async = true;

    sqPaymentScript.onload = () => {
      try {
        window.googlePaymentsClient = new window.google.payments.api.PaymentsClient(paymentConfig);
        resolve();
      } catch (err) {
        return Promise.reject(err);
      }
    };

    sqPaymentScript.error = err => {
      reject(err);
    };

    document.getElementsByTagName('head')[0].appendChild(sqPaymentScript);
  });
};

const readyToPayRequest = () => {
  try {
    return window.googlePaymentsClient.isReadyToPay(isReadyToPayRequest);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getButton = (onClick, isPhone) => {
  return window.googlePaymentsClient.createButton({
    onClick,
    buttonColor: 'black',
    buttonType: isPhone ? 'short' : 'long',
  });
};

const payment = ({ totalPrice, currencyCode, gatewayMerchantId, gateway }) => {
  const paymentDataRequest = {
    merchantInfo: {
      merchantName: 'merchant.ru.papajohns',
    },
    merchantId: '05575206923977080751',
    paymentMethodTokenizationParameters: {
      tokenizationType: 'PAYMENT_GATEWAY',
      parameters: {
        gateway,
        gatewayMerchantId,
      },
    },
    allowedPaymentMethods,
    cardRequirements: {
      allowedCardNetworks,
    },
  };

  paymentDataRequest.transactionInfo = {
    totalPriceStatus: 'FINAL',
    totalPrice: totalPrice.toString(),
    currencyCode,
  };
  return window.googlePaymentsClient.loadPaymentData(paymentDataRequest);
};

export default {
  loadScript,
  readyToPayRequest,
  payment,
  getButton,
};
