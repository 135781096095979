import * as types from './actionTypes';

const initialState = {
  settings: {},
  sendingData: {
    isSending: false,
    isSent: false,
    error: '',
  },
  isLoading: false,
  isLoaded: false,
  error: '',
};

export default function feedbackDataReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_DATA_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        error: '',
      };
    }

    case types.FETCH_DATA_SUCCESS:
      return {
        ...state,
        settings: action.payload,
        isLoading: false,
        isLoaded: true,
        error: '',
      };

    case types.FETCH_DATA_FAILURE:
      return {
        ...state,
        settings: {},
        isLoading: false,
        isLoaded: false,
        error: action.payload,
      };

    //

    case types.SEND_DATA_REQUEST: {
      return {
        ...state,
        sendingData: {
          isSending: true,
          isSent: false,
          error: '',
        },
      };
    }

    case types.SEND_DATA_SUCCESS:
      return {
        ...state,
        sendingData: {
          isSending: false,
          isSent: true,
          error: '',
        },
      };

    case types.SEND_DATA_FAILURE:
      return {
        ...state,
        sendingData: {
          isSending: false,
          isSent: false,
          error: action.payload,
        },
      };

    case types.RESET_FEEDBACK:
      return {
        ...state,
        sendingData: initialState.sendingData,
      };

    default:
      return state;
  }
}
