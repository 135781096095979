import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Styles
import styles from './AppLink.styl';

const cx = classNames.bind(styles);

export default function AppLink(props) {
  const { className, children, to, href, external, ...otherProps } = props;

  if (href) {
    return (
      <a {...otherProps} className={cx('AppLink', className)} href={href}>
        {children}
      </a>
    );
  }

  if (external) {
    return (
      <a
        {...otherProps}
        className={cx('AppLink', className)}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  }

  return (
    <Link {...otherProps} className={cx('AppLink', className)} to={to}>
      {children}
    </Link>
  );
}

AppLink.defaultProps = {
  className: '',
  external: false,
};

AppLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  href: PropTypes.string,
  to: PropTypes.string,
  external: PropTypes.bool,
};
