/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Styles
import styles from './InputField.styl';

const cx = classNames.bind(styles);

export default function InputField(props) {
  const {
    className,
    label,
    isNumberInput,
    isError,
    //
    inputClassname,
    name,
    required,
    disabled,
    _ref,
    register,
    autoComplete,
    textBlockInput,
    ...otherProps
  } = props;

  const inputProps = {
    ...otherProps,
    className: cx('InputField__field', inputClassname),
    name,
    disabled,
    required,
    ref: _ref || register,
    id: name,
  };

  const [blockState, setBlockState] = useState(true);

  const onNumberChange = e => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      inputProps.onChange(e);
    }
  };

  return (
    <div
      className={cx('InputField', className, {
        InputField_disabled: disabled,
        InputField_error: isError,
      })}
    >
      {Boolean(label) && (
        <label className={cx('InputField__label')} htmlFor={name}>
          {label} {required && '*'}
        </label>
      )}
      {
        (textBlockInput && blockState)
          ?
          <div {...inputProps} className={cx('InputField__textBlock', inputProps.className)} autoComplete={autoComplete}
               onChange={isNumberInput ? onNumberChange : inputProps.onChange}
               onClick={() => setBlockState(false)}
          >
            <div className={cx('InputField__textInsideBlock', {'InputField__textInsideBlock_placeholder': !inputProps.value})}>
              <p>{inputProps.value || inputProps.placeholder}</p></div>
          </div>
          : <input {...inputProps} autoComplete={autoComplete}
                   onChange={isNumberInput ? onNumberChange : inputProps.onChange}
                   onBlur={() => setBlockState(true)}
                   autoFocus={textBlockInput && true}
          />
      }
    </div>
  );
}

InputField.defaultProps = {
  className: '',
  inputClassname: '',
  type: 'text',
  required: false,
  label: '',
  name: undefined, // НИ В КОЕМ СЛУЧАЕ НЕ ПРОКИДЫВАТЬ, ИНАЧЕ ОТВАЛИТСЯ CLOUDPAYMENTS
  disabled: false,
  _ref: undefined,
  register: undefined, // для react-hook-form
  isNumberInput: false,
  isError: false,
  autoComplete: '',
  textBlockInput: false,
};

InputField.propTypes = {
  className: PropTypes.string,
  inputClassname: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  _ref: PropTypes.object,
  disabled: PropTypes.bool,
  register: PropTypes.func,
  isNumberInput: PropTypes.bool,
  isError: PropTypes.bool,
  autoComplete: PropTypes.string,
  textBlockInput: PropTypes.bool,
};
