import { createSelector } from 'reselect';

export const getConditionsSelector = state => state.stock.data.details?.conditions || [];
export const getRewardsSelector = state => state.stock.data.details?.rewards || [];
export const getStockConditionGoodsSelector = state => {
  const conditions = getConditionsSelector(state);

  return conditions
    .filter(condition => condition.goods)
    .reduce((goods, item) => goods.concat(item.goods), []);
};

export const stockSelector = createSelector(
  [state => state.stock.list.data, (_, alias) => alias],
  (list, alias) => list.find(item => item.alias === alias) || {},
);

export const getCheckedConditionsSelector = createSelector(getConditionsSelector, conditions => {
  return conditions.filter(condition => condition.check);
});

export const getProgressSelector = createSelector(
  getConditionsSelector,
  getCheckedConditionsSelector,
  (conditions, checkedConditions) => {
    return checkedConditions.length / conditions.length || 0;
  },
);

export const getIsAllConditionsCheckedSelector = createSelector(getConditionsSelector, conditions =>
  conditions.every(cond => cond.check),
);
