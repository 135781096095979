import cardApi from './api';
import * as types from './actionTypes';

export const fetchCards = () => {
  return dispatch => {
    dispatch({ type: types.FETCH_LIST_REQUEST });
    return cardApi
      .getCards()
      .then(payload => {
        return dispatch({ type: types.FETCH_LIST_SUCCESS, payload });
      })
      .catch(({ message }) => {
        return dispatch({ type: types.FETCH_LIST_FAILURE, payload: message });
      });
  };
};

export const fetchCardsIsNeeded = () => {
  return (dispatch, getState) => {
    const {
      card: { isLoading, isLoaded },
      user: { isAuthenticated },
    } = getState();

    if (isAuthenticated && !isLoading && !isLoaded) {
      return dispatch(fetchCards());
    }

    return false;
  };
};

export const setCardId = cardId => ({ type: types.SET_CARD_ID, payload: cardId });
export const setCryptogram = cryptogram => ({ type: types.SET_CRYPTOGRAM, payload: cryptogram });
export const setCardFields = fields => ({ type: types.SET_CARD_FIELDS, payload: fields });
export const resetCardFields = () => ({ type: types.RESET_CARD_FIELDS });
export const resetCardState = () => ({ type: types.RESET_CARD_STATE });
