import * as immutable from "object-path-immutable";
import * as types from "./actionTypes";
import { FETCH_CATALOG_NOT_FOUND_SLIDER_SUCCESS } from "./actionTypes";

const getIndexOfGoodToUpdate = (list, id) => {
  return list.findIndex(good => good.id === id);
};

const initialState = {
  products: {
    categoriesAliases: {},
    categories: [],
    list: [],
    isLoading: false,
    isLoaded: false
  },
  ingredients: {
    list: [],
    isLoading: false,
    isLoaded: false,
    hasError: false
  },
  pizzaFilter: {
    activeIngredients: {},
    isLoading: false,
    isLoaded: false,
    hasError: false
  },
  upsale: null,
  sliderNotFound: [],
  football_goods: [],
};

export default function catalogReducer(state = initialState, action) {
  switch (action.type) {
    case types.REMOVE_INGREDIENT: {
      const indexOfGoodToUpdate = getIndexOfGoodToUpdate(state.products.list, action.payload.id);
      const indexOfVariationToUpdate = state.products.list[
        indexOfGoodToUpdate
        ].variations.findIndex(variation => variation.id === action.payload.variationId);

      return immutable.update(
        state,
        [
          "products",
          "list",
          indexOfGoodToUpdate,
          "variations",
          indexOfVariationToUpdate,
          "include_ingredients"
        ],
        ingredients => {
          const indexOfIngredientToUpdate = ingredients.findIndex(
            ingredient => ingredient.id === action.payload.ingredientId
          );
          return immutable.update(ingredients, [indexOfIngredientToUpdate], ingredient =>
            immutable.set(ingredient, "removed", !ingredient.removed)
          );
        }
      );
    }

    case types.CANCEL_REMOVED_INGREDIENTS: {
      const indexOfGoodToUpdate = getIndexOfGoodToUpdate(state.products.list, action.payload.id);
      const indexOfVariationToUpdate = state.products.list[
        indexOfGoodToUpdate
        ].variations.findIndex(variation => variation.id === action.payload.variationId);

      return immutable.update(
        state,
        [
          "products",
          "list",
          indexOfGoodToUpdate,
          "variations",
          indexOfVariationToUpdate,
          "include_ingredients"
        ],
        ingredients =>
          (ingredients || []).map(ingredient => immutable.set(ingredient, "removed", false))
      );
    }

    case types.FETCH_CATALOG_GOODS_REQUEST:
      return immutable.assign(state, "products", {
        isLoading: true,
        isLoaded: false
      });

    case types.FETCH_CATALOG_GOODS_SUCCESS: {
      let list = [];
      const categoriesAliases = {};

      const categories = action.payload.map(category => {
        const { goods, ...otherProps } = category;

        list = [...list, ...goods];
        categoriesAliases[otherProps.alias] = otherProps.name;

        return otherProps;
      });

      return immutable.assign(state, "products", {
        isLoading: false,
        isLoaded: true,
        list,
        categoriesAliases,
        categories
      });
    }

    case types.FETCH_CATALOG_GOODS_FAILURE:
      return immutable.assign(state, "products", {
        isLoading: false,
        isLoaded: false
      });

    case types.FETCH_CATALOG_ACTIVE_INGREDIENTS_REQUEST:
      return immutable.assign(state, "pizzaFilter", {
        isLoading: true,
        isLoaded: false,
        hasError: false
      });

    case types.FETCH_CATALOG_ACTIVE_INGREDIENTS_SUCCESS:
      return immutable.assign(state, "pizzaFilter", {
        isLoading: false,
        isLoaded: true,
        activeIngredients: action.payload
      });

    case types.FETCH_CATALOG_ACTIVE_INGREDIENTS_FAILURE:
      return immutable.assign(state, "pizzaFilter", {
        isLoading: false,
        isLoaded: false,
        hasError: true
      });

    case types.FETCH_CATALOG_UPSALE_SUCCESS: {
      return {
        ...state,
        upsale: action.payload
      };
    }

    case types.FETCH_CATALOG_NOT_FOUND_SLIDER_SUCCESS: {
      return {
        ...state,
        sliderNotFound: action.payload
      };
    }

    case types.SET_FOOTBALL_GOODS: {
      return {
        ...state,
        football_goods: action.payload
      };
    }

    default:
      return state;
  }
}
