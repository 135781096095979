import { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';

// State
import { mediaChanged } from '../state/modules/responsive/actions';

// Utils
import { difference } from './helpers';

// Styles
import breakpoints from '../styles/config/breakpoints.json';

export default function useMediaQueryMatch() {
  const dispatch = useDispatch();
  const prevRef = useRef({});

  useEffect(() => {
    const handleMediaMatch = () => {
      const mediaQueries = {
        isTablet: `(max-width: ${breakpoints.tablet}px), (orientation: landscape) and (width: ${breakpoints.iPadPro}px) and (height: ${breakpoints.iPadProVertical}px)`,
        isPhone: `(max-width: ${breakpoints.phone}px), (orientation: landscape) and (max-height: ${breakpoints.verticalPhone}px)`,
      };

      Object.keys(mediaQueries).forEach(key => {
        mediaQueries[key] = window.matchMedia(mediaQueries[key]).matches;
      });

      if (!_isEmpty(difference(mediaQueries, prevRef.current))) {
        dispatch(mediaChanged({ ...mediaQueries }));
        prevRef.current = mediaQueries;
      }
    };

    handleMediaMatch();
    window.addEventListener('resize', handleMediaMatch, { passive: true });

    return () => {
      window.removeEventListener('resize', handleMediaMatch);
    };
  }, []);
}
