import * as types from './actionTypes';

const initialState = {
  className: '',
  isOpen: false,
  onConfirm: f => f,
  btnConfirmText: '',
  onCancel: f => f,
  btnCancelText: '',
  message: '',
  container: null,
};

export default function modalReducer(state = initialState, action) {
  switch (action.type) {
    case types.SHOW_MODAL_CONFIRM: {
      return {
        ...state,
        isOpen: true,
        onConfirm: action.payload.onConfirm,
        btnConfirmText: action.payload.btnConfirmText,
        onCancel: action.payload.onCancel,
        btnCancelText: action.payload.btnCancelText,
        message: action.payload.message,
        container: action.payload.container,
        className: action.payload.className,
      };
    }

    case types.HIDE_MODAL_CONFIRM: {
      return {
        ...state,
        isOpen: false,
      };
    }

    default:
      return state;
  }
}
