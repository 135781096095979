import _pick from 'lodash/pick';
import * as types from './actionTypes';
import {
  FETCH_CITY_MAP_COORDINATES_REQUEST,
  FETCH_CITY_MAP_COORDINATES_SUCCESS,
  FETCH_CURRENT_GEO_POSITION_SUCCESS
} from './actionTypes';

const initialState = {
  searchQuery: '',
  list: [],
  userCity: {},
  cityMapCoordinates: [],
  currentGeoPosition: '',
  waitOpenings: false,
};

export default function cityReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_CITIES_SUCCESS: {
      return {
        ...state,
        list: action.payload,
      };
    }

    case types.FETCH_USER_CITY_SUCCESS:
      return {
        ...state,
        userCity: action.payload,
      };

    case types.SET_USER_CITY: {
      // а другие поля нам и не нужны в стейте
      const list = state.list.map(city => _pick(city, ['id', '_supertag', 'name', 'parent_id']));
      const data = state.list.find(city => city.id === action.payload);

      return {
        ...state,
        list,
        userCity: data,
      };
    }

    case types.SEARCH_CITIES:
      return {
        ...state,
        searchQuery: action.payload,
      };

    case types.FETCH_CURRENT_GEO_POSITION_SUCCESS:
      return {
        ...state,
        currentGeoPosition: action.payload,
      };

    case types.SET_WAIT_OPENINGS_CITY:
      return {
        ...state,
        waitOpenings: true,
      };

    default:
      return state;
  }
}
