import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// State
import { cancelRemovedIngredients, removeIngredient } from '../../../state/modules/catalog/actions';
import { addCartItem } from '../../../state/modules/cart/actions';
import { showFreeSaucesPanel } from '../../../state/modules/freeSauces/actions';
import { fetchGiftsIsNeeded } from '../../../state/modules/gifts/actions';
import { sortByPriceDownToUp, sortByPriceUpToDown } from './helpers';

const SORTING_CONSTANTS = {
  upToDown: 'upToDown',
  downToUp: 'downToUp',
  'none': '',
};
const sortingOptions = [SORTING_CONSTANTS.none, SORTING_CONSTANTS.upToDown, SORTING_CONSTANTS.downToUp];

export default function useProductList(category) {
  const shownCategories = category?.filter(item => item?.is_hidden === 0);

  const dispatch = useDispatch();
  const goods = useSelector(state => state.catalog.products.list);
  const sailplayGifts = useSelector(state => state.gifts.list);

  const [filteredStateGood, setFilteredStateGood] = useState([]);
  const [filteredCategory, setFilteredCategory] = useState([]);
  const [filteredIds, setFilteredIds] = useState([]);
  const [sorting, setSorting] = useState(sortingOptions[0]);

  useEffect(() => {
    dispatch(fetchGiftsIsNeeded());
  }, []);

  const getGoodsWithoutDublicates = () => {
    if (filteredIds.length) {
      filteredGoods(filteredIds);
    } else {
      setFilteredCategory(shownCategories.map(elem => elem.alias));
      setFilteredStateGood(goods);
    }
  };

  const filteredGoods = (id) => {

    const filter = goods.filter(item => {
      if (id.includes(item.id)) {
        return item;
      }
    });

    let sortedGoods = [...filter];

    if (!!sorting) {
      if (sorting === SORTING_CONSTANTS.upToDown) {
        sortedGoods = sortByPriceUpToDown(filter);
      }
      if (sorting === SORTING_CONSTANTS.downToUp) {
        sortedGoods = sortByPriceDownToUp(filter);
      }
    }

    const allCategories = [...new Set(goods.map((elem) => elem.category))];
    const category = [...new Set(filter.map((elem) => elem.category))];
    category.sort((x, y) => {
      const xIndex = allCategories.indexOf(x);
      const yIndex = allCategories.indexOf(y);
      return xIndex - yIndex;
    });

    setFilteredCategory(category);
    setFilteredStateGood(sortedGoods);
    setFilteredIds(id);
  };

  const removeIngredientAction = useCallback(({ id, variation, ingredientId }) => {
    return dispatch(removeIngredient(id, variation.id, ingredientId));
  }, []);

  const cancelRemovedIngredientsAction = useCallback(({ id, variation }) => {
    return dispatch(cancelRemovedIngredients(id, variation.id));
  }, []);

  const addToCart = useCallback(async (variation, onSuccessCallback) => {
    await dispatch(
      addCartItem({ composition: [{ type: 'good', item: variation }] }, onSuccessCallback),
    );

    if (variation.sauces?.length > 0) {
      await dispatch(
        showFreeSaucesPanel(variation.id, variation.sauces, variation.free_sauce_count),
      );
    }
  }, []);

  const switchSorting = useCallback(() => {
    const currentIndex = sortingOptions.indexOf(sorting);
    const nextIndex = (currentIndex + 1) % sortingOptions.length;
    setSorting(sortingOptions[nextIndex]);
  }, [sorting]);

  return {
    goods,
    filteredStateGood,
    getGoodsWithoutDublicates,
    filteredGoods,
    filteredCategory,
    removeIngredientAction,
    cancelRemovedIngredientsAction,
    addToCart,
    sailplayGifts,
    sorting,
    switchSorting,
  };
}
