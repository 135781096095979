import { getCurrentDateUTC } from '../../../utils/helpers';
import { setGlobalMessage } from '../../../utils/setGlobalMessage';
import * as types from './actionTypes';
import chatApi from './api';
import { payloadTooLarge, unprocessableEntity } from '../../../utils/constants';

const fetchChatsStart = () => {
  return { type: types.FETCH_CHATS_REQUEST };
};

const fetchChatsDone = chats => {
  return {
    type: types.FETCH_CHATS_SUCCESS,
    payload: chats
  };
};

const fetchChatsFail = error => {
  return {
    type: types.FETCH_CHATS_FAILURE,
    payload: error
  };
};

const setActiveChat = chat => {
  if (chat) {
    return {
      type: types.SET_ACTIVE_CHAT_SUCCESS,
      payload: chat
    };
  }
  return {
    type: types.SET_ACTIVE_CHAT_FAILURE
  };
};

export const setUnreadedMessage = messages => {
  return {
    type: types.SET_UNREADED_MESSAGE,
    payload: messages
  };
};

export const fetchChats = () => {
  return dispatch => {
    dispatch(fetchChatsStart());

    return chatApi
      .getChats()
      .then(chats => {
        const activeChat = chats.find(chat => !chat.is_closed);
        if (activeChat) {
          dispatch(fetchChatsDone(chats.filter(chat => chat.is_closed)));
          dispatch(setActiveChat(activeChat));
          return dispatch(
            setUnreadedMessage(activeChat?.messages.filter(message => !message.is_read))
          );
        }
        return dispatch(fetchChatsDone(chats.filter(chat => chat.is_closed)));
      })
      .catch(error => {
        dispatch(fetchChatsFail(error));
      });
  };
};

export const closeActiveChat = () => {
  return {
    type: types.CLOSE_ACTIVE_CHAT
  };
};

const sendMessageRequest = (message, attachment) => {
  return {
    type: types.SEND_MESSAGE_REQUEST,
    payload: {
      text: message,
      is_message_from_user: true,
      time: getCurrentDateUTC(),
      attachment_links: attachment ? [attachment] : []
    }
  };
};

const sendMessageSuccess = () => {
  return {
    type: types.SEND_MESSAGE_SUCCESS
  };
};

const sendMessageFailure = error => {
  return {
    type: types.SEND_MESSAGE_FAILURE,
    payload: error
  };
};

const getLiaResponse = message => {
  return {
    type: types.GET_LIA_RESPONSE,
    payload: {
      text: message,
      is_message_from_user: false,
      time: getCurrentDateUTC(),
      attachment_links: message.attachment_links
    }
  };
};

const setIssueId = id => {
  return {
    type: types.SET_ISSUE_ID,
    payload: id
  };
};

export const setNewMessage = message => {
  return {
    type: types.SET_NEW_MESSAGE,
    payload: message
  };
};

const resetUnreadedStart = () => {
  return {
    type: types.RESET_UNREADED_MESSAGES_REQUEST
  };
};

const resetUnreadedSuccess = () => {
  return {
    type: types.RESET_UNREADED_MESSAGES_SUCCESS
  };
};

const resetUnreadedFailure = error => {
  return {
    type: types.RESET_UNREADED_MESSAGES_FAILURE,
    payload: error
  };
};

export const resetUnreadedMessages = () => {
  return dispatch => {
    dispatch(resetUnreadedStart());
    return chatApi
      .resetUnreadedMessages()
      .then(systemMessage => {
        if (systemMessage) {
          return dispatch(resetUnreadedSuccess());
        }
        return dispatch(resetUnreadedFailure(systemMessage.error));
      })
      .catch(error => {
        return dispatch(resetUnreadedFailure(error));
      });
  };
};

const saveImageRequest = () => {
  return {
    type: types.SAVE_IMAGE_REQUEST
  };
};

const saveImageSuccess = imageURL => {
  return {
    type: types.SAVE_IMAGE_SUCCESS,
    payload: imageURL
  };
};

const saveImageFailure = error => {
  return {
    type: types.SAVE_IMAGE_FAILURE,
    payload: error
  };
};

export const saveImage = image => (dispatch, getState) => {
  const locale = getState().intl.locale;
  dispatch(saveImageRequest());
  return chatApi
    .saveImage({ type: 'message-attachment', file: image })
    .then(response => {
      return dispatch(saveImageSuccess(response.link));
    })
    .catch(error => {
      if (error.status === 413) {
        setGlobalMessage(payloadTooLarge(locale));
      }
      if (error.status === 422) {
        setGlobalMessage(unprocessableEntity(locale));
      }
      return dispatch(saveImageFailure(error));
    });
};

export const deleteImage = () => {
  return {
    type: types.DELETE_IMAGE
  };
};

export const sendMessage = message => {
  return (dispatch, getState) => {
    const {
      chat: { attachment }
    } = getState();

    if (message.length < 1 && !attachment) {
      return dispatch(sendMessageFailure('Too short message'));
    }
    dispatch(changeLoadingLiaStatus(true));
    dispatch(sendMessageRequest(message, attachment));

    return chatApi
      .sendMessage(message, attachment)
      .then(answer => {
        dispatch(sendMessageSuccess());
        dispatch(changeLoadingLiaStatus(false));
        dispatch(deleteImage());
        if (!answer.text && answer.issue_id) {
          return dispatch(setIssueId(answer.issue_id));
        }
        return dispatch(getLiaResponse(answer.text));
      })
      .catch(error => {
        dispatch(deleteImage());
        return dispatch(sendMessageFailure(error));
      });
  };
};

export const resetChatState = () => {
  return {
    type: types.RESET_CHAT_STATE
  };
};

export const changeLoadingLiaStatus = value => {
  return {
    type: types.CHANGE_LOADING_LIA_STATUS,
    payload: value
  };
};
