import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// State
import { unusedBonusesSum } from '../../../state/modules/user/selectors';

// Components
import MobileView from './MobileView';
import DesktopView from './DesktopView';

export default function Profile(props) {
  const { onSignOut, onProfileClick } = props;

  const { avatar, loyalty } = useSelector(state => state.user.profile);
  const { isTablet, isPhone } = useSelector(state => state.responsive);
  const bonuses = useSelector(unusedBonusesSum);

  const isMobile = isTablet || isPhone;

  const View = isMobile ? MobileView : DesktopView;

  return (
    <View
      onSignOut={onSignOut}
      onProfileClick={onProfileClick}
      bonuses={bonuses}
      avatar={avatar}
      loyalty={loyalty}
    />
  );
}

Profile.defaultProps = {
  onProfileClick: undefined,
};

Profile.propTypes = {
  onSignOut: PropTypes.func.isRequired,
  onProfileClick: PropTypes.func,
};
