import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Icons
import GoogleIcon from '../../icons/social/Google.svg';
import FacebookIcon from '../../icons/social/Facebook.svg';
import VkIcon from '../../icons/social/Vk.svg';
import TelegramIcon from '../../icons/social/Telegram.svg';
import OkIcon from '../../icons/social/Ok.svg';
import TwitterIcon from '../../icons/social/Twitter.svg';
import ShareIcon from '../../icons/social/Share.svg';
import InstagramIcon from '../../icons/social/instagram.svg';

// Styles
import styles from './SocialButton.styl';

const cx = classNames.bind(styles);

const icons = {
  google: <GoogleIcon width={16} height={16} />,
  facebook: <FacebookIcon width={9} height={17} />,
  vk: <VkIcon width={21} height={12} />,
  telegram: <TelegramIcon width={18} height={18} />,
  twitter: <TwitterIcon width={19} height={15} />,
  ok: <OkIcon width={24} height={24} />,
  share: <ShareIcon width={17} height={14} />,
  instagram: <InstagramIcon width={23} height={23} />,
};

export default function SocialButton(props) {
  const { className, type, href } = props;

  const icon = icons[type] ? icons[type] : null;

  return (
    <a
      className={cx('SocialButton', className, { [`SocialButton_${type}`]: type })}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {icon}
    </a>
  );
}

SocialButton.defaultProps = {
  className: '',
};

SocialButton.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf([
    'google',
    'facebook',
    'vk',
    'telegram',
    'twitter',
    'ok',
    'share',
    'instagram',
  ]).isRequired,
  href: PropTypes.string.isRequired,
};
