import { createSelector } from 'reselect';
import { isBonus, isPromotional, isCombobox, isUpsale } from '../../../utils/cart';

// общее кол-во товаров в корзине
export const totalCountItemsSelector = createSelector(
  state => state.cart.composition,
  items => items.reduce((acc, item) => acc + item.count, 0),
);

// товары в корзине за бонусные баллы
export const bonusesInCompositionSelector = createSelector(
  state => state.cart.composition,
  items => items.filter(item => isBonus(item)),
);

// акционные товары в корзине
export const promoInCompositionSelector = createSelector(
  state => state.cart.composition,
  items => items.filter(item => isPromotional(item) || isCombobox(item) || isUpsale(item)),
);

// вся ли корзина состоит исключительно из акционных товаров
export const isAllCompositionPromoSelector = createSelector(
  [state => state.cart.composition, promoInCompositionSelector],
  (items, promoItems) => items.length === promoItems.length,
);
