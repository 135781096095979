import { createSelector } from 'reselect';
import {themeNameSpaces} from "../city/themeNameSpaces";
import {isMaterAndMargo} from "../city/selectors";

export const promotionGameState = createSelector(
  state => state.promotionGame,
  promotionGame => promotionGame,
);

export const isPromotionGameActive = createSelector(
  promotionGameState,
  state => state.isPromotionGameActive,
);

export const promotionGameName = createSelector(promotionGameState, props => props.gameName);

export const isHalloweenGame = createSelector(promotionGameName, game => game === themeNameSpaces.HALLOWEEN_QUEST)
export const promoArtefact = createSelector(promotionGameState, props => props.artefacts);

export const promoGameProps = createSelector(
  isPromotionGameActive,
  isHalloweenGame,
  isMaterAndMargo,
  promotionGameName,
  promoArtefact,
  (isPromotionGameActive, isHalloweenGame, isMaterAndMargo, gameName, artefacts) => ({
    isPromotionGameActive,
    isHalloweenGame,
    isMaterAndMargo,
    gameName,
    artefacts,
    artefactsIds: artefacts.map(art => art.id),
  }),
);
