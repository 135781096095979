const prefix = '@card';

export const FETCH_LIST_REQUEST = `${prefix}/FETCH_LIST_REQUEST`;
export const FETCH_LIST_SUCCESS = `${prefix}/FETCH_LIST_SUCCESS`;
export const FETCH_LIST_FAILURE = `${prefix}/FETCH_LIST_FAILURE`;

export const SET_CARD_ID = `${prefix}/SET_CARD_ID`;
export const SET_CRYPTOGRAM = `${prefix}/SET_CRYPTOGRAM`;
export const SET_CARD_FIELDS = `${prefix}/SET_CARD_FIELDS`;
export const RESET_CARD_FIELDS = `${prefix}/RESET_CARD_FIELDS`;
export const RESET_CARD_STATE = `${prefix}/RESET_CARD_STATE`;
