import _isEmpty from 'lodash/isEmpty';

// State
import * as stockTypes from '../../modules/stock/actionTypes';

// Смена города
export function cityChange(action, prevState, nextState) {
  const actionPayloadCityName = action?.payload?.name;
  const prevUserCityDataName = prevState?.city?.userCity?.name;

  if (
    _isEmpty(actionPayloadCityName) ||
    _isEmpty(prevUserCityDataName) ||
    prevUserCityDataName === actionPayloadCityName
  ) {
    return null;
  }

  const outData = {
    event: 'UA event',
    eventCategory: 'Выбор города',
    eventAction: actionPayloadCityName,
    eventLabel: undefined,
    UserCity: actionPayloadCityName,
  };

  return outData;
}

// Смена языка
export function localeChange(action, prevState, nextState) {
  let eventAction = '';
  let UserLanguage = '';

  if (action.payload) {
    if (action.payload === 'en') {
      eventAction = 'На английский';
      UserLanguage = 'Английский';
    } else if (action.payload === 'ru') {
      eventAction = 'На русский';
      UserLanguage = 'Русский';
    }
  }

  const outData = {
    event: 'UA event',
    eventCategory: 'Переключение языка',
    eventAction,
    eventLabel: undefined,
    UserLanguage,
  };

  return outData;
}

// Добавление действительного промокода
export function applyStock(action, prevState, nextState) {
  const {
    type,
    payload: { code, error },
    badStock,
    status,
  } = action;

  const _success = type === stockTypes.APPLY_STOCK_SUCCESS;

  if (_success) {
    const prevStockDataCode = prevState?.stock?.data?.code;

    if (!_isEmpty(prevStockDataCode) && prevStockDataCode === code) {
      return null;
    }
  }

  const outData = {
    event: 'UA event',
    eventCategory: 'Активация промокода',
    eventAction: 'Вручную',
    // eslint-disable-next-line no-nested-ternary
    eventLabel: _success ? code : status === 404 || status === 400 ? badStock : error,
    promoValidity: _success ? 'Действителен' : 'Недействителен',
  };

  return outData;
}
