import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';

// Components
import Lang from '../Lang';

// Utils
import isomorphicCookies from '../../../utils/cookie/isomorphicCookies';

// Configs
import config from '../../../config';

// Styles
import styles from './MobileView.styl';

const cx = classNames.bind(styles);

export default function MobileView(props) {
  const { className } = props;

  const locale = useSelector(state => state.intl.locale);

  const onLocaleChange = newLocale => {
    if (newLocale !== locale) {
      isomorphicCookies().set('locale', newLocale);
      window.location.reload();
    }

    return null;
  };

  return (
    <div className={cx('LangSwitcher', className)}>
      {config.langList.map(l => (
        <Lang key={l} locale={l} onLocaleClick={onLocaleChange} isActive={locale === l} />
      ))}
    </div>
  );
}

MobileView.defaultProps = {
  className: '',
};

MobileView.propTypes = {
  className: PropTypes.string,
};
