import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { isAstronautTheme } from '../../../state/modules/city/selectors';
import HalalIcon from '../../../icons/halalMain.svg';

// Components
import Modal from '../../Modal';
import MobileMenu from '../../MobileMenu';
import CitySelector from '../../CitySelector';
import Logo from '../../Logo';

// Router
import { paths, getRoute } from '../../../entry/routes';

// Styles
import styles from './Header.styl';
import ModalCityRedirect from '../../ModalCityRedirect/MobileView/ModalCityRedirect';

const cx = classNames.bind(styles);

export default function MobileView(props) {
  const { className } = props;

  const [isOpen, setIsOpen] = useState(false);
  const isAstronaut = useSelector(isAstronautTheme);
  const city = useSelector(state => state.city.userCity);

  const toggle = () => {
    setIsOpen(prev => !prev);
  };
  const isHalal = !!city?.is_halal;
  return (
    <header className={cx('Header', isAstronaut && 'Header_cosmos', className)}>
      <Link to={getRoute(paths.home)} className={cx('Header__logo')}>
        <Logo />
      </Link>
      <CitySelector className={cx('Header__CitySelector')} />
      {!isHalal && <HalalIcon width={24} height={24} />}
      <div
        className={cx('Header__hamburger')}
        onClick={toggle}
        onKeyDown={e => e.key === 'Enter' && toggle()}
        role="button"
        tabIndex={0}
      >
        <div className={cx('Header__hamburger-line')} />
        <div className={cx('Header__hamburger-line')} />
        <div className={cx('Header__hamburger-line')} />
      </div>
      <ModalCityRedirect />

      <Modal isOpen={isOpen} onClose={toggle} direction="left" exit={0}>
        <MobileMenu onClose={toggle} />
      </Modal>
    </header>
  );
}

MobileView.defaultProps = {
  className: '',
};

MobileView.propTypes = {
  className: PropTypes.string,
};
