import React from 'react';
import { Transition } from 'react-transition-group';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Icons
import CloseIcon from '../../icons/close.svg';

// Styles
import styles from './Popup.styl';

const cx = classNames.bind(styles);

export default function Popup(props) {
  const { className, children, isOpen, onHide, isLanding, isAuth } = props;
  return (
    <Transition in={isOpen} timeout={100} mountOnEnter unmountOnExit>
      {state => (
        <div
          className={cx(
            'Popup',
            isLanding ? 'Popup_landing' : isAuth ? 'Popup_auth' : 'Popup_regular',
            `Popup_${state}`,
            className
          )}
        >
          {onHide && (
            <button
              className={cx('Popup__close')}
              onClick={onHide}
              onKeyDown={e => e.key === 'Enter' && onHide()}
              type="button"
            >
              <CloseIcon width={14} height={14} />
            </button>
          )}
          {children}
        </div>
      )}
    </Transition>
  );
}

Popup.defaultProps = {
  className: '',
  onHide: null,
  isLanding: false,
  isAuth: false
};

Popup.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  onHide: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  isLanding: PropTypes.bool,
  isAuth: PropTypes.bool,
};
