import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames/bind';
import { useIntl } from 'react-intl';

// State
import { searchCities } from '../../../../state/modules/city/actions';

// Icons
import SearchIcon from '../../../../icons/search.svg';

// Styles
import styles from './SearchBar.styl';

const cx = classNames.bind(styles);

export default function SearchBar() {
  const dispatch = useDispatch();
  const intl = useIntl();

  const handleOnChange = e => {
    dispatch(searchCities(e.target.value));
  };

  useEffect(() => {
    return () => {
      dispatch(searchCities(''));
    };
  }, []);

  return (
    <div className={cx('SearchBar')}>
      <input
        className={cx('SearchBar__field')}
        onChange={handleOnChange}
        placeholder={intl.formatMessage({ id: 'field.search' })}
        type="text"
      />
      <div className={cx('SearchBar__icon')}>
        <SearchIcon width={24} height={24} />
      </div>
    </div>
  );
}
