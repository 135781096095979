import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createMiddleware } from 'redux-beacon';
import googleTagManager from '@redux-beacon/google-tag-manager';

import eventsMapper from './analytics';
import rootReducer from './reducers';

export let exportableStore;
export default function configureStore(preloadedState) {
  const gtm = googleTagManager();
  const gtmMiddleware = createMiddleware(eventsMapper, gtm);
  const middlewares = [thunkMiddleware, gtmMiddleware];

  let composeEnhancers = compose;

  if (__CLIENT__ && !(__PROD__ && process.env.NEW2 === 'true')) {
    composeEnhancers = require('redux-devtools-extension').composeWithDevTools;

    const logger = require(`redux-logger`).createLogger({ collapsed: true });
    middlewares.push(logger);
  }

  const createdStore = createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(...middlewares)),
  );

  exportableStore = createdStore;
  return createdStore;
}
