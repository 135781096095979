import React from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

// Components
import Modal from '../../Modal';
import Modal3 from '../../Modal3';
import Button from '../../Button';
import Heading from '../../Heading';

// State
import { acceptCityRedirect, clearCityRedirect } from '../../../state/modules/ui/actions';

// Styles
import styles from './ModalCityRedirect.styl';

const cx = classNames.bind(styles);

const ModalCityRedirect = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const cityToRedirect = useSelector(state => state.ui.cityToRedirect);
  const isRealPhone = useSelector(state => state.responsive.isRealPhone);

  const onChangeCityAccept = () => {
    dispatch(acceptCityRedirect(cityToRedirect));
  };

  const onChangeCityRefuse = () => {
    dispatch(clearCityRedirect());
  };

  if (!cityToRedirect) {
    return null;
  }

  const View = isRealPhone ? Modal3 : Modal;

  return (
    <>
      <View className={cx('ModalCityRedirect')} isOpen={!!cityToRedirect}>
        {isRealPhone && <div className={cx('ModalCityRedirect__modal-icon')} />}
        <Heading tagName="h3" level={3} className={cx('ModalCityRedirect__title')}>
          {intl.formatMessage({ id: 'checkout.changeCityRequest.title' })}
        </Heading>
        <p className={cx('ModalCityRedirect__description')}>
          {intl.formatMessage({ id: 'checkout.changeCityRequest.description' })}
        </p>
        <div className={cx('ModalCityRedirect__wrapper')}>
          <Button onClick={onChangeCityAccept} className={cx('ModalCityRedirect__button')}>
            {intl.formatMessage({ id: 'checkout.changeCityRequest.button' })}
          </Button>
          <Button
            onClick={onChangeCityRefuse}
            className={cx('ModalCityRedirect__button-secondary')}
          >
            {intl.formatMessage({ id: 'button.cancel' })}
          </Button>
        </div>
      </View>
    </>
  );
};

export default ModalCityRedirect;
