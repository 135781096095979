import * as immutable from 'object-path-immutable';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import * as types from './actionTypes';

const initialState = {
  goods: {},
};

const getIndexOfVariationToUpdate = (state, goodId, variationId) => {
  const variations = _get(state, ['goods', goodId, 'variations']);
  return variations.findIndex(variation => variation.id === variationId);
};

const getIndexOfIngredientToUpdate = (ingredients, ingredientId) => {
  return ingredients.findIndex(ingredient => ingredient.id == ingredientId);
};

const getIngredientsType = included => (included ? 'include_ingredients' : 'available_ingredients');

const getGoodData = (state, goodId, data) => {
  const variations = _get(state, ['goods', goodId, 'variations']);

  return _isEmpty(variations) ? data : _omit(data, ['variations']);
};

export default function constructorReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_GOOD_REQUEST:
      return immutable.assign(state, ['goods', action.payload.id], {
        isLoading: true,
        isLoaded: false,
        error: '',
      });

    case types.FETCH_GOOD_SUCCESS:
      return immutable.assign(state, ['goods', action.payload.id], {
        ...getGoodData(state, action.payload.id, action.payload.data),
        isLoading: false,
        isLoaded: true,
      });

    case types.FETCH_GOOD_FAILURE:
      return immutable.assign(state, ['goods', action.payload.id], {
        isLoading: false,
        isLoaded: true,
        error: action.payload.error,
      });

    case types.SET_CUSTOM_NAME:
      return immutable.set(state, ['goods', action.payload.id, 'customName'], action.payload.name);

    case types.CHANGE_DOUGH:
      return immutable.set(
        state,
        ['goods', action.payload.id, 'currentDough'],
        action.payload.dough,
      );

    case types.CHANGE_SIZE:
      return immutable.set(state, ['goods', action.payload.id, 'currentSize'], action.payload.size);

    case types.TOGGLE_STUFFED_CRUST:
      return immutable.assign(state, ['goods', action.payload.id], {
        stuffedCrust: action.payload.value,
        stuffedCrustIsAdd: action.payload.value !== 'none',
      });

    case types.SELECT_STUFFED_CRUST:
      return immutable.assign(state, ['goods', action.payload.id], {
        stuffedCrust: action.payload.value,
        stuffedCrustIsAdd: action.payload.value !== 'none',
      });

    case types.CHANGE_AVAILABLE_VARIATIONS:
      return immutable.set(
        state,
        ['goods', action.payload.id, 'variations'],
        action.payload.variations,
      );

    case types.ADD_PORTION: {
      const indexOfVariationToUpdate = getIndexOfVariationToUpdate(
        state,
        action.payload.id,
        action.payload.variationId,
      );
      const ingredientsType = getIngredientsType(action.payload.included);

      return immutable.update(
        state,
        ['goods', action.payload.id, 'variations', indexOfVariationToUpdate, ingredientsType],
        ingredients => {
          const indexOfIngredientToUpdate = getIndexOfIngredientToUpdate(
            ingredients,
            action.payload.ingredientId,
          );
          return immutable.update(ingredients, [indexOfIngredientToUpdate, 'count'], (count = 0) =>
            Math.min(Number(count) + 1, 2),
          );
        },
      );
    }

    case types.REMOVE_PORTION: {
      const indexOfVariationToUpdate = getIndexOfVariationToUpdate(
        state,
        action.payload.id,
        action.payload.variationId,
      );
      const ingredientsType = getIngredientsType(action.payload.included);

      return immutable.update(
        state,
        ['goods', action.payload.id, 'variations', indexOfVariationToUpdate, ingredientsType],
        ingredients => {
          const indexOfIngredientToUpdate = getIndexOfIngredientToUpdate(
            ingredients,
            action.payload.ingredientId,
          );
          return immutable.update(ingredients, [indexOfIngredientToUpdate, 'count'], (count = 0) =>
            Math.max(Number(count) - 1, 0),
          );
        },
      );
    }

    case types.REMOVE_INGREDIENT: {
      const indexOfVariationToUpdate = getIndexOfVariationToUpdate(
        state,
        action.payload.id,
        action.payload.variationId,
      );
      const ingredientsType = getIngredientsType(action.payload.included);

      return immutable.update(
        state,
        ['goods', action.payload.id, 'variations', indexOfVariationToUpdate, ingredientsType],
        ingredients => {
          const indexOfIngredientToUpdate = getIndexOfIngredientToUpdate(
            ingredients,
            action.payload.ingredientId,
          );
          return immutable.set(ingredients, [indexOfIngredientToUpdate, 'count'], 0);
        },
      );
    }

    case types.SET_IS_ADDED: {
      return immutable.assign(state, ['goods', action.payload], {
        added: true,
      });
    }

    case types.RESET: {
      return immutable.set(state, ['goods', action.payload.id], {
        adding: false,
        added: false,
        isLoading: false,
        isLoaded: false,
        error: '',
        customName: '',
      });
    }

    default:
      return state;
  }
}
