import { get } from '../../../utils/api';
import config from '../../../config';

export default {
  getCities: data => get({ url: 'city/list', data, ssr: true }),
  getUserCity: (data, headers) => get({ url: 'city/user-city', headers, data, ssr: true }),
  getUserCoordinates: data => get({ url: `city/details`, data }),
  getGeoPosition: (longitude, latitude) =>
    fetch(
      `https://geocode-maps.yandex.ru/1.x/?apikey=${config.yandexApiKey}&format=json&geocode=${longitude},${latitude}`,
    ).then(res => res.json()),
};
