import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Style
import styles from './Container.styl';

const cx = classNames.bind(styles);

export default function Container(props) {
  const { className, children, size, ...rest } = props;

  return (
    <div className={cx('Container', className, `Container_size_${size}`)} {...rest}>
      {children}
    </div>
  );
}

Container.defaultProps = {
  className: '',
  size: 'm',
};

Container.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf(['s', 'm', 'l']),
};
