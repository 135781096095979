import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import InputField from '../../InputField';
import PhoneField from '../../PhoneField';
import Button from '../../Button';
import CheckboxField from '../../CheckboxField';
import AppLink from '../../AppLink';
import Heading from '../../Heading';

// Router
import { getRoute } from '../../../entry/routes';

// State
import { signUpWithSms } from '../../../state/modules/user/actions';
import userApi from '../../../state/modules/user/api';

// Utils
import { numberByCode, required, requiredNumber } from '../../../utils/validators';
import { setGlobalMessage } from '../../../utils/setGlobalMessage';

// Config
import config from '../../../config';

// Styles
import styles from './NewSignUpForm.styl';

const cx = classNames.bind(styles);

export default function NewSignUpForm(props) {
  const { className, successCallback, goToSignIn } = props;

  const intl = useIntl();
  const dispatch = useDispatch();

  const [isSigningUp, setIsSigninUp] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(true);
  const [isCheckbox1Enabled, setIsCheckbox1Enabled] = useState(false);
  const [isCheckbox2Enabled, setIsCheckbox2Enabled] = useState(false);
  const [isCheckbox3Enabled, setIsCheckbox3Enabled] = useState(false);
  const [isCheckbox4Enabled, setIsCheckbox4Enabled] = useState(false);
  const [isCheckbox5Enabled, setIsCheckbox5Enabled] = useState(false);
  const [timer, setTimer] = useState(0);
  const intervalRef = useRef(null);

  // в Польше кнопка недоступна, пока не прожаты первый и последний чекбоксы
  const isButtonDisabled =
    config.legal === 'pl' ? !isFirstStep && (!isCheckbox1Enabled || !isCheckbox5Enabled) : false;

  const { register, handleSubmit, errors, control, getValues } = useForm({
    mode: 'onSubmit',
    defaultValues: { phone: '', code: '' },
  });

  const sendConfirmCode = async () => {
    const { phone } = getValues(['phone']);

    await userApi
      .postUserConfirmCodeForUnregistered({ phone, type: 'signup' })
      .then(() => {
        setTimer(60);
        intervalRef.current = setInterval(() => {
          setTimer(prev => {
            if (prev - 1 === 0) {
              clearInterval(intervalRef.current);
            }

            return prev - 1;
          });
        }, 1000);

        setIsFirstStep(false);
      })
      .catch(() => null);
  };

  // подтверждаем второй шаг
  const onSubmit = handleSubmit(async formValues => {
    setIsSigninUp(true);

    if (isFirstStep) {
      await sendConfirmCode();
    } else {
      const { phone, code } = formValues;

      const handleSuccessCallback = () => {
        successCallback?.();
        setGlobalMessage(intl.formatMessage({ id: 'signUp.successMessage.text' }), {
          type: 'success',
        });
      };

      await dispatch(
        signUpWithSms(
          {
            phone,
            code,
            sms_state: config.legal === 'pl' ? isCheckbox3Enabled : undefined,
            subscription_state: config.legal === 'pl' ? isCheckbox4Enabled : undefined,
            accepted_loyalty_program:
              config.legal === 'pl' ? Number(isCheckbox2Enabled) : undefined,
          },
          handleSuccessCallback,
        ),
      );
    }

    setIsSigninUp(false);
  });

  return (
    <form className={cx('NewSignUpForm', className)} onSubmit={onSubmit}>
      <Heading className={cx('NewSignUpForm__title')} level={3}>
        {intl.formatMessage({ id: 'page.signUp' })}
      </Heading>
      <div className={cx('NewSignUpForm__phone')}>
        <Controller
          name="phone"
          control={control}
          rules={{ validate: { required, requiredNumber, numberByCode } }}
          render={({ onChange, value, name }) => (
            <PhoneField
              name={name}
              value={value}
              label={intl.formatMessage({ id: 'field.phone.label' })}
              autoFocus
              onChange={e => {
                // при любом изменении номера сбрасываем к первому шагу
                setIsFirstStep(true);
                return onChange(e);
              }}
            />
          )}
        />
        {errors.phone && <div className={cx('NewSignUpForm__error')}>{errors.phone.message}</div>}
      </div>
      {!isFirstStep && (
        <div className={cx('NewSignUpForm__code')}>
          <InputField
            name="code"
            type="password"
            label={intl.formatMessage({ id: 'field.sms.label' })}
            register={register({
              validate: {
                length: value =>
                  value.length === 4
                    ? undefined
                    : intl.formatMessage({ id: 'validators.requiredCode' }),
              },
            })}
          />
          {errors.code && <div className={cx('NewSignUpForm__error')}>{errors.code.message}</div>}
        </div>
      )}
      {config.legal === 'pl' && !isFirstStep && (
        <>
          <CheckboxField
            className={cx('NewSignUpForm__privacy-title')}
            value={isCheckbox1Enabled}
            label={intl.formatMessage({ id: 'privacy.checkbox1.title' })}
            onChange={() => setIsCheckbox1Enabled(prev => !prev)}
          />
          <div className={cx('NewSignUpForm__privacy-description')}>
            {intl.formatMessage({ id: 'privacy.checkbox1.description' })}
          </div>
          <CheckboxField
            className={cx('NewSignUpForm__privacy-title')}
            value={isCheckbox2Enabled}
            label={intl.formatMessage({ id: 'privacy.checkbox2.title' })}
            onChange={() => setIsCheckbox2Enabled(prev => !prev)}
          />
          <div className={cx('NewSignUpForm__privacy-description')}>
            {intl.formatMessage({ id: 'privacy.checkbox2.description' })}
          </div>
          <div className={cx('NewSignUpForm__privacy-subtitle')}>
            {intl.formatMessage({ id: 'privacy.title' })}
          </div>
          <div className={cx('NewSignUpForm__privacy-description')}>
            {intl.formatMessage({ id: 'privacy.description' })}
          </div>
          <CheckboxField
            className={cx('NewSignUpForm__privacy-title')}
            value={isCheckbox3Enabled}
            label={intl.formatMessage({ id: 'privacy.checkbox3.title' })}
            onChange={() => setIsCheckbox3Enabled(prev => !prev)}
            disabled={!isCheckbox2Enabled}
          />
          <div className={cx('NewSignUpForm__privacy-description')}>
            {intl.formatMessage({ id: 'privacy.checkbox3.description' })}
          </div>
          <CheckboxField
            className={cx('NewSignUpForm__privacy-title')}
            value={isCheckbox4Enabled}
            label={intl.formatMessage({ id: 'privacy.checkbox4.title' })}
            onChange={() => setIsCheckbox4Enabled(prev => !prev)}
            disabled={!isCheckbox2Enabled}
          />
          <div className={cx('NewSignUpForm__privacy-description')}>
            {intl.formatMessage({ id: 'privacy.checkbox4.description' })}
          </div>
          <CheckboxField
            className={cx('NewSignUpForm__privacy-title')}
            value={isCheckbox5Enabled}
            label={intl.formatMessage(
              { id: 'privacy.checkbox5.title' },
              {
                link1: (
                  <AppLink to={getRoute('user-agreement')}>
                    {intl.formatMessage({ id: 'checkout.agreePrivacyPolicyLink' })}
                  </AppLink>
                ),
                link2: (
                  <AppLink to={getRoute('politics-of-privacy')}>
                    {intl.formatMessage({ id: 'checkout.agreePrivacyPolicyLink1' })}
                  </AppLink>
                ),
              },
            )}
            onChange={() => setIsCheckbox5Enabled(prev => !prev)}
          />
          <div className={cx('NewSignUpForm__privacy-description')}>
            {intl.formatMessage(
              { id: 'privacy.checkbox5.description' },
              {
                link1: (
                  <AppLink to={getRoute('user-agreement')}>
                    {intl.formatMessage({ id: 'checkout.agreePrivacyPolicyLink' })}
                  </AppLink>
                ),
                link2: (
                  <AppLink to={getRoute('politics-of-privacy')}>
                    {intl.formatMessage({ id: 'checkout.agreePrivacyPolicyLink1' })}
                  </AppLink>
                ),
              },
            )}
          </div>
        </>
      )}
      <div className={cx('NewSignUpForm__actions')}>
        {!isFirstStep && (
          <button
            className={cx('NewSignUpForm__action', {
              NewSignUpForm__action_disabled: Boolean(timer),
            })}
            onClick={sendConfirmCode}
            type="button"
            disabled={Boolean(timer)}
          >
            {intl.formatMessage(
              { id: timer ? 'button.receiveCodeAfter' : 'button.receiveCode' },
              { second: timer },
            )}
          </button>
        )}
        {isFirstStep && goToSignIn && (
          <button className={cx('NewSignUpForm__action')} onClick={goToSignIn} type="button">
            {intl.formatMessage({ id: 'button.back' })}
          </button>
        )}
        <Button
          className={cx('NewSignUpForm__submit')}
          type="submit"
          isLoading={isSigningUp}
          disabled={isButtonDisabled}
        >
          {intl.formatMessage({ id: 'button.continue' })}
        </Button>
      </div>
    </form>
  );
}

NewSignUpForm.defaultProps = {
  className: '',
  successCallback: undefined,
  goToSignIn: undefined,
};

NewSignUpForm.propTypes = {
  className: PropTypes.string,
  successCallback: PropTypes.func,
  goToSignIn: PropTypes.func,
};
