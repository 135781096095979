import React, { useEffect, useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { matchPath, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import isomorphicCookies from '../../../utils/cookie';

// Router
import { paths } from '../../../entry/routes';
import useOrderTracking from "../../../scenes/OrderTracking/useOrderTracking";

const pathsNamespace = {
  checkoutPage: 'checkoutPage',
  orderTrackingPage: 'orderTrackingPage',
  productPage: 'productPage',
};

export const CityAdsScript = () => {
  const { pathname } = useLocation();
  const _supertag = useSelector(state => state.city.userCity._supertag);
  const { openedProductCardId } = useSelector(state => state?.ui);
  const { composition } = useSelector(state => state?.cart);
  const utmSourceCityAds = isomorphicCookies().get('utm_source') === 'cityads';

  const trackingOrderData = useSelector(state => state.order.trackingOrderData)

  const pathsToMatch = useRef([
    {
      path: `/${_supertag}${paths.order}`,
      key: pathsNamespace.checkoutPage,
      exact: true,
      strict: false,
    },
    {
      path: `/${_supertag}${paths.orderTrackingAlias}`,
      key: pathsNamespace.orderTrackingPage,
      exact: true,
      strict: false,
    },
    {
      path: paths.productAlias,
      key: pathsNamespace.productPage,
      exact: true,
      strict: false,
    },
  ]).current;

  const matchingPath = useMemo(() => {
    return pathsToMatch.find(route => matchPath(pathname, route))?.key;
  }, [pathname]);

  const cartData = useMemo(() => {
    return composition.reduce(
      (result, good) => {
        result.ids.push(good.item.good_id);
        result.counts.push(good.count);
        return result;
      },
      { ids: [], counts: [] },
    );
  }, [composition]);

  const lastOrderCompleteData = useMemo(() => {

    if (matchingPath !== pathsNamespace.orderTrackingPage || !trackingOrderData || !trackingOrderData?.client_number){
      return null
    }

    const {id, client_number, cart: {discount_price, price, composition}} = trackingOrderData
    const orderN = client_number || id;
    const orderProductsIds = composition.map(pr => pr.item.id);
    const orderTotal = discount_price || price;
    const orderCurrency = 'RUR';

    return { orderN, orderProductsIds, orderTotal, orderCurrency };
  }, [matchingPath, trackingOrderData]);
  useEffect(() => {
    // если нет метки cityads оставляем дефолтный скрипт
    if (!utmSourceCityAds) {
      return;
    }

    // Remove the previous script
    const scriptElement = document.getElementById('xcntmyAsync');
    if (scriptElement) {
      scriptElement.parentNode.removeChild(scriptElement);
    }

    if (openedProductCardId) {
      const newScript = document.createElement('script');
      newScript.id = 'xcntmyAsync';
      newScript.type = 'text/javascript';
      newScript.text = `xcnt_order_products = null; xcnt_order_id = null; xcnt_order_total = null; xcnt_order_currency = null; xcnt_basket_products = null; xcnt_basket_quantity = null; var xcnt_product_id = '${openedProductCardId}'; (function(d){ var xscr = d.createElement( 'script' ); xscr.async = 1; xscr.src = '//x.cnt.my/async/track/?r=' + Math.random(); var x = d.getElementById( 'xcntmyAsync' ); x.parentNode.insertBefore( xscr, x ); })(document);`;
      document.head.appendChild(newScript);
    } else if (matchingPath === pathsNamespace.checkoutPage && cartData.ids.length) {
      const xcnt_basket_products = cartData.ids.join(',');
      const xcnt_basket_quantity = cartData.counts.join(',');
      const newScript = document.createElement('script');
      newScript.id = 'xcntmyAsync';
      newScript.type = 'text/javascript';
      newScript.text = `xcnt_order_products = null; xcnt_order_id = null; xcnt_order_total = null; xcnt_order_currency = null; xcnt_product_id = null; var xcnt_basket_products = '${xcnt_basket_products}'; var xcnt_basket_quantity = '${xcnt_basket_quantity}';(function(d){ var xscr = d.createElement( 'script' ); xscr.async = 1; xscr.src = '//x.cnt.my/async/track/?r=' + Math.random(); var x = d.getElementById( 'xcntmyAsync' ); x.parentNode.insertBefore( xscr, x ); })(document);`;
      document.head.appendChild(newScript);
    } else if (matchingPath === pathsNamespace.orderTrackingPage && lastOrderCompleteData?.orderN) {
      const xcnt_order_products = lastOrderCompleteData.orderProductsIds.join(',');
      const xcnt_order_id = lastOrderCompleteData.orderN.toString();
      const xcnt_order_total = lastOrderCompleteData.orderTotal.toString();
      const xcnt_order_currency = lastOrderCompleteData.orderCurrency.toString();

      const newScript = document.createElement('script');
      newScript.id = 'xcntmyAsync';
      newScript.type = 'text/javascript';
      // 'xcnt_order_products; xcnt_order_id; xcnt_order_total; xcnt_order_currency;'
      newScript.text = `xcnt_basket_products = null; xcnt_basket_quantity = null; xcnt_product_id = null; var xcnt_order_products = '${xcnt_order_products}'; var xcnt_order_id = '${xcnt_order_id}';var xcnt_order_total = '${xcnt_order_total}'; var xcnt_order_currency = '${xcnt_order_currency}';(function(d){ var xscr = d.createElement( 'script' ); xscr.async = 1; xscr.src = '//x.cnt.my/async/track/?r=' + Math.random(); var x = d.getElementById( 'xcntmyAsync' ); x.parentNode.insertBefore( xscr, x ); })(document);`;
      document.head.appendChild(newScript);
    } else {
      const newScript = document.createElement('script');
      newScript.id = 'xcntmyAsync';
      newScript.type = 'text/javascript';
      newScript.text = `xcnt_basket_products = null; xcnt_basket_quantity = null; xcnt_product_id = null; xcnt_order_products = null; xcnt_order_id = null; xcnt_order_total = null; xcnt_order_currency = null; xcnt_product_id = null; (function(d){ var xscr = d.createElement( 'script' ); xscr.async = 1; xscr.src = '//x.cnt.my/async/track/?r=' + Math.random(); var x = d.getElementById( 'xcntmyAsync' ); x.parentNode.insertBefore( xscr, x ); })(document);`;
      document.head.appendChild(newScript);
    }
  }, [
    composition,
    matchingPath,
    pathname,
    cartData,
    lastOrderCompleteData,
    openedProductCardId,
    utmSourceCityAds,
  ]);

  if (utmSourceCityAds) {
    return null;
  }

  return (
    <Helmet>
      <script id="xcntmyAsync" type="text/javascript">
        {`(function(d){ var xscr = d.createElement( 'script' ); xscr.async = 1; xscr.src = '//x.cnt.my/async/track/?r=' + Math.random(); var x = d.getElementById( 'xcntmyAsync' ); x.parentNode.insertBefore( xscr, x ); })(document);`}
      </script>
    </Helmet>
  );
};
