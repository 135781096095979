import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

// State
import { signOut } from '../../state/modules/user/actions';
import {
  appStoreUrlSelector,
  playMarketUrlSelector,
  appGalleryUrlSelector,
} from '../../state/modules/city/selectors';

// Components
import NavBar from '../AuthModule/NavBar';
import Nav from '../Nav';
import MobilePhone from '../MobilePhone';
import MobileAppButton from '../MobileAppButton';
import LangSwitcher from '../LangSwitcher/MobileView';
import Button from '../Button';
import Profile from '../AuthModule/Profile';

// Router
import { paths, getRoute } from '../../entry/routes';

// Styles
import styles from './MobileMenu.styl';

const cx = classNames.bind(styles);

export default function MobileMenu(props) {
  const { onClose } = props;

  const intl = useIntl();
  const dispatch = useDispatch();
  const phoneNumber = useSelector(state => state.city.userCity.help_number);
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const appleStoreUrl = useSelector(appStoreUrlSelector);
  const playMarketUrl = useSelector(playMarketUrlSelector);
  const appGalleryUrl = useSelector(appGalleryUrlSelector);
  const OS = useSelector(state => state.responsive.OS);

  const mobileButton = useMemo(() => {
    if (OS === 'iOS' && appleStoreUrl) {
      return <MobileAppButton className={cx('MobileMenu__app')} url={appleStoreUrl} type="apple" />;
    }

    if (OS === 'AndroidOS') {
      return (
        <>
          {playMarketUrl && (
            <MobileAppButton className={cx('MobileMenu__app')} url={playMarketUrl} type="google" />
          )}
          {appGalleryUrl && (
            <MobileAppButton className={cx('MobileMenu__app')} url={appGalleryUrl} type="huawei" />
          )}
        </>
      );
    }

    return null;
  }, [OS, appleStoreUrl, playMarketUrl, appGalleryUrl]);

  const handleClose = () => {
    return onClose();
  };

  const handleSignOut = async () => {
    await dispatch(signOut());
  };

  return (
    <div className={cx('MobileMenu')}>
      <NavBar className={cx('MobileMenu__navbar')} onClose={handleClose} />
      <div className={cx('Auth')}>
        {isAuthenticated ? (
          <Profile onSignOut={handleSignOut} onProfileClick={handleClose} />
        ) : (
          <div className={cx('Auth__login')}>
            <div className={cx('Auth__text')}>{intl.formatMessage({ id: 'auth.subline' })}</div>
            <Button
              className={cx('Auth__button')}
              to={getRoute(paths.signin)}
              secondary
              onClick={handleClose}
            >
              {intl.formatMessage({ id: 'button.login' })}
            </Button>
          </div>
        )}
      </div>
      <Nav className={cx('MobileMenu__nav')} onClose={handleClose} view="phone" />
      <div className={cx('MobileMenu__footer')}>
        {phoneNumber && <MobilePhone className={cx('MobileMenu__phone')} phone={phoneNumber} />}
        {mobileButton}
        <LangSwitcher className={cx('MobileMenu__lang')} />
      </div>
    </div>
  );
}

MobileMenu.propTypes = {
  onClose: PropTypes.func.isRequired,
};
