// используется только в корзине! в каталогах приходит good_type вместо type

export const isCombobox = props => {
  return props.type === 'combo';
};

export const isGift = props => {
  return props.type === 'gift';
};

export const isBonus = props => {
  return props.type === 'bonus';
};

export const isUpsale = props => {
  return props.type === 'upsale';
};

export const isPromotional = props => {
  return props.type === 'promotional';
};

export const isGood = props => {
  return props.type === 'good';
};

// для обычного товара проверка идет через item.id
export function getStopListReplacements(stopList, good) {
  return stopList.find(stopListItem => stopListItem.good === good.item?.id)?.replacements || null;
}

// для бесплатных соусов проверка через .id
export function getStopListSauceReplacements(stopList, sauce) {
  return stopList.find(stopListItem => stopListItem.good === sauce.id)?.replacements || null;
}
//для сортировки массива composition в checkout
export const getSortedProducts = (products) => {
  let goods = [];
  let upsales = [];
  let others = [];
  products.forEach((good) => {
    const { type, old_price } = good;
    if (type === 'good' && !old_price) {
      goods.push(good);
    } else if (type === 'upsale') {
      upsales.push(good);
    } else {
      others.push(good);
    }
  });
  return goods.concat(upsales, others);
};
