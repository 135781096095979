import * as types from './actionTypes';
import { CHANGE_LOADING_LIA_STATUS } from './actionTypes';

const initialState = {
  chats: [],
  activeChat: { is_closed: false, issue_id: null, messages: [] },
  attachment: null,
  chatStatus: { isLoadind: false, isLoaded: false, error: {} },
  messageStatus: { isSending: false, isSent: false, error: {} },
  unreadedResetStatus: { isReset: false, resetDone: false, error: {} },
  saveAttachmentStatus: { isSaving: false, isSaved: false, error: {} },
  unreadedMessages: [],
  loadingLia: false
};

export default function chatReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_CHATS_REQUEST:
      return {
        ...state,
        chatStatus: { isLoadind: true, isLoaded: false, error: '' }
      };

    case types.FETCH_CHATS_SUCCESS:
      return {
        ...state,
        chatStatus: { isLoadind: false, isLoaded: true, error: '' },
        chats: action.payload
      };

    case types.FETCH_CHATS_FAILURE:
      return {
        ...state,
        chatStatus: { isLoadind: false, isLoaded: false, error: action.payload }
      };

    case types.SET_ACTIVE_CHAT_SUCCESS:
      return {
        ...state,
        activeChat: action.payload
      };

    case types.SET_ACTIVE_CHAT_FAILURE:
      return {
        ...state
      };

    case types.CLOSE_ACTIVE_CHAT:
      return {
        ...state,
        chats: [...state.chats, { ...state.activeChat, is_closed: true }],
        activeChat: initialState.activeChat
      };

    case types.SEND_MESSAGE_REQUEST:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          messages: [...state.activeChat.messages, action.payload]
        },
        messageStatus: { isSending: true, isSent: false, error: '' }
      };

    case types.SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        messageStatus: { isSending: false, isSent: true, error: '' }
      };

    case types.SEND_MESSAGE_FAILURE:
      return {
        ...state,
        messageStatus: { isSending: false, isSent: false, error: action.payload }
      };

    case types.GET_LIA_RESPONSE:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          messages: [...state.activeChat.messages, action.payload]
        }
      };

    case types.SET_NEW_MESSAGE:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          messages: [...state.activeChat.messages, action.payload]
        }
      };

    case types.SET_UNREADED_MESSAGE:
      return {
        ...state,
        unreadedMessages: [...state.unreadedMessages, ...action.payload]
      };

    case types.RESET_UNREADED_MESSAGES_REQUEST:
      return {
        ...state,
        unreadedResetStatus: { isReset: true, resetDone: false, error: '' }
      };

    case types.RESET_UNREADED_MESSAGES_SUCCESS:
      return {
        ...state,
        unreadedResetStatus: { isReset: false, resetDone: true, error: '' },
        unreadedMessages: []
      };

    case types.RESET_UNREADED_MESSAGES_FAILURE:
      return {
        ...state,
        unreadedResetStatus: { isReset: false, resetDone: false, error: action.payload }
      };

    case types.SET_ISSUE_ID:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          issue_id: action.payload
        }
      };

    case types.SAVE_IMAGE_REQUEST:
      return {
        ...state,
        saveAttachmentStatus: { isSaving: true, isSaved: false, error: '' }
      };

    case types.SAVE_IMAGE_SUCCESS:
      return {
        ...state,
        saveAttachmentStatus: { isSaving: false, isSaved: true, error: '' },
        attachment: action.payload
      };

    case types.SAVE_IMAGE_FAILURE:
      return {
        ...state,
        saveAttachmentStatus: { isSaving: false, isSaved: false, error: action.payload }
      };

    case types.DELETE_IMAGE:
      return {
        ...state,
        attachment: ''
      };

    case types.RESET_CHAT_STATE:
      return {
        ...initialState
      };

    case types.CHANGE_LOADING_LIA_STATUS:
      return {
        ...state,
        loadingLia: action.payload
      };

    default:
      return state;
  }
}
