import * as immutable from 'object-path-immutable';
import * as types from './actionTypes';

const initialState = {
  list: [],
  isLoading: false,
  isLoaded: false,
  hasError: false,
  leftSidePizzaId: -1,
  rightSidePizzaId: -1,
  currentDough: '',
  currentSize: '',
  adding: false,
  added: false,
  stuffedCrust: 'none',
  stuffedCrustIsAdd: false,
};

export default function halvesReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        hasError: false,
      };

    case types.FETCH_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        list: action.payload,
      };
    }

    case types.FETCH_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        hasError: true,
      };

    case types.SELECT_PIZZA: {
      if (action.payload.side === 'left') {
        return immutable.set(state, 'leftSidePizzaId', action.payload.id);
      }

      return immutable.set(state, 'rightSidePizzaId', action.payload.id);
    }

    case types.CHANGE_DOUGH:
      return immutable.set(state, 'currentDough', action.payload.dough);

    case types.CHANGE_SIZE:
      return immutable.set(state, 'currentSize', action.payload.size);

    case types.TOGGLE_STUFFED_CRUST:
    case types.SELECT_STUFFED_CRUST:
      // return immutable.set(state, 'stuffedCrustIsAdd', action.payload.value);
      // return immutable.set(state, 'stuffedCrust', action.payload.value);
      return {
        ...state,
        stuffedCrust: action.payload.value,
        stuffedCrustIsAdd: action.payload.value !== 'none',
      };

    case types.SET_IS_ADDED:
      return {
        ...state,
        added: true,
      };

    case types.RESET:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}
