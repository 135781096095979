import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import io from 'socket.io-client';
import Echo from 'laravel-echo';
import _debounce from 'lodash/debounce';

// Styles
import styles from './Chat.styl';

// Components
import ChatButton from './partials/ChatButton/ChatButton';
import ChatMessages from './partials/ChatMessages/ChatMessages';
import ChatPreloader from './partials/ChatPreloader';

// Icons
import SendMessageIcon from '../../icons/send-message.svg';
import CloseChatIcon from '../../icons/cross.svg';
import AttachFileIcon from '../../icons/attach.svg';

// Actions
import {
  closeActiveChat,
  fetchChats,
  sendMessage,
  setNewMessage,
  setUnreadedMessage,
  saveImage,
  deleteImage
} from '../../state/modules/chat/actions';

// Config
import config from '../../config';

//Utils
import { getThisDate } from '../../utils/dateFormatter';
import { setGlobalMessage } from '../../utils/setGlobalMessage';

const cx = classNames.bind(styles);

const Chat = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit, reset } = useForm();
  const intl = useIntl();
  const maxFileSize = 1.4e7;
  const chats = useSelector(state => state.chat.chats);
  const loadingLia = useSelector(state => state.chat.loadingLia);
  const activeChat = useSelector(state => state.chat.activeChat);
  const unreadedMessages = useSelector(state => state.chat.unreadedMessages);
  const attachment = useSelector(state => state.chat.attachment);

  const [isVisible, setIsVisible] = useState(false);
  const [isMessagesVisible, setMessagesVisible] = useState(false);
  const [imageSource, setImageSource] = useState('');

  const messagesVisibleHandler = useCallback(() => {
    setTimeout(() => {
      setMessagesVisible(true);
    }, 500);
  }, []);

  const messagesHiddenHandler = useCallback(() => {
    setMessagesVisible(false);
  }, []);

  const handleOnDrop = useCallback((file, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      if (rejectedFiles[0].file.size > maxFileSize) {
        return setGlobalMessage(intl.formatMessage({ id: 'chat.maxFileSize' }));
      }
    }
    dispatch(saveImage(file[0]));
  }, [dispatch]);

  const deleteAttachmentHandler = useCallback(() => {
    dispatch(deleteImage());
  }, [dispatch]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpg, image/jpeg, image/gif, image/png',
    multiple: false,
    maxSize: maxFileSize,
    onDrop: handleOnDrop
  });

  useEffect(() => {
    dispatch(fetchChats());

    window.io = io;
    window.echo = new Echo({
      broadcaster: 'socket.io',
      host: config.socketUrl,
      transports: ['websocket', 'polling', 'flashsocket']
    });
  }, []);

  const issueID = useMemo(() => {
    return activeChat?.issue_id;
  }, [activeChat?.issue_id]);

  useEffect(() => {
    if (issueID) {
      window.echo
        .private(`issue.${issueID}`)
        .listen('.message.created', ({ message }) => {
          if (message.from.name !== 'Системный пользователь') {
            const formattedMessage = {
              is_message_from_user: false,
              text: message.text,
              time: getThisDate(message.created_at, {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit'
                }
              ),
              attachment_links: message.attachments
            };
            dispatch(setNewMessage(formattedMessage));
            // Это для уведомлений
            if (!isVisible) {
              dispatch(setUnreadedMessage([formattedMessage]));
            }
          }
        })
        .listen('.issue.updated.status', ({ status }) => {
          if (status.value === 'completed') {
            window.echo.leave(`issue.${issueID}`);
            dispatch(closeActiveChat());
          }
        });
    }

    return () => {
      if (issueID) {
        window.echo.leave(`issue.${issueID}`);
      }
    };
  }, [issueID]);

  const sendHandler = useCallback((message) => {
    if (typeof message === 'string') {
      dispatch(sendMessage(message));
    } else {
      dispatch(sendMessage(message.text));
    }
    deleteAttachmentHandler();
    return reset({ text: '' });
  }, [dispatch, deleteAttachmentHandler]);

  const sendHandlerDebounced = useCallback(
    _debounce(q => sendHandler(q), 300),
    []);

  // Открытие чата
  const openHandler = () => {
    setIsVisible(prev => !prev);
  };

  // Открытие просмотра изображения
  const viewImageHandler = src => {
    setImageSource(src);
  };

  return (
    <>
      <div className={cx(isVisible ? 'Chat' : 'Chat_hidden')}>
        <div className={cx('Chat__header')}>
          <h6>{intl.formatMessage({ id: 'chat.header' })}</h6>
          <CloseChatIcon onClick={openHandler} className={cx('Chat__buttons')} />
        </div>
        {isVisible && (
          <>
            <ChatMessages
              className={cx(isMessagesVisible ? 'Chat__messages' : 'Chat__messages_hidden')}
              chats={chats}
              activeChat={activeChat}
              viewImageHandler={viewImageHandler}
              messagesVisibleHandler={messagesVisibleHandler}
              messagesHiddenHandler={messagesHiddenHandler}
            />
            {!isMessagesVisible && (
              <div className={cx('Chat__spinner-container')}>
                <div className={cx('Chat__spinner')} />
              </div>
            )}
          </>
        )}
        {loadingLia && <ChatPreloader className={cx('Chat__preloader')} />}
        <div className={cx('Chat__input')}>
          <input
            type="text"
            placeholder={intl.formatMessage({ id: 'chat.input.placeholder' })}
            name="text"
            ref={register()}
            autoComplete="off"
            onKeyDown={e => e.key === 'Enter' && sendHandlerDebounced(e.target.value)}
          />
          {!attachment ? (
            <div {...getRootProps({ className: cx('Chat__file-input') })}>
              <input {...getInputProps()} />
              <AttachFileIcon width={17} height={18} className={cx('Chat__buttons')} />
            </div>
          ) : (
            <CloseChatIcon
              width={17}
              height={17}
              className={cx('Chat__buttons')}
              onClick={deleteAttachmentHandler}
            />
          )}
          <SendMessageIcon
            width={18}
            height={18}
            className={cx('Chat__buttons')}
            onClick={handleSubmit(sendHandler)}
          />
        </div>
      </div>
      <ChatButton
        isVisible={isVisible}
        openHandler={openHandler}
        unreadedMessages={unreadedMessages}
      />
      {imageSource && (
        <div className={cx('Chat__image-viewer')} onClick={() => setImageSource('')}>
          <img src={imageSource} alt="img" />
        </div>
      )}
    </>
  );
};

export default Chat;
