import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import qs from 'query-string';

// State
import orderApi from '../../state/modules/order/api';
import { isOrderDoneSelector } from '../../state/modules/order/selectors';

// Router
import { getRoute, paths } from '../../entry/routes';

// Config
import config from '../../config';

// Utils
import isomorphicCookies from '../../utils/cookie/isomorphicCookies';
import {useDispatch} from "react-redux";
import {saveTrackingOrderData} from "../../state/modules/order/actions";

export default function useOrderTracking() {
  const history = useHistory();
  const location = useLocation();
  const { alias } = useParams();
  const dispatch = useDispatch();

  const intervalRef = useRef();
  const [orderData, setOrderData] = useState(null);
  const isOrderDone = isOrderDoneSelector(orderData);
  const from_mail = +qs.parse(location.search).from_email;

  useEffect(() => {
    if (from_mail === 1) {
      sessionStorage.setItem('redirect', location.pathname);
    }
  }, []);

  const isOrderError = useMemo(() => {
    return (orderData?.client_number.includes('N'));
  }, [orderData]);

  const seo = useMemo(() => {
    const actionpay = isomorphicCookies().get('actionpay');
    const adv_uid = isomorphicCookies().get('adv_uid');

    return (
      <>
        {/* actionpay SEO */}
        {orderData && actionpay && (
          <img
            src={`https://apypp.com/ok/19425.png?actionpay=${actionpay}&apid=${alias}&price=${orderData.cart.discount_price}`}
            height="1"
            width="1"
            alt=""
          />
        )}
        {/* advertise SEO */}
        {orderData && adv_uid && (
          <img
            src={`//advertiseru.net/tracking/543413f72b13426b/img/?uid=${adv_uid}&order_id=${alias}&amount=${orderData.cart.discount_price}`}
            width="1"
            height="1"
            alt=""
          />
        )}
      </>
    );
  }, [alias, orderData?.cart?.discount_price]);

  const getOrderStatus = useCallback(() => {
    orderApi
      .getOrderStatus({ action: 'status', order_id: alias })
      .then(data => {
        setOrderData(data);
        dispatch(saveTrackingOrderData(data))
      })
      .catch(() => {
        history.push(getRoute(paths.home));
      });
  }, [alias]);

  // работает для Польши - если нас редиректнуло с 501
  useEffect(() => {
    if (config.legal === 'pl') {
      const { error } = qs.parse(location.search);

      if (error === '501') {
        history.push(getRoute(paths.order));
      }
    }
  }, []);

  useEffect(() => {
    if (!isOrderDone) {
      // если ордер_стаус еще не получен (ст 0) или мы отслеживаем курьера (ст 4), то запрос каждые 10 сек
      // в остальных статусах посылаем его раз в минуту
      const time =
        !orderData || Number(orderData.order_status) === 0 || Number(orderData.order_status) === 4
          ? 10000
          : 60000;

      if (!orderData) {
        getOrderStatus();
      }

      intervalRef.current = setInterval(() => {
        getOrderStatus();
      }, time);
    }

    return () => {
      if (!isOrderDone) {
        clearInterval(intervalRef.current);
      }
    };
  }, [orderData?.order_status]);

  return { orderData, seo, getOrderStatus, isOrderError };
}
