import Cookies from 'js-cookie';
import { timeZones } from "./constants";

const cookieTimeZone = Cookies?.get('timeZone');
export const timeZone = timeZones?.find((tz) => tz.id === cookieTimeZone)?.id || 'Europe/Moscow';

// Все нормальные браузеры спокойно парсят дату вида "2022-07-02 12:11:54". А Сафари нет, ему нужен "2022-07-02T12:11:54"
export const dateParse = (value) => {
  // возвращаем значение с T и Z, либо просто текущую дату
  return Date.parse(`${value.replace(/\s/g, 'T')}Z`);
};

// форматируем в зависимости от options
export const getThisDate = (
  value,
  options,
  separator,
) => {
  const parsedValue = typeof value === 'string' ? dateParse(value) : value;
  if (!parsedValue) {
    return '';
  }
  if (separator) {
    const date = new Intl.DateTimeFormat('ru', {
      timeZone,
      day: options.day,
      month: options.month,
      year: options.year,
    }).format(parsedValue);

    const time = new Intl.DateTimeFormat('ru', {
      timeZone,
      hour: options.hour,
      minute: options.minute,
      second: options.second,
    }).format(parsedValue);

    return `${date}${separator}${time}`;
  }
  return new Intl.DateTimeFormat('ru', { timeZone, ...options }).format(parsedValue);
};

// из 2022-07-02T12:11:54.000Z в 2022-07-02 12:11:54
export const createTimeStep = () => {
  return new Date().toISOString().split('.')[0].replace(/T/g, ' ');
};

export const dateEquals = (date1, date2, delta = 0) => {
  return Math.abs(date1.getTime() - date2.getTime()) <= delta;
};
