import * as types from './actionTypes';

export function showModalConfirm(params) {
  return {
    type: types.SHOW_MODAL_CONFIRM,
    payload: params,
  };
}

export function hideModalConfirm() {
  return { type: types.HIDE_MODAL_CONFIRM, payload: {} };
}
