import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

// Components
import DesktopView from './DesktopView';
import MobileView from './MobileView';
import Group from './partials/Group';

// State
import {
  onlyMoscowCitiesSelector,
  otherCitiesSelector,
  filteredCitiesByNameSelector,
} from '../../state/modules/city/selectors';

// Config
import config from '../../config';

export default function CitySelector(props) {
  const { className, isLanding } = props;

  const intl = useIntl();
  const isPhone = useSelector(state => state.responsive.isPhone);
  const list = useSelector(state => state.city.list);
  const query = useSelector(state => state.city.searchQuery);
  const moscowCities = useSelector(onlyMoscowCitiesSelector);
  const otherCities = useSelector(otherCitiesSelector);
  const filteredCities = useSelector(filteredCitiesByNameSelector);

  const getChildren = () => {
    if (query) {
      return filteredCities.length > 0 ? (
        <Group
          title={intl.formatMessage({ id: 'citySelector.foundCities' })}
          cities={filteredCities}
        />
      ) : (
        <div>{intl.formatMessage({ id: 'citySelector.cityNotFound' })}</div>
      );
    }

    if (config.legal === 'ru') {
      return (
        <>
          <Group title={intl.formatMessage({ id: 'citySelector.moscow' })} cities={moscowCities} />
          <Group
            title={intl.formatMessage({ id: 'citySelector.anotherCities' })}
            cities={otherCities}
          />
        </>
      );
    }

    return <Group title={intl.formatMessage({ id: 'citySelector.cities' })} cities={list} />;
  };

  const View = isPhone ? MobileView : DesktopView;

  return (
    <View className={className} moreThanOne={list.length > 1} isLanding={isLanding}>
      {getChildren()}
    </View>
  );
}

CitySelector.defaultProps = {
  className: '',
  isLanding: false,
};

CitySelector.propTypes = {
  className: PropTypes.string,
  isLanding: PropTypes.bool,
};
