import React from 'react';
import { injectIntl } from 'react-intl';
import stringIsEmail from 'validator/lib/isEmail';

// Config
import config from '../config';

export const getText = (id, values) => {
  const injectGetMessage = fn =>
    React.createElement(injectIntl(({ intl }) => fn(intl.formatMessage)));

  return injectGetMessage(getMessage => getMessage({ id }, values));
};
export function removeNumbers(str) {
  // Получаем последние 10 символов строки
  const last10 = str.slice(-10);
  // Проверяем, являются ли последние 10 символов числовыми
  if (/^\d{10}$/.test(last10)) {
    // Если являются, отрезаем их
    return str.slice(0, -10);
  }
  // Если не являются, возвращаем исходную строку
  return str;
}
export const composeValidators = (...validators) => (value, allValues) => {
  return validators.reduce((error, validator) => error || validator(value, allValues), undefined);
};

export const required = value => {
  return value ? undefined : getText('validators.required');
};

export const requiredNumber = value => {
  return value && value.length >= config.phone.digitsCount
    ? undefined
    : getText('validators.phone');
};

export const numberByCode = value => {
  const getRegExpCheck = tel => {
    if (config.legal === 'kz') {
      return /^\+7(?=4|5|6|7)/.test(tel);
    }

    if (config.legal === 'kg') {
      return /^\+996/.test(tel);
    }

    if (config.legal === 'by') {
      return /^\+375/.test(tel);
    }

    if (config.legal === 'pl') {
      return /^\+48/.test(tel);
    }

    return /^\+79/.test(tel);
  };

  return getRegExpCheck(value) ? undefined : getText('validators.phone');
};

export const isEmail = value => {
  return stringIsEmail(value, { allow_utf8_local_part: false })
    ? undefined
    : getText('validators.email');
};

export const strongPassword = value => {
  if (!new RegExp('^(?=.{6,})').test(value)) {
    return getText('validators.strongPassword.minSimbols');
  }

  if (!new RegExp('^(?=.*[a-z])').test(value)) {
    return getText('validators.strongPassword.lowerCase');
  }

  if (!new RegExp('^(?=.*[A-Z])').test(value)) {
    return getText('validators.strongPassword.upperCase');
  }

  if (!new RegExp('^(?=.*[0-9])').test(value)) {
    return getText('validators.strongPassword.requiredNumber');
  }

  return undefined;
};

export const passwordIsEqual = (otherField, textId) => (value, allValues) => {
  return value === allValues[otherField]
    ? undefined
    : getText(textId || 'validators.passwordIsEqual');
};

export const minWidth = number => (value = '') => {
  if (value.length) {
    return value.trim().length >= number ? undefined : getText('validators.minWidth', { number });
  }

  return false;
};

export const validDate = value => {
  if (!value) {
    return undefined;
  }

  const currentYear = new Date().getFullYear();
  const splittedDate = value.split('-');
  const diff = currentYear - Number(splittedDate[0]);

  return diff > 100 || diff < 14 ? getText('validators.invalidBithday') : undefined;
};

// форк 'attr-accept';
export const validateAttrType = (file, acceptedFiles) => {
  if (file && acceptedFiles) {
    const acceptedFilesArray = Array.isArray(acceptedFiles)
      ? acceptedFiles
      : acceptedFiles.split(',');
    const fileName = file.name || '';
    const mimeType = file.type || '';
    const baseMimeType = mimeType.replace(/\/.*$/, '');

    return acceptedFilesArray.some(type => {
      const validType = type.trim();

      if (validType.charAt(0) === '.') {
        return fileName.toLowerCase().endsWith(validType.toLowerCase());
      }

      if (validType.endsWith('/*')) {
        return baseMimeType === validType.replace(/\/.*$/, '');
      }
      return mimeType === validType;
    });
  }
  return true;
};
