import * as types from './actionTypes';

const initialState = {
  list: [],
  isLoading: false,
  isLoaded: false,
  error: '',
};

export default function sailplayReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_GIFTS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        list: [],
        error: '',
      };
    }

    case types.FETCH_GIFTS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        list: action.payload,
        error: '',
      };
    }

    case types.FETCH_GIFTS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        list: [],
        error: action.payload,
      };
    }

    default:
      return state;
  }
}
