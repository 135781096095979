import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import Button from '../../Button';
import Modal from '../../Modal';
import AddressSelector from '../../AddressSelector/DesktopView';
import RestaurantSelector from '../../RestaurantSelector/DesktopView';
import DeliveryTypeSwitcher from '../../DeliveryTypeSwitcher/DesktopView';

// State
import { minOrderSumSelector } from '../../../state/modules/order/selectors';

// Utils
import { formatMaxAddress } from '../../../utils/formatters';

// Icons
import PinIcon from '../../../icons/pin.svg';
import PinRedIcon from '../../../icons/map/pin-red.svg';
import Clock2Icon from '../../../icons/clock2.svg';
import CubeIcon from '../../../icons/cube.svg';

// Styles
import styles from './AddressButton.styl';

const cx = classNames.bind(styles);

export default function AddressButton(props) {
  const { className } = props;

  const intl = useIntl();
  const minOrderSum = useSelector(minOrderSumSelector);
  const deliveryType = useSelector(state => state.order.checkout.deliveryType);
  const deliveryAddress = useSelector(state => state.order.checkout.deliveryAddress);
  const deliveryRestaurant = useSelector(state => state.order.checkout.deliveryRestaurant);
  const deliveryTimeType = useSelector(state => state.order.checkout.deliveryTimeType);
  const deliveryTime = useSelector(state => state.order.checkout.deliveryTime);
  const pickupRestaurant = useSelector(state => state.order.checkout.pickupRestaurant);
  const unit = useSelector(state => state.city.userCity.currency?.unit);

  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);

  const isIntimeOrder = deliveryTimeType === 'intime' && deliveryTime.hoursMinutes;

  const handleOnAddressModalOpen = () => {
    setIsAddressModalOpen(true);
  };

  const handleOnAddressModalClose = () => {
    setIsAddressModalOpen(false);
  };

  const getButton = () => {
    // если мы выбрали адрес доставки
    if (deliveryType === 'delivery' && deliveryRestaurant) {
      return (
        <button
          className={cx('AddressButton', 'AddressButton_active', className)}
          onClick={handleOnAddressModalOpen}
          onKeyDown={e => e.key === 'Enter' && handleOnAddressModalOpen()}
          type="button"
        >
          {/* адрес доставки */}
          <div className={cx('AddressButton__line', 'AddressButton__line_medium')}>
            <PinRedIcon className={cx('AddressButton__icon', 'AddressButton__icon_redpin')} />
            <span className={cx('AddressButton__span')}>{formatMaxAddress(deliveryAddress)}</span>
          </div>
          {/* время доставки */}
          {deliveryRestaurant.delivery_time !== undefined && !isIntimeOrder ? (
            <div className={cx('AddressButton__line')}>
              <span className={cx('AddressButton__border')}>
                <Clock2Icon className={cx('AddressButton__icon')} />
              </span>
              {intl.formatMessage(
                { id: 'header.minTime' },
                {
                  time: deliveryRestaurant.delivery_time,
                  unit: intl.formatMessage({ id: 'min' }),
                },
              )}
            </div>
          ) : null}
          {/* минимальная сумма */}
          <div className={cx('AddressButton__line')}>
            <span className={cx('AddressButton__border')}>
              <CubeIcon className={cx('AddressButton__icon')} />
            </span>
            {intl.formatMessage({ id: 'header.minSum' }, { sum: minOrderSum, currency: unit })}
          </div>
        </button>
      );
    }

    // если мы выбрали адрес самовывоза
    if (deliveryType === 'pickup' && pickupRestaurant) {
      return (
        <button
          className={cx('AddressButton', 'AddressButton_active', className)}
          onClick={handleOnAddressModalOpen}
          onKeyDown={e => e.key === 'Enter' && handleOnAddressModalOpen()}
          type="button"
        >
          {/* адрес доставки */}
          <div className={cx('AddressButton__line', 'AddressButton__line_medium')}>
            <PinRedIcon className={cx('AddressButton__icon', 'AddressButton__icon_redpin')} />
            <span className={cx('AddressButton__span')}>{pickupRestaurant.address}</span>
          </div>
          {/* минимальная сумма */}
          <div className={cx('AddressButton__line')}>
            <span className={cx('AddressButton__border')}>
              <CubeIcon className={cx('AddressButton__icon')} />
            </span>
            {intl.formatMessage({ id: 'order.pickup.title' })}
          </div>
        </button>
      );
    }

    // если мы не выбрали адрес доставки / если выбрали самовывоз
    return (
      <Button
        className={cx('AddressButton', 'AddressButton_inactive', className)}
        onClick={handleOnAddressModalOpen}
      >
        <PinIcon className={cx('AddressButton__icon', 'AddressButton__icon_whitepin')} />
        {intl.formatMessage({ id: 'select.address' })}
      </Button>
    );
  };

  return (
    <>
      {getButton()}
      <Modal
        className={cx('AddressButton__modal')}
        isOpen={isAddressModalOpen}
        onClose={handleOnAddressModalClose}
      >
        <DeliveryTypeSwitcher className={cx('AddressButton__types')} />
        {deliveryType === 'delivery' && (
          <AddressSelector onSuccessCallback={handleOnAddressModalClose} />
        )}
        {deliveryType === 'pickup' && (
          <RestaurantSelector onSuccessCallback={handleOnAddressModalClose} />
        )}
      </Modal>
    </>
  );
}

AddressButton.defaultProps = {
  className: '',
};

AddressButton.propTypes = {
  className: PropTypes.string,
};
