import React, { createRef, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Field, Form as FinalForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { OnChange } from 'react-final-form-listeners';
import classNames from 'classnames/bind';

import { composeValidators, isEmail, required } from '../../../utils/validators';
import * as userActions from '../../../state/modules/user/actions';

import Form from '../../Form';
import InputField from '../../InputField';
import PhoneField from '../../PhoneField';
import RadioField from '../../RadioField';

import Button from '../../Button';
import FieldError from '../../FieldError';

import styles from './RecoveryPasswordFirstStepForm.styl';

const cx = classNames.bind(styles);

const ConditionField = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

const WhenFieldChanges = ({ field, becomes, set, to }) => (
  <Field name={set} subscription={{}}>
    {(
      // No subscription. We only use Field to get to the change function
      { input: { onChange } }
    ) => (
      <OnChange name={field}>
        {value => {
          if (value === becomes) {
            onChange(to);
          }
        }}
      </OnChange>
    )}
  </Field>
);

export default function RecoveryPasswordFirstStepForm(props) {
  const [recPass, setRecPass] = useState('recovery_password')

  const { phone, email, codeIsSending, codeIsSended, error }
    = useSelector(state => state.user.recoveryPassword);

  const dispatch = useDispatch();

  const formRef = createRef();
  const radioPhoneRef = createRef();
  const radioEmailRef = createRef();

  useEffect(() => {
    if (codeIsSended) {
      dispatch(userActions.recoveryPasswordSendConfirmCodeReset());
      props.onNext();
    }
  }, [codeIsSended]);

  const initialValues = {
    type: recPass,
    phone: phone,
    email: email
  };

  // Очищаем поля и ошибки валидации при переключении радио-кнопок
  const clearField = _currentRef => {
    const { phone, email } = initialValues;
    const { value } = _currentRef.current.props;

    const _init = {
      phone,
      email,
      type: value
    };

    // Сбрасываем форму
    formRef.current.form.reset(_init);

    // Сбрасываем ошибки submit
    if (error) {
      dispatch(userActions.clearUserErrors("recoveryPassword"));
    }
  };

  const handleSubmit = ({ phone, email, type }) => {
    setRecPass(type)
    if (email) {
      dispatch(userActions.rememberEmail(email));
      dispatch(userActions.rememberPhone(""));
      return dispatch(userActions.recoveryPasswordSendConfirmCode({ email, type:"recovery_password",channel: "email" }));
    } else {
      dispatch(userActions.rememberPhone(phone));
      dispatch(userActions.rememberEmail(""));
      return dispatch(userActions.recoveryPasswordSendConfirmCode({ phone, type:"recovery_password", channel: "flash_call"}));
    }
  };

  return (
    <div>
      <FinalForm
        ref={formRef}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        render={({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <WhenFieldChanges
              field="recovery_password"
              becomes={false}
              set="phone"
              to={undefined}
            />
            <WhenFieldChanges
              field="mail_recovery_password"
              becomes={false}
              set="email"
              to={undefined}
            />
            <Form.Row>
              <Field name="type" type="radio" value="recovery_password" ref={radioPhoneRef}>
                {({ input }) => (
                  <Form.Field width="one-half">
                    <FormattedMessage id="field.phone.label">
                      {label => (
                        <RadioField
                          {...input}
                          label={label}
                          onChange={e => {
                            input.onChange(e);
                            clearField(radioPhoneRef);
                          }}
                        />
                      )}
                    </FormattedMessage>
                  </Form.Field>
                )}
              </Field>
              <Field
                name="type"
                type="radio"
                value="mail_recovery_password"
                ref={radioEmailRef}
              >
                {({ input }) => (
                  <Form.Field width="one-half">
                    <RadioField
                      {...input}
                      label="Email"
                      onChange={e => {
                        input.onChange(e);
                        clearField(radioEmailRef);
                      }}
                    />
                  </Form.Field>
                )}
              </Field>
            </Form.Row>
            <ConditionField when="type" is="recovery_password">
              <Field name="phone" validate={required}>
                {({ meta, input }) => (
                  <Form.Field>
                    <FormattedMessage id="recoveryPassword.type.phone">
                      {label => <PhoneField {...input} label={label} />}
                    </FormattedMessage>
                    <FieldError name="phone" />
                    <Form.Error {...meta} error={error} />
                  </Form.Field>
                )}
              </Field>
            </ConditionField>
            <ConditionField when="type" is="mail_recovery_password">
              <Field name="email" validate={composeValidators(required, isEmail)}>
                {({ meta, input }) => (
                  <Form.Field>
                    <InputField {...input} type="email" label="Email" />
                    <FieldError name="email" />
                    <Form.Error {...meta} error={error} />
                  </Form.Field>
                )}
              </Field>
            </ConditionField>
            <div className={cx("Actions")}>
              <Button className={cx("Actions__item")} onClick={props.onCancel} link>
                <FormattedMessage id="button.cancel" />
              </Button>
              <Button
                className={cx("Actions__item")}
                type="submit"
                isLoading={codeIsSending}
              >
                <FormattedMessage id="button.continue" />
              </Button>
            </div>
          </Form>
        )}
      />
    </div>
  );
}

RecoveryPasswordFirstStepForm.propTypes = {
  onNext: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};
