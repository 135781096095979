import * as types from './actionTypes';

const initialState = {
  isPhone: false,
  isTablet: false,
  isRealPhone: false,
  isRealTablet: false,
  browserName: 'chrome',
  OS: null,
};

export default function responsiveReducer(state = initialState, action) {
  switch (action.type) {
    case types.MEDIA_CHANGED:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
