import * as constructorTypes from '../../../modules/constructor/actionTypes';
import * as catalogTypes from '../../../modules/catalog/actionTypes';

function getIngredientForConstructor(findedItem, payload, isRemoved) {
  const { variationId, ingredientId, included } = payload;

  if (findedItem) {
    const findedVar = findedItem.variations.find(vari => vari.id === variationId);

    if (findedVar) {
      let findedIngred = {};

      if (!included) {
        findedIngred = findedVar.available_ingredients.find(ingr => ingr.id === ingredientId);
      } else {
        findedIngred = findedVar.include_ingredients.find(ingr => ingr.id === ingredientId);
      }

      if (findedIngred) {
        return {
          name: findedIngred.name,
          removed: isRemoved,
        };
      }
    }
  }

  return null;
}

export function changeIngredients(action, prevState, nextState) {
  let ingredient = {};

  const { id, variationId, ingredientId } = action.payload;

  switch (action.type) {
    case constructorTypes.ADD_PORTION: {
      const good = nextState.pizzaConstructor.goods[id];

      ingredient = getIngredientForConstructor(good, action.payload, false);

      break;
    }

    case constructorTypes.REMOVE_PORTION:
    case constructorTypes.REMOVE_INGREDIENT: {
      const good = nextState.pizzaConstructor.goods[id];

      ingredient = getIngredientForConstructor(good, action.payload, true);

      break;
    }

    case catalogTypes.REMOVE_INGREDIENT: {
      const findedItem = nextState.catalog.products.list.find(item => item.id === id);

      if (findedItem) {
        const findedVar = findedItem.variations.find(vari => vari.id === variationId);

        if (findedVar) {
          const findedIngred = findedVar.include_ingredients.find(ingr => ingr.id === ingredientId);
          ingredient = {
            name: findedIngred.name,
            removed: findedIngred.removed,
          };
        }
      }

      break;
    }

    default:
      break;
  }

  const outData = {
    event: 'UA event',
    eventCategory: 'Изменение ингредиентов',
    eventAction: ingredient.removed ? 'удаление' : 'добавление',
    eventLabel: (ingredient.name || '').toLowerCase(),
  };

  return outData;
}
