import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Field from './Field';
import Group from './Group';
import Row from './Row';
import Error from './Error';
import Success from './Success';

import styles from './Form.styl';

const cx = classNames.bind(styles);

const Form = ({ className, children, instanceRef, ...otherProps }) => (
  <form {...otherProps} ref={instanceRef} className={cx('Form', className)}>
    {children}
  </form>
);

Form.defaultProps = {
  className: '',
};

Form.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  instanceRef: PropTypes.func,
};

Form.Field = Field;
Form.Group = Group;
Form.Row = Row;
Form.Error = Error;
Form.Success = Success;

export default Form;
