/* eslint-disable no-param-reassign */
export function convertCartProductToAnalytic(product) {
  const { item } = product;

  // Init Output Product
  const outProd = {
    name: item.name,
    id: item.id,
    price:
      product.discount_price || product.discount_price === 0
        ? product.discount_price
        : product.price,
    category: item.category,
    count: product.count,
  };

  // (тип теста)? + size + unit
  let variant = '';
  if (item.kind && item.kind.name) {
    variant += item.kind.name;
  }

  if (variant) {
    variant += ', ';
  }

  if (item.size && item.size.value) {
    variant += item.size.value;
  }

  if (item.size && item.size.unit) {
    variant += ` ${item.size.unit}`;
  }

  variant = variant.trim();
  if (variant) {
    outProd.variant = variant;
  }

  // Если категория "Пицца"
  if (item.category_id === 1) {
    // Толщина теста
    if (item.dough) {
      outProd.dimension1 = item.dough;
    }

    // Половинки
    if (item.name.includes('+')) {
      outProd.name = `Половинки ${item.name}`;
    }

    // Сырный борт
    const stuffedCrusts = {
      none: 'Стандартный',
      cheese: 'Сырный',
      sausage: 'Колбасный',
    };

    outProd.dimension18 = stuffedCrusts[item.stuffed_crust] || '';
  }

  // Товар в подарок. Варианты: «Не подарок»/«За бонусы»/«По промокоду»/«За самовывоз»
  switch (product.type) {
    case 'gift':
      outProd.name = `Купон ${outProd.name}`;
      outProd.dimension17 = 'По промокоду';
      outProd.price = outProd.price || '0.00';
      break;

    case 'bonus':
      outProd.name = `${outProd.name} (Папа Бонус)`;
      outProd.dimension17 = 'За бонусы';
      outProd.price = '0.00';
      break;

    case 'good':
    default:
      outProd.dimension17 = 'Не подарок';
      break;
  }

  // Соусы в подарок
  if (item.added_sauces && item.added_sauces.length > 0) {
    const sauces = [];
    item.added_sauces.forEach(sauce => {
      sauces.push(`${sauce.name}, ${sauce.size.value} ${sauce.size.unit}`);
    });

    outProd.dimension4 = sauces.join(', ');
  }

  // Дополнительные ингредиенты
  if (item.added_ingredients && item.added_ingredients.length > 0) {
    const added = [];
    item.added_ingredients.forEach(ingredient => {
      added.push(`${ingredient.name} ${ingredient.count} порции`);
    });

    outProd.dimension3 = added.join(', ');
  }

  return outProd;
}

export function getProductsFromCart(cart) {
  return cart.composition.map(product => convertCartProductToAnalytic(product));
}

export function addUserId(objToAssign, nextState) {
  if (nextState && nextState.user.profile && nextState.user.profile.id) {
    objToAssign.userID = nextState.user.profile.id;
  }

  return objToAssign;
}
