import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './Form.styl';

const cx = classNames.bind(styles);

const Success = ({ className, message, dirtySinceLastSubmit }) => {
  if (message && !dirtySinceLastSubmit) {
    return <div className={cx('Form__success', className)}>{message}</div>;
  }
  return null;
};

Success.defaultProps = {
  className: '',
  dirtySinceLastSubmit: false,
};

Success.propTypes = {
  className: PropTypes.string,
  message: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dirtySinceLastSubmit: PropTypes.bool,
};

export default Success;
