import * as types from './actionTypes';

const initialState = {
  list: [],
  isLoading: false,
  isLoaded: false,
  error: '',
};

export default function bannerReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_BANNERS_REQUEST:
      return {
        ...state,
        list: [],
        isLoading: true,
        isLoaded: false,
        error: '',
      };

    case types.FETCH_BANNERS_SUCCESS:
      return {
        ...state,
        list: action.payload,
        isLoading: false,
        isLoaded: true,
        error: '',
      };

    case types.FETCH_BANNERS_FAILURE:
      return {
        ...state,
        list: [],
        isLoading: false,
        isLoaded: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
