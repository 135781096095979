import { get, post, put } from '../../../utils/api';
import { withUnauthorizedToken } from '../../../utils/auth';

export default {
  getRestaurantDetails: params => get({ url: 'restaurant/details', data: withUnauthorizedToken(params) }),
  getRestaurantDeliveryTime: params => get({ url: 'restaurant/delivery-time', data: params }),
  setOrderRating: params => put({ url: 'order-rate/save', data: withUnauthorizedToken(params) }),
  getIntervals: params => get({ url: 'restaurant/intervals', data: params }),
  getOrderStatus: params => get({ url: `order/status`, data: withUnauthorizedToken(params) }),
  getList: page => get({ url: 'order/list', data: { page } }),
  pay: params => post({ url: 'order/save', data: withUnauthorizedToken(params) }),
  cancelOrder: params => post({ url: 'order/cancel', data: withUnauthorizedToken(params) }),
  getCancelShortcuts: () => get({ url: 'order/cancel-shortcuts' }),
  sberValidation: order =>
    post({
      url: 'payment/sberbank-button-success-callback-for-frontend',
      data: withUnauthorizedToken(order),
    }),
  applePayConfirmation: params =>
    post({ url: 'sberbank-payment/apple-pay-confirmation', data: params }),
  applePayConfirmationPGW: params =>
    post({ url: 'pay-gateway/apple-pay-confirmation', data: params }),
};
