import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Transition } from 'react-transition-group';

// Utils
import { disableBodyScroll, clearAllBodyScrollLocks } from '../../utils/body-scroll-lock';

// Styles
import styles from './Modal.styl';

const cx = classNames.bind(styles);

const modalRoot = typeof window !== 'undefined' ? document.getElementById('modal-root') : null;

export default function Modal(props) {
  const { className, children, isOpen, onClose, direction, transparent, exit } = props;

  const contentRef = useRef(null);
  const overlayRef = useRef(null);
  const isPhone = useSelector(state => state.responsive.isPhone);
  const isExitExist = exit || exit === 0;

  const handleOutsideClick = e => {
    if (isOpen && !contentRef.current.contains(e.target)) {
      onClose();
    }
  };

  const onEscPress = e => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('keydown', onEscPress, { passive: true });
      const header = document.getElementById('Header');
      const headerSticky = document.getElementById('Header_sticky');
      disableBodyScroll(overlayRef.current, {
        reserveScrollBarGap: true,
        scrollBarGapElements: [headerSticky, header],
      });
    }

    return () => {
      if (isOpen) {
        window.removeEventListener('keydown', onEscPress);
        clearAllBodyScrollLocks();
      }
    };
  }, [isOpen]);

  if (isPhone) {
    return (
      modalRoot &&
      createPortal(
        <Transition
          in={isOpen}
          timeout={{ enter: 0, exit: isExitExist ? exit : 700 }}
          mountOnEnter
          unmountOnExit
        >
          {state => (
            <>
              <div
                className={cx('ModalPhone__overlay', `ModalPhone__overlay_${state}`)}
                onClick={onClose}
                onKeyDown={() => null}
                tabIndex={0}
                role="button"
              />
              <div
                className={cx('ModalPhone', `ModalPhone_${state}`, `ModalPhone_${direction}`, {
                  ModalPhone_transparent: transparent,
                })}
                ref={overlayRef}
                id="modal"
              >
                <div className={cx('ModalPhone__content', className)}>{children}</div>
              </div>
            </>
          )}
        </Transition>,
        modalRoot,
      )
    );
  }

  return (
    modalRoot &&
    createPortal(
      <Transition
        in={isOpen}
        timeout={{ enter: 0, exit: isExitExist ? exit : 350 }}
        mountOnEnter
        unmountOnExit
      >
        {state => (
          <div
            className={cx('Modal', `Modal_${state}`)}
            onClick={handleOutsideClick}
            onKeyDown={() => null}
            tabIndex={0}
            role="button"
            ref={overlayRef}
            id="modal"
          >
            <div className={cx('Modal__content', className)} ref={contentRef}>
              {children}
            </div>
          </div>
        )}
      </Transition>,
      modalRoot,
    )
  );
}

Modal.defaultProps = {
  className: '',
  direction: 'top',
  isOpen: false,
  onClose: () => null,
  children: null,
  transparent: false,
  exit: undefined,
};

Modal.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.oneOf(['top', 'left']),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.any,
  transparent: PropTypes.bool,
  exit: PropTypes.number,
};
