import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Icons
import CloseIcon from '../../../icons/close.svg';

// Styles
import styles from './NavBar.styl';

const cx = classNames.bind(styles);

export default function NavBar(props) {
  const { className, children, onClose } = props;

  return (
    <div className={cx('NavBar', className)}>
      {children}
      <button className={cx('NavBar__close')} onClick={onClose} type="button">
        <CloseIcon width={16} height={16} />
      </button>
    </div>
  );
}

NavBar.defaultProps = {
  className: '',
  children: '',
};

NavBar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  onClose: PropTypes.func.isRequired,
};
