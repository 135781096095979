// форк react-onclickout, исправлены пассивные прослушиватели

import React, { Component, Children } from 'react';
import { findDOMNode } from 'react-dom';

export default class ClickOutComponent extends Component {
  componentDidMount() {
    const self = this;
    let elTouchIsClick = true;
    let documentTouchIsClick = true;
    const el = findDOMNode(this);

    self.__documentTouchStarted = function (e) {
      el.removeEventListener('click', self.__elementClicked);
      document.removeEventListener('click', self.__documentClicked);
    };

    self.__documentTouchMoved = function (e) {
      documentTouchIsClick = false;
    };

    self.__documentTouchEnded = function (e) {
      if (documentTouchIsClick) self.__documentClicked(e);
      documentTouchIsClick = true;
    };

    self.__documentClicked = function (e) {
      if ((e.__clickedElements || []).indexOf(el) !== -1) return;

      const clickOutHandler = self.onClickOut || self.props.onClickOut;
      if (!clickOutHandler) {
        return console.warn('onClickOut is not defined.');
      }

      clickOutHandler.call(self, e);
    };

    self.__elementTouchMoved = function (e) {
      elTouchIsClick = false;
    };

    self.__elementTouchEnded = function (e) {
      if (elTouchIsClick) self.__elementClicked(e);
      elTouchIsClick = true;
    };

    self.__elementClicked = function (e) {
      e.__clickedElements = e.__clickedElements || [];
      e.__clickedElements.push(el);
    };

    setTimeout(function () {
      if (self.__unmounted) return;
      self.toggleListeners('addEventListener');
    }, 0);
  }

  componentWillUnmount() {
    this.toggleListeners('removeEventListener');
    this.__unmounted = true;
  }

  toggleListeners(listenerMethod) {
    const el = findDOMNode(this);

    el[listenerMethod]('touchmove', this.__elementTouchMoved, { passive: true });
    el[listenerMethod]('touchend', this.__elementTouchEnded, { passive: true });
    el[listenerMethod]('click', this.__elementClicked, { passive: true });

    document[listenerMethod]('touchstart', this.__documentTouchStarted, { passive: true });
    document[listenerMethod]('touchmove', this.__documentTouchMoved, { passive: true });
    document[listenerMethod]('touchend', this.__documentTouchEnded, { passive: true });
    document[listenerMethod]('click', this.__documentClicked, { passive: true });
  }

  render() {
    const { children } = this.props;

    return Array.isArray(children) ? <div>{children}</div> : Children.only(children);
  }
}
