import _get from 'lodash/get';
import * as immutable from 'object-path-immutable';
import * as types from './actionTypes';

const initialState = {
  list: {
    data: [],
    isLoading: false,
    isLoaded: false,
    error: '',
  },
  unconditionalList: {
    isLoading: false,
    isLoaded: false,
    data: {},
    error: '',
  },
  gifts: [],
  data: {},
  isApplying: false,
  isApplied: false,
  error: '',
  isLoadingStockApply: false,
};

export default function stockReducer(state = initialState, action) {
  switch (action.type) {
    case types.IS_LOADING_STOCK_APPLY:
      return {
        ...state,
        isLoadingStockApply: action.payload,
      };

    case types.FETCH_LIST_REQUEST:
      return immutable.assign(state, 'list', {
        isLoading: true,
        isLoaded: false,
        error: '',
      });

    case types.FETCH_LIST_SUCCESS:
      return immutable.assign(state, 'list', {
        data: action.payload,
        isLoading: false,
        isLoaded: true,
      });

    case types.FETCH_LIST_FAILURE:
      return immutable.assign(state, 'list', {
        data: [],
        isLoading: false,
        isLoaded: false,
        error: action.payload,
      });

    //

    case types.APPLY_STOCK_REQUEST:
      return {
        ...state,
        isApplying: true,
        isApplied: false,
        error: '',
      };

    case types.APPLY_STOCK_SUCCESS:
      return {
        ...state,
        isApplying: false,
        isApplied: true,
        data: action.payload,
        error: '',
      };

    case types.APPLY_STOCK_FAILURE:
      return {
        ...state,
        data: {},
        isApplying: false,
        isApplied: false,
        error: action.payload,
      };

    //

    case types.SEND_STOCK_APPLY_EXTRA_REQUEST:
      return {
        ...state,
        error: '',
      };

    case types.SEND_STOCK_APPLY_EXTRA_SUCCESS:
      return {
        ...state,
        error: '',
      };

    case types.SEND_STOCK_APPLY_EXTRA_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    //

    case types.CLEAR_STOCK_REQUEST:
      return {
        ...state,
        error: '',
      };

    case types.CLEAR_STOCK_SUCCESS:
      return {
        ...state,
        data: {},
        gifts: [],
        isApplied: false,
        error: '',
      };

    case types.CLEAR_STOCK_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case types.GET_UNCONDITIONAL_LIST_REQUEST:
      return {
        ...state,
        unconditionalList: {
          ...state.unconditionalList,
          isLoading: true,
          isLoaded: false,
          error: '',
        },
      };

    case types.GET_UNCONDITIONAL_LIST_SUCCESS:
      return {
        ...state,
        unconditionalList: {
          ...state.unconditionalList,
          data: action.payload,
          isLoading: false,
          isLoaded: true,
          error: '',
        },
      };

    case types.GET_UNCONDITIONAL_LIST_FAILURE:
      return {
        ...state,
        unconditionalList: {
          ...state.unconditionalList,
          data: {},
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };

    case types.REMOVE_INGREDIENT: {
      const goods = _get(
        state,
        `data.details.${action.payload.stockRole}[${action.payload.index}].goods`,
        [],
      );

      const indexOfGoodToUpdate = goods.findIndex(good => good.id === action.payload.goodId);
      const indexOfVariationToUpdate = goods[indexOfGoodToUpdate].variations.findIndex(
        variation => variation.id === action.payload.variationId,
      );

      return immutable.update(
        state,
        [
          'data',
          'details',
          action.payload.stockRole,
          action.payload.index,
          'goods',
          indexOfGoodToUpdate,
          'variations',
          indexOfVariationToUpdate,
          'include_ingredients',
        ],
        ingredients => {
          const indexOfIngredientToUpdate = ingredients.findIndex(
            ingredient => ingredient.id === action.payload.ingredientId,
          );
          return immutable.update(ingredients, [indexOfIngredientToUpdate], ingredient =>
            immutable.set(ingredient, 'removed', !ingredient.removed),
          );
        },
      );
    }

    case types.CANCEL_REMOVED_INGREDIENTS: {
      const goods = _get(
        state,
        `data.details.${action.payload.stockRole}[${action.payload.index}].goods`,
        [],
      );
      const indexOfGoodToUpdate = goods.findIndex(good => good.id === action.payload.goodId);
      const indexOfVariationToUpdate = goods[indexOfGoodToUpdate].variations.findIndex(
        variation => variation.id === action.payload.variationId,
      );

      return immutable.update(
        state,
        [
          'data',
          'details',
          action.payload.stockRole,
          action.payload.index,
          'goods',
          indexOfGoodToUpdate,
          'variations',
          indexOfVariationToUpdate,
          'include_ingredients',
        ],
        ingredients =>
          (ingredients || []).map(ingredient => immutable.set(ingredient, 'removed', false)),
      );
    }

    case types.CLEAR_STOCK_ERRORS_SUCCESS:
      return {
        ...state,
        error: '',
      };

    case types.APPLY_STOCK_GIFTS: {
      return {
        ...state,
        gifts: action.payload,
      };
    }

    default:
      return state;
  }
}
