import config from '../../../config';
import googlePayApi from './googlePayCloudApi';
import * as types from './actionTypes';
import payTypesApi from './api';

export const fetchApplePayDeliveryPrice = () => {
  return dispatch => {
    dispatch({ type: types.FETCH_APPLE_PAY_DELIVERY_PRICE_REQUEST });
    return payTypesApi
      .getApplePayDeliveryPrice()
      .then(data => {
        return dispatch({ type: types.FETCH_APPLE_PAY_DELIVERY_PRICE_SUCCESS, payload: data });
      })
      .catch(({ message }) => {
        return dispatch({ type: types.FETCH_APPLE_PAY_DELIVERY_PRICE_FAILURE, payload: message });
      });
  };
};

export const fetchApplePayDeliveryPriceIsNeeded = () => {
  return (dispatch, getState) => {
    if (!getState().payTypes.applePayDeliveryPrice.isLoaded) {
      return dispatch(fetchApplePayDeliveryPrice());
    }

    return false;
  };
};

export const fetchApplePayStatus = restaurantId => {
  return dispatch => {
    dispatch({ type: types.FETCH_APPLE_PAY_STATUS_REQUEST });
    return payTypesApi
      .getApplePayStatus(restaurantId)
      .then(data => {
        return dispatch({ type: types.FETCH_APPLE_PAY_STATUS_SUCCESS, payload: data });
      })
      .catch(({ message }) => {
        return dispatch({ type: types.FETCH_APPLE_PAY_STATUS_FAILURE, payload: message });
      });
  };
};

export const fetchApplePayStatusIsNeeded = () => {
  return (dispatch, getState) => {
    if (!getState().payTypes.applePayStatus.isLoaded) {
      return dispatch(fetchApplePayStatus());
    }

    return false;
  };
};

export const initGooglePay = isAvailable => {
  return (dispatch, getState) => {
    dispatch({ type: types.INIT_GOOGLE_PAY_REQUEST });

    if (getState().responsive.browserName === 'safari') {
      return dispatch({ type: types.INIT_GOOGLE_PAY_FAILURE, payload: 'Safari :(' });
    }

    return googlePayApi
      .loadScript()
      .then(() =>
        googlePayApi
          .readyToPayRequest()
          .then(response => {
            if (response.result && isAvailable) {
              return dispatch({ type: types.INIT_GOOGLE_PAY_SUCCESS });
            }

            return dispatch({
              type: types.INIT_GOOGLE_PAY_FAILURE,
              payload: 'Google Pay is not available',
            });
          })
          .catch(({ message }) => {
            return dispatch({ type: types.INIT_GOOGLE_PAY_FAILURE, payload: message });
          }),
      )
      .catch(({ message }) => {
        return dispatch({ type: types.INIT_GOOGLE_PAY_FAILURE, payload: message });
      });
  };
};

export const initApplePay = () => {
  return dispatch => {
    if (window.ApplePaySession) {
      return window.ApplePaySession.canMakePaymentsWithActiveCard(config.merchantID)
        .then(canMakePayments => {
          if (canMakePayments) {
            return dispatch({ type: types.INIT_APPLE_PAY, data: { applePay: true } });
          }

          return false;
        })
        .catch(() => {});
    }

    return false;
  };
};
