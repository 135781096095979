/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Styles
import styles from './InputField.styl';

const cx = classNames.bind(styles);

export default function InputField(props) {
  const {
    className,
    label,
    isNumberInput,
    isPlainInput,
    isError,
    //
    inputClassname,
    name,
    required,
    disabled,
    _ref,
    register,
    autoComplete,
    onBlur,
    ...otherProps
  } = props;

  const inputProps = {
    ...otherProps,
    className: cx('InputField__field', inputClassname, {
      InputField__field_plain: isPlainInput,
    }),
    name,
    disabled,
    required,
    ref: _ref || register,
    id: name,
  };

  const [styleStandart, setStyleStandart] = useState(false);

  const onNumberChange = e => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      inputProps.onChange(e);
    }
  };

  const moveCaret = (e) => {
    setStyleStandart(true)
    isNumberInput ? onNumberChange(e) : inputProps.onChange(e)
  }

  const onBlurHandler = (e) => {
    setStyleStandart(false)
    onBlur && onBlur(e)
  }

  useEffect(() => {
    styleStandart && inputProps.ref && inputProps.ref.current?.setSelectionRange(inputProps.ref.current.value.length, inputProps.ref.current.value.length)
  }, [styleStandart])


  return (
    <div
      className={cx('InputField', className, {
        InputField_disabled: disabled,
        InputField_error: isError,
      })}
    >
      {Boolean(label) && (
        <label className={cx('InputField__label')} htmlFor={name}>
          {label} {required && '*'}
        </label>
      )}
      <input {...inputProps} className={cx(inputProps.className, {'InputField__standart': styleStandart})} autoComplete={autoComplete} onClick={moveCaret} onBlur={onBlurHandler} onChange={moveCaret} />
    </div>
  );
}

InputField.defaultProps = {
  className: '',
  inputClassname: '',
  type: 'text',
  required: false,
  label: '',
  name: undefined, // НИ В КОЕМ СЛУЧАЕ НЕ ПРОКИДЫВАТЬ, ИНАЧЕ ОТВАЛИТСЯ CLOUDPAYMENTS
  disabled: false,
  _ref: undefined,
  register: undefined, // для react-hook-form
  isNumberInput: false,
  isPlainInput: false,
  isError: false,
  autoComplete: '',
};

InputField.propTypes = {
  className: PropTypes.string,
  inputClassname: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  _ref: PropTypes.object,
  disabled: PropTypes.bool,
  register: PropTypes.func,
  isNumberInput: PropTypes.bool,
  isPlainInput: PropTypes.bool,
  isError: PropTypes.bool,
  autoComplete: PropTypes.string,
};
