import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
import { useIntl } from 'react-intl';
import {
  YMaps,
  Map,
  Clusterer,
  ZoomControl,
  FullscreenControl,
  Placemark,
} from 'react-yandex-maps';
import classNames from 'classnames/bind';

// State
import {
  setPickupRestaurantDetails,
  setLocalStorageCheckout,
} from '../../state/modules/order/actions';
import { selectUserCity } from '../../state/modules/city/actions';
import restaurantApi from '../../state/modules/restaurant/api';
import { mapLocaleSelector } from '../../state/modules/intl/selectors';

// Components
import { GMaps } from '../GoogleMap';
import RestaurantGoogle from './Restaurant_Google';

// Assets
import pinIcon from './assets/pin-green.svg';
import pinIconDisabled from './assets/pin-green_dis.svg';
import activePinIcon from './assets/pin-red.svg';

// Utils
import { customScrollTo } from '../../utils/customScrollTo';

// Config
import config from '../../config';

// Styles
import styles from './RestaurantsMap.styl';
import { setGlobalMessage } from '../../utils/setGlobalMessage';
import { setCityToRedirect } from '../../state/modules/ui/actions';

const cx = classNames.bind(styles);

export default function RestaurantSelector(onSuccessCallback) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const list = useSelector(state => state.restaurant.list);
  const isRealPhone = useSelector(state => state.responsive.isRealPhone);
  const pickupRestaurant = useSelector(state => state.order.checkout.pickupRestaurant);
  const { userCity, list: cityList } = useSelector(state => state.city);
  const mapLocale = useSelector(mapLocaleSelector);
  const isCompositionExist = useSelector(state => !!state.cart.composition.length);

  const buttonRef = useRef(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [selectedRestaurant, setSelectedRestaurant] = useState(pickupRestaurant);
  const [restaurantAddress, setRestaurantAddress] = useState('');
  const [mapCenter, setMapCenter] = useState(null);
  const [currentMode, setCurrentMode] = useState(config.legal === 'pl' ? 'list' : 'search'); // search, map, list
  const cityToRedirect = useSelector(state => state.ui.cityToRedirect);

  const alertMessage = intl.formatMessage({ id: 'checkout.intime.unavailable2' });

  const isButtonExist = useMemo(() => Boolean(onSuccessCallback), [onSuccessCallback]);

  const modes = useMemo(() => {
    return [
      {
        key: 'search',
        description: intl.formatMessage({ id: 'order.pickup.selectRestaurant.onsearch' }),
      },
      {
        key: 'map',
        description: intl.formatMessage({ id: 'order.pickup.selectRestaurant.onmap' }),
      },
      {
        key: 'list',
        description: intl.formatMessage({ id: 'order.pickup.selectRestaurant.onlist' }),
      },
    ];
  }, []);

  const handleApiAvaliable = () => {
    setIsMapLoaded(true);
  };

  // выбираем способ поиска
  const handleSetCurrentMode = newMode => {
    setCurrentMode(newMode);

    if (newMode === 'map') {
      setIsMapLoaded(false);
    }
  };

  // ставим данные ресторана в стейт
  const handleSetRestaurantDetails = newSelectedRestaurant => {
    dispatch(
      setLocalStorageCheckout({ pickupRestaurant: newSelectedRestaurant, deliveryType: 'pickup' }),
    );

    // если city_id найденного ресторана не совпадает с нашим текущим
    if (newSelectedRestaurant.city_id !== userCity.id) {
      const newCity = cityList.find(city => city.id === newSelectedRestaurant.city_id);
      if (newCity) {
        // если корзина пуста сделать редирект на нужный город
        // иначе - спросить желает ли пользователь сменить город
        if (isCompositionExist) {
          dispatch(setCityToRedirect(newCity));
          return;
        }
        dispatch(selectUserCity(newCity));
        return;
      }
    }
    setMapCenter(newSelectedRestaurant.coordinates);
    setSelectedRestaurant(newSelectedRestaurant);
    dispatch(setPickupRestaurantDetails(newSelectedRestaurant));

    if (isButtonExist) {
      onSuccessCallback();
    }
  };

  // выбираем ресторан на карте или в листе
  const handleOnRestaurantSelect = newSelectedRestaurant => {
    if (newSelectedRestaurant.forward_restaurant_id > 0) {
      return;
    }

    if (isButtonExist) {
      customScrollTo(buttonRef.current);
      setMapCenter(newSelectedRestaurant.coordinates);
      setSelectedRestaurant(newSelectedRestaurant);
      return;
    }

    handleSetRestaurantDetails(newSelectedRestaurant);
  };

  // выбираем адрес в поиске
  const handleOnRestaurantSearchClick = async newRestaurantAddress => {
    if (!newRestaurantAddress) {
      return;
    }

    setRestaurantAddress(newRestaurantAddress);

    // ищем ближайший ресторан для этого адреса
    try {
      const { id } = await restaurantApi.getRestaurantAddress({ address: newRestaurantAddress });
      let newSelectedRestaurant = list.find(restaurant => restaurant.id === id);

      // если у ресторана включена переадресация
      if (newSelectedRestaurant && newSelectedRestaurant.forward_restaurant_id > 0) {
        const forwardedRestaurant = list.find(
          restaurant => restaurant.id === newSelectedRestaurant.forward_restaurant_id,
        );

        newSelectedRestaurant = forwardedRestaurant || newSelectedRestaurant;
      }

      if (newSelectedRestaurant) {
        handleOnRestaurantSelect(newSelectedRestaurant);
      }
    } catch (e) {
      console.warn(e);
    }
  };

  // если мы нажимаем на кнопку ПОДТВЕРДИТЬ
  const handleOnAccept = async () => {
    handleSetRestaurantDetails(selectedRestaurant);
  };

  useEffect(() => {
    if (pickupRestaurant) {
      setSelectedRestaurant(pickupRestaurant);
    }
  }, [pickupRestaurant, cityToRedirect]);

  const cityCenter =
    config.legal === 'pl'
      ? [...userCity.coordinates]
      : [userCity.coordinates[1], userCity.coordinates[0]];

  const googleMap = useMemo(() => {
    return (
      <GMaps
        getMapRef={handleApiAvaliable}
        loadingElement={<div className={cx('RestaurantsMap__preloader')} />}
        containerElement={<div className={cx('RestaurantsMap__mapLayout')} />}
        mapElement={<div style={{ width: '100%', height: '100%', zIndex: 2 }} />}
        defaultZoom={10}
        defaultCenter={cityCenter}
        options={{
          streetViewControl: false,
          mapTypeControl: false,
          // gestureHandling: this.props.isPhone ? 'none' : 'auto',
        }}
      >
        <MarkerClusterer
          defaultMinimumClusterSize={3}
          averageCenter
          enableRetinaIcons
          gridSize={80}
        >
          {list.map(restaurant => {
            if (restaurant.forward_restaurant_id > 0) {
              return (
                <RestaurantGoogle
                  key={restaurant.id}
                  redirected
                  onClick={() => null}
                  {...restaurant}
                />
              );
            }

            return (
              <RestaurantGoogle
                key={restaurant.id}
                onClick={() => handleOnRestaurantSelect(restaurant)}
                {...restaurant}
              />
            );
          })}
        </MarkerClusterer>
      </GMaps>
    );
  }, [list]);

  const yMap = useMemo(() => {
    const options = {
      preset: 'islands#darkGreenClusterIcons',
      groupByCoordinates: false,
      clusterDisableClickZoom: false,
      clusterHideIconOnBalloonOpen: false,
      geoObjectHideIconOnBalloonOpen: false,
      minClusterSize: 3,
    };

    return (
      <YMaps query={{ lang: mapLocale, apikey: config.yandexApiKey }} enterprise>
        <Map
          width="100%"
          height="100%"
          onLoad={handleApiAvaliable}
          state={{
            zoom: 10,
            center: mapCenter || cityCenter,
          }}
        >
          <Clusterer options={options}>
            {list.map(restaurant => {
              if (!restaurant.coordinates) {
                return null;
              }

              const getIcon = () => {
                if (selectedRestaurant?.id === restaurant.id) {
                  return activePinIcon;
                }

                if (restaurant.forward_restaurant_id > 0) {
                  return pinIconDisabled;
                }

                return pinIcon;
              };

              const placemarkProperties = { hintContent: restaurant.name };
              const placemarkGeometry = [
                Number(restaurant.coordinates?.[0]),
                Number(restaurant.coordinates?.[1]),
              ];
              const placemarkOptions = {
                iconLayout: 'default#image',
                iconImageHref: getIcon(),
                iconImageSize: [32, 32],
                iconImageOffset: [-16, -32],
                disableClickZoom: true,
              };

              return (
                <Placemark
                  key={restaurant.id}
                  geometry={placemarkGeometry}
                  properties={placemarkProperties}
                  options={placemarkOptions}
                  onClick={() => {
                    selectedRestaurant?.id !== restaurant.id &&
                      handleOnRestaurantSelect(restaurant);
                    restaurant.forward_restaurant_id > 0 && setGlobalMessage(alertMessage);
                  }}
                />
              );
            })}
          </Clusterer>
          {!isRealPhone && <FullscreenControl />}
          {!isRealPhone && <ZoomControl />}
        </Map>
      </YMaps>
    );
  }, [mapLocale, mapCenter, userCity, list, selectedRestaurant, isRealPhone]);

  return {
    buttonRef,
    mapCenter,
    isMapLoaded,
    selectedRestaurant,
    restaurantAddress,
    currentMode,
    setRestaurantAddress,
    //
    handleOnRestaurantSelect,
    handleOnAccept,
    handleSetCurrentMode,
    handleApiAvaliable,
    handleOnRestaurantSearchClick,
    //
    modes,
    isButtonExist,
    yMap,
    googleMap,
  };
}
