import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// State
import { selectUserCity } from '../../../../state/modules/city/actions';

// Components
import Heading from '../../../Heading';
import CheckLink from '../../../CheckLink';

// Styles
import styles from './Group.styl';
import { setCityToRedirect } from '../../../../state/modules/ui/actions';

const cx = classNames.bind(styles);

export default function Group(props) {
  const { title, cities } = props;

  const dispatch = useDispatch();
  const id = useSelector(state => state.city.userCity.id);
  const isCompositionExist = useSelector(state => !!state.cart.composition.length);

  const onCitySelect = city => {
    if (isCompositionExist) {
      dispatch(setCityToRedirect(city));
      return;
    }
    dispatch(selectUserCity(city));
  };

  return (
    <div className={cx('Group')}>
      <Heading className={cx('Group__title')} level={4} tagName="div">
        {title}
      </Heading>
      <div className={cx('Group__list')}>
        {cities.map(city => (
          <div key={city.id} className={cx('Group__item')}>
            <CheckLink
              className={cx('Group__link')}
              selected={city.id === id}
              onClick={city.id === id ? undefined : () => onCitySelect(city)}
            >
              {city.name}
            </CheckLink>
          </div>
        ))}
      </div>
    </div>
  );
}

Group.defaultProps = {
  title: '',
  cities: [],
};

Group.propTypes = {
  title: PropTypes.string,
  cities: PropTypes.array,
};
