import * as types from './actionTypes';

const initialState = {
  isModalApp: true,
  isModalVpn: true,
};

export default function homeModalReducer(state = initialState, action) {
  switch (action.type) {
    case types.CHANGE_APP_MODAL:
      return { ...state, isModalApp: false };
    case types.CHANGE_VPN_MODAL:
      return { ...state, isModalVpn: false };
    default:
      return state;
  }
}
