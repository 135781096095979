import * as immutable from 'object-path-immutable';
import * as types from './actionTypes';

const getIndexOfComboboxToUpdate = (list, id) => {
  return list.findIndex(combobox => combobox.alias === id);
};

const getIndexOfLevelToUpdate = (levels, id) => {
  return levels.findIndex(level => level.level === id);
};

const getIndexOfGoodToUpdate = (goods, id) => {
  return goods.findIndex(good => good.id === id);
};

const getIndexOfVariationToUpdate = (variations, id) => {
  return variations.findIndex(variation => variation.id === id);
};

const initialState = {
  list: [],
  isLoading: false,
  isLoaded: false,
  error: '',
  boxes: {},
};

export default function comboboxReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_COMBOBOXES_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        error: '',
      };

    case types.FETCH_COMBOBOXES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        initialList: action.payload,
        list: action.payload,
        error: '',
      };

    case types.SET_IS_ADDED: {
      return immutable.assign(state, ['boxes', action.payload], {
        added: true,
      });
    }

    case types.FETCH_COMBOBOXES_FAILURE:
      return {
        ...state,
        list: [],
        isLoaded: false,
        isLoading: false,
        error: action.payload,
      };

    case types.REMOVE_INGREDIENT: {
      const indexOfComboboxToUpdate = getIndexOfComboboxToUpdate(
        state.list,
        action.payload.comboboxId,
      );

      const indexOfLevelToUpdate = getIndexOfLevelToUpdate(
        state.list[indexOfComboboxToUpdate].levels,
        action.payload.level,
      );

      const indexOfGoodToUpdate = getIndexOfGoodToUpdate(
        state.list[indexOfComboboxToUpdate].levels[indexOfLevelToUpdate].goods,
        action.payload.goodId,
      );

      const indexOfVariationToUpdate = getIndexOfVariationToUpdate(
        state.list[indexOfComboboxToUpdate].levels[indexOfLevelToUpdate].goods[indexOfGoodToUpdate]
          .variations,
        action.payload.variationId,
      );

      return immutable.update(
        state,
        [
          'list',
          indexOfComboboxToUpdate,
          'levels',
          indexOfLevelToUpdate,
          'goods',
          indexOfGoodToUpdate,
          'variations',
          indexOfVariationToUpdate,
          'include_ingredients',
        ],
        ingredients => {
          const indexOfIngredientToUpdate = ingredients.findIndex(
            ingredient => ingredient.id === action.payload.ingredientId,
          );
          return immutable.update(ingredients, [indexOfIngredientToUpdate], ingredient =>
            immutable.set(ingredient, 'removed', !ingredient.removed),
          );
        },
      );
    }

    case types.CANCEL_REMOVED_INGREDIENTS: {
      const indexOfComboboxToUpdate = getIndexOfComboboxToUpdate(
        state.list,
        action.payload.comboboxId,
      );

      const indexOfLevelToUpdate = getIndexOfLevelToUpdate(
        state.list[indexOfComboboxToUpdate].levels,
        action.payload.level,
      );

      const indexOfGoodToUpdate = getIndexOfGoodToUpdate(
        state.list[indexOfComboboxToUpdate].levels[indexOfLevelToUpdate].goods,
        action.payload.goodId,
      );

      const indexOfVariationToUpdate = getIndexOfVariationToUpdate(
        state.list[indexOfComboboxToUpdate].levels[indexOfLevelToUpdate].goods[indexOfGoodToUpdate]
          .variations,
        action.payload.variationId,
      );

      return immutable.update(
        state,
        [
          'list',
          indexOfComboboxToUpdate,
          'levels',
          indexOfLevelToUpdate,
          'goods',
          indexOfGoodToUpdate,
          'variations',
          indexOfVariationToUpdate,
          'include_ingredients',
        ],
        ingredients =>
          (ingredients || []).map(ingredient => immutable.set(ingredient, 'removed', false)),
      );
    }

    case types.ADD_TO_SELECTED_SUCCESS:
      return immutable.push(state, `boxes.${action.alias}.goods`, action.composition);

    case types.REMOVE_FROM_SELECTED_SUCCESS: {
      const findIndex = state.boxes[action.alias].goods.findIndex(good => good.id === action.id);

      return immutable.del(state, `boxes.${action.alias}.goods.${findIndex}`);
    }

    case types.RESET_COMBOBOX: {
      const comboboxIndex = getIndexOfComboboxToUpdate(state.list, action.payload);

      return {
        ...state,
        boxes: {},
        list: immutable.assign(state.list, [comboboxIndex], state.initialList[comboboxIndex]),
      };
    }

    default:
      return state;
  }
}
