// Action types
export const LOCATION_CHANGE = '@location/LOCATION_CHANGE';

// Actions
const locationChange = (previousLocation, currentLocation) => ({
  type: LOCATION_CHANGE,
  current: currentLocation,
  previous: previousLocation,
});

export const updateLocation = newLocation => (dispatch, getState) => {
  const { location } = getState();

  if (location.current !== newLocation) {
    return dispatch(locationChange(location.current, newLocation));
  }

  return false;
};

// Reducer
const initialState = {
  current: {},
  previous: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      return {
        ...state,
        current: action.current,
        previous: action.previous,
      };
    }
    default:
      return state;
  }
};
