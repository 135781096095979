import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import InputField from '../InputField';

import IconEyeOpen from '../../icons/eye-open.svg';
import IconEyeClose from '../../icons/eye-close.svg';

import styles from './PasswordField.styl';

const cx = classNames.bind(styles);

export default function PasswordField(props) {

  const [showed, setShowed] = useState(false);

  const toggle = () => setShowed(!showed);

  const { className, ...inputProps } = props;

  return (
    <div className={cx('PasswordField', className)}>
      <InputField type={showed ? 'text' : 'password'} name="password" {...inputProps} />
      <div className={cx('PasswordField__icon')} onClick={toggle}>
        {showed ? (
          <IconEyeOpen width={24} height={24} />
        ) : (
          <IconEyeClose width={24} height={24} />
        )}
      </div>
    </div>
  );
}

PasswordField.propTypes = {
  className: PropTypes.string
};
