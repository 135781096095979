import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Icons
import CheckIcon from '../../icons/check.svg';

// Styles
import styles from './CheckLink.styl';

const cx = classNames.bind(styles);

export default function CheckLink(props) {
  const { className, children, selected, removed, ...otherProps } = props;

  return (
    <div
      className={cx('CheckLink', className, {
        CheckLink_selected: selected,
        CheckLink_removed: removed,
      })}
      {...otherProps}
    >
      <div className={cx('CheckLink__icon', {
        CheckLink__icon_selected: selected,
      })}>
        <CheckIcon />
      </div>
      {children}
    </div>
  );
}

CheckLink.defaultProps = {
  className: '',
  selected: false,
  removed: false,
};

CheckLink.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  selected: PropTypes.bool,
  removed: PropTypes.bool,
};
